import React, { useEffect, useState } from "react";
import axios from "axios";

export const ParticipantDetail = ({ details, eventData, token }) => {
  const [error, setError] = useState(null);
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // Fetch insights based on userId
  useEffect(() => {
    if (!details) return;

    const fetchInsights = async (userId) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiDomain}/api/v2/user/monthly?id=${userId}&access_token=${token}&period=day`
        );
        setInsights(response.data);
      } catch (err) {
        setError("Error fetching insights.");
      } finally {
        setLoading(false);
      }
    };

    fetchInsights(details.instagramBusinessAccountId);
  }, [details, token]);

  // Match participationDetails with eventData
  const matchedEvents = details?.participationDetails.map((part) => {
    const eventDetails = eventData.find(
      (event) => event.eventID === part.event_id
    );

    return eventDetails
      ? {
          eventName: eventDetails.eventName,
          eventType: eventDetails.eventType,
          participantsCount: eventDetails.participantsCount,
          startDate: eventDetails.startDate,
          endDate: eventDetails.endDate,
          participationTime: part.participation_time,
          state: part.state.String,
          city: part.city.String,
          pincode: part.pincode.String,
        }
      : null;
  });

  return (
    <div className="p-6 space-y-8">
      {/* Event Details Section */}
      <section className="space-y-4">
        <h2 className="text-2xl font-bold text-gray-800">
          Participated Events
        </h2>
        {matchedEvents && matchedEvents.length > 0 ? (
          matchedEvents.map((event, index) =>
            event ? (
              <div
                key={index}
                className="bg-white shadow-md border rounded-lg p-6 space-y-4"
              >
                <h3 className="text-xl font-semibold text-indigo-600">
                  {event.eventName}
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      Event Type:
                    </p>
                    <p className="text-lg font-semibold text-gray-800">
                      {event.eventType || "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      Participants:
                    </p>
                    <p className="text-lg font-semibold text-gray-800">
                      {event.participantsCount || "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      Start Date:
                    </p>
                    <p className="text-lg font-semibold text-gray-800">
                      {new Date(event.startDate).toLocaleDateString("en-GB") ||
                        "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">End Date:</p>
                    <p className="text-lg font-semibold text-gray-800">
                      {new Date(event.endDate).toLocaleDateString("en-GB") ||
                        "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      Participation Time:
                    </p>
                    <p className="text-lg font-semibold text-gray-800">
                      {new Date(
                        event.participationTime
                      ).toLocaleDateString("en-GB") || "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">State:</p>
                    <p className="text-lg font-semibold text-gray-800">
                      {event.state || "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">City:</p>
                    <p className="text-lg font-semibold text-gray-800">
                      {event.city || "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      Pincode:
                    </p>
                    <p className="text-lg font-semibold text-gray-800">
                      {event.pincode || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <p
                key={index}
                className="text-red-500 bg-white shadow-md rounded-lg p-4"
              >
                No matching event found for participation detail.
              </p>
            )
          )
        ) : (
          <p className="text-gray-600">No participation details available.</p>
        )}
      </section>
    </div>
  );
};



// import React, { useEffect, useState } from "react";
// import axios from "axios";

// export const ParticipantDetail = ({ details, eventData, token }) => {
//   const [error, setError] = useState(null);
//   const [insights, setInsights] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const apiDomain = process.env.REACT_APP_API_DOMAIN;

//   // Fetch insights based on userId
//   useEffect(() => {
//     if (!details) return;

//     const fetchInsights = async (userId) => {
//       setLoading(true);
//       try {
//         const response = await axios.get(
//           `${apiDomain}/api/v2/user/monthly?id=${userId}&access_token=${token}&period=day`
//         );
//         setInsights(response.data);
//       } catch (err) {
//         setError("Error fetching insights.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchInsights(details.instagramBusinessAccountId);
//   }, [details, token]);

//   // Helper to find event details from eventData
//   const getEventDetails = (eventId) =>
//     eventData?.find((event) => event.eventID === eventId) || {};

//   return (
//     <main className="space-y-8 px-4">
//       {details?.participationDetails?.map((participant, index) => {
//         const eventDetails = getEventDetails(participant.eventId);
//         return (
//           <div
//             key={index}
//             className="relative p-6 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-md shadow-lg"
//           >
//             {/* Card Content */}
//             <div className="bg-white rounded-md p-6 shadow-md relative z-10">
//               <div className="border-b pb-4 mb-4">
//                 <h2 className="text-2xl font-bold text-gray-800">
//                   {eventDetails.eventName || "Event Name Not Available"}
//                 </h2>
//                 <p className="text-gray-600">
//                   {new Date(eventDetails.startDate).toLocaleDateString("en-GB")}{" "}
//                   -{" "}
//                   {new Date(eventDetails.endDate).toLocaleDateString("en-GB")}
//                 </p>
//               </div>

//               <div className="grid grid-cols-2 gap-4">
//                 <div>
//                   <p className="text-lg font-semibold text-gray-700">
//                     Participant Name:
//                   </p>
//                   <p className="text-gray-600">{details.name || "N/A"}</p>
//                 </div>
//                 <div>
//                   <p className="text-lg font-semibold text-gray-700">
//                     Joining Date:
//                   </p>
//                   <p className="text-gray-600">
//                     {new Date(details.joiningTime).toLocaleDateString("en-GB")}
//                   </p>
//                 </div>
//                 <div>
//                   <p className="text-lg font-semibold text-gray-700">
//                     City:
//                   </p>
//                   <p className="text-gray-600">{participant.city || "N/A"}</p>
//                 </div>
//                 <div>
//                   <p className="text-lg font-semibold text-gray-700">
//                     State:
//                   </p>
//                   <p className="text-gray-600">{participant.state || "N/A"}</p>
//                 </div>
//                 <div>
//                   <p className="text-lg font-semibold text-gray-700">
//                     Pincode:
//                   </p>
//                   <p className="text-gray-600">{participant.pincode || "N/A"}</p>
//                 </div>
//                 <div>
//                   <p className="text-lg font-semibold text-gray-700">
//                     International:
//                   </p>
//                   <p className="text-gray-600">
//                     {participant.international ? "Yes" : "No"}
//                   </p>
//                 </div>
//               </div>

       
//               {/* Insights Section */}
        
//             </div>
//           </div>
//         );
//       })}
//     </main>
//   );
// };
