import React from "react";

const ReferralCoins = ({
  referredCoins = {}, // Default to an empty object if not provided
  referrerCoins = {}, // Default to an empty object if not provided
  setReferredCoins,
  setReferrerCoins,
}) => {
  const referredFields = [
    {
      title: "Referred Sponsor to Sponsor",
      key: "sponsorTosponsor",
    },
    {
      title: "Referred Sponsor to User",
      key: "sponsorTouser",
    },
    {
      title: "Referred User to Sponsor",
      key: "userTosponsor",
    },
    {
      title: "Referred User to User",
      key: "userTouser",
    },
  ];

  const referrerFields = [
    {
      title: "Referrer Sponsor to Sponsor",
      key: "sponsorTosponsor",
    },
    {
      title: "Referrer Sponsor to User",
      key: "sponsorTouser",
    },
    {
      title: "Referrer User to Sponsor",
      key: "userTosponsor",
    },
    {
      title: "Referrer User to User",
      key: "userTouser",
    },
  ];

  const handleInputChange = (type, key, value) => {
    const updatedValue = parseInt(value, 10) || 0; // Ensure numeric input with default 0
    if (type === "referred") {
      setReferredCoins((prev) => ({
        ...prev,
        [key]: updatedValue,
      }));
    } else if (type === "referrer") {
      setReferrerCoins((prev) => ({
        ...prev,
        [key]: updatedValue,
      }));
    }
  };

  return (
    <section className="p-4 border rounded-md bg-blue-50 mt-8 shadow-lg">
      {/* Referred Coins Section */}
      <h3 className="text-lg font-semibold mb-4">Referred Coins (%)</h3>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mb-8">
        {referredFields.map((field, index) => (
          <div
            key={index}
            className="flex flex-col lg:flex-row items-center gap-4 bg-white p-4 shadow-md border rounded-md"
          >
            {/* Label */}
            <label className="lg:w-1/2 text-sm lg:text-base font-medium text-gray-700">
              {field.title}
            </label>

            {/* Input */}
            <input
              type="number"
              value={referredCoins?.[field.key] || 0} // Default to 0 if undefined
              onChange={(e) =>
                handleInputChange("referred", field.key, e.target.value)
              }
              className="w-full lg:w-1/2 border border-gray-300 rounded-md px-4 py-2"
              placeholder="Enter percentage"
            />
          </div>
        ))}
      </div>

      {/* Referrer Coins Section */}
      <h3 className="text-lg font-semibold mb-4">Referrer Coins (%)</h3>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
        {referrerFields.map((field, index) => (
          <div
            key={index}
            className="flex flex-col lg:flex-row items-center gap-4 bg-white p-4 shadow-md border rounded-md"
          >
            {/* Label */}
            <label className="lg:w-1/2 text-sm lg:text-base font-medium text-gray-700">
              {field.title}
            </label>

            {/* Input */}
            <input
              type="number"
              value={referrerCoins?.[field.key] || 0} // Default to 0 if undefined
              onChange={(e) =>
                handleInputChange("referrer", field.key, e.target.value)
              }
              className="w-full lg:w-1/2 border border-gray-300 rounded-md px-4 py-2"
              placeholder="Enter percentage"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ReferralCoins;
