import React, { useContext } from "react";
// import TotalStat from "../../components/content/Dashboard/TotalStat";
import EventTable from "./EventTable";
import { DataContext } from "../context/DataContext";
import LoadingScreen from "../utilities/LoadingScreen";

const Dashboard = () => {
  const { eventData, loading } = useContext(DataContext);
  
  if (loading) <LoadingScreen />

  // Main dashboard content
  return (
    <div className="lg:ml-[20vw] lg:mr-[2vw]">
      <EventTable eventData={eventData} Dashboard={true} />
    </div>
  );
};

export default Dashboard;
