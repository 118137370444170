import React, { useEffect, useState } from "react";
import axios from "axios";

export const SponsorshipDetail = ({ details, eventData, token }) => {
  const [error, setError] = useState(null);
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const getResponse = async (url) => {
    try {
      const response = await axios.get(url, {
        params: {
          access_token: token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching media details:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (!details) return;

    const fetchInsights = async (userId) => {
      setLoading(true);
      try {
        const initialUrl = `${apiDomain}/api/v2/user/monthly?id=${userId}&access_token=${token}&period=day`;
        const data = await getResponse(initialUrl);
        setInsights(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchInsights(details.ig_user.id);
  }, [details, token]);

  // Match sponsorship details with event data
  const matchedSponsorships = Object.keys(details.eventCategories).flatMap(
    (eventId) => {
      const categories = details.eventCategories[eventId]; // Array of categories for this event
      const eventDetails = eventData.find(
        (event) => event.eventID === Number(eventId)
      );

      if (!eventDetails) return []; // Skip if no matching event

      return categories.map((category) => ({
        eventName: eventDetails.eventName,
        startDate: eventDetails.startDate,
        endDate: eventDetails.endDate,
        categoryName: category.categoryName,
        description: category.description,
        noOfCoupons: category.noOfCoupons,
        noOfGiftHampers: category.noOfGiftHampers,
        noOfRemainingCoupons: category.noOfRemainingCoupons,
        noOfRemainingGiftHampers: category.noOfRemainingGiftHampers,
        price: category.price,
      }));
    }
  );

  if (loading) {
    return (
      <div className="text-center py-4">
        <p>Loading sponsorship details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-4 text-red-500">
        <p>Error loading sponsorship details: {error.message}</p>
      </div>
    );
  }

  return (
    <div className="container px-4 py-6">
      <h1 className="text-4xl font-extrabold mb-4 text-center text-gray-800">
        Sponsorship Details
      </h1>
      <div className="w-full h-1 bg-gradient-to-r from-blue-400 to-purple-600 mb-6"></div>

      {matchedSponsorships && matchedSponsorships.length > 0 ? (
        <div className="grid grid-cols-1  gap-6">
          {matchedSponsorships.map((sponsorship, index) => (
            <div
              key={index}
              className="bg-white shadow-md border rounded-lg p-4 hover:shadow-lg transition-shadow duration-300"
            >
              <h2 className="text-2xl font-semibold text-indigo-600 mb-2">
                {sponsorship.eventName}
              </h2>
              <p className="text-sm text-gray-500 mb-2">
                {new Date(sponsorship.startDate).toLocaleDateString("en-GB")} -{" "}
                {new Date(sponsorship.endDate).toLocaleDateString("en-GB")}
              </p>
              <h3 className="text-xl font-bold text-gray-800">
                {sponsorship.categoryName}
              </h3>
              <p className="text-gray-600 mb-4">{sponsorship.description}</p>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm font-medium text-gray-500">Coupons:</p>
                  <p className="text-lg font-semibold text-gray-800">
                    {sponsorship.noOfRemainingCoupons} /{" "}
                    {sponsorship.noOfCoupons}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Gift Hampers:
                  </p>
                  <p className="text-lg font-semibold text-gray-800">
                    {sponsorship.noOfRemainingGiftHampers} /{" "}
                    {sponsorship.noOfGiftHampers}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">Price:</p>
                  <p className="text-lg font-semibold text-gray-800">
                    ₹{sponsorship.price}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600 text-center">
          No sponsorship details available.
        </p>
      )}
    </div>
  );
};
