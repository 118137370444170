import React, { useEffect, useState, useRef, useCallback } from "react";
import { uploadFile } from "../../firebase/fileUpload";
import EventDataSection from "./child/EventDataSection";
import Winners from "./child/Winners";
import Entities from "./child/Entities";
import CoinValue from "./child/coinSystem/CoinValue";
import ReferralCoins from "./child/coinSystem/RrCoins";
import Category from "./child/Category";
import ParticipationAdditionalData from "./child/ParticipationAdditionalData";
import VotesValue from "./child/VotesValue";

const EventAdditionalData = ({
  additionalData = {}, // Default to an empty object
  setAdditionalData,
  isEditMode,
}) => {
  // Default values to prevent null/undefined errors
  const defaultAdditionalData = {
    votes: {
      like: { votes: 0, value: 0 },
      comment: { votes: 0, value: 0 },
      share: { votes: 0, value: 0 },
      impression: { votes: 0, value: 0 },
      reach: { votes: 0, value: 0 },
      save: { votes: 0, value: 0 },
    },
    winners: {
      national: 0,
      state: 0,
      city: 0,
      pincode: 0,
      international: 0,
    },
    entities: [],
    coinValue: 0,
    referredCoins: {
      sponsorTosponsor: 0,
      sponsorTouser: 0,
      userTouser: 0,
      userTosponsor: 0,
    },
    referrerCoins: {
      sponsorTosponsor: 0,
      sponsorTouser: 0,
      userTouser: 0,
      userTosponsor: 0,
    },
    generalCategory: [],
    ageCategory: [],
    followersCategory: [],
    genderCategory: [],
    isHashtagsEditable: false,
    isCaptionEditable: false,
    isSponsorTagsEditable: false,
    eventPrice: 0,
    eventCaption1: "",
    eventCaption2: "",
    eventHashtags: [],
    eventImageUrl: null,
    eventAdditionalImages: [],
    eventAdditionalDesc: "",
    advideoUrl: null,
    demoVideoUrl: null,
    participationPost: {
      caption1: "",
      caption2: "",
      hashtags: [],
      frameImage: null,
      carouselImages: [],
      isPPHashtagEditable: false,
      isPPCaptionEditable: false,
      arePPSponsorsTagEditable: false,
      isDefaultChecked: false,
    },
  };

  // State Initialization

  const [winners, setWinners] = useState(defaultAdditionalData.winners);
  const [entities, setEntities] = useState(defaultAdditionalData.entities);
  const [coinValue, setCoinValue] = useState(defaultAdditionalData.coinValue);
  const [votes, setVotes] = useState(
    additionalData?.votes || defaultAdditionalData.votes
  );
  const [referredCoins, setReferredCoins] = useState(
    defaultAdditionalData.referredCoins
  );
  const [referrerCoins, setReferrerCoins] = useState(
    defaultAdditionalData.referrerCoins
  );
  const [generalCategory, setGeneralCategory] = useState(
    defaultAdditionalData.generalCategory
  );
  const [ageCategory, setAgeCategory] = useState(
    defaultAdditionalData.ageCategory
  );
  const [followersCategory, setFollowersCategory] = useState(
    defaultAdditionalData.followersCategory
  );
  const [genderCategory, setGenderCategory] = useState(
    defaultAdditionalData.genderCategory
  );
  const [isHashtagsEditable, setIsHashtagsEditable] = useState(
    defaultAdditionalData.isHashtagsEditable
  );
  const [isCaptionEditable, setIsCaptionEditable] = useState(
    defaultAdditionalData.isCaptionEditable
  );
  const [isSponsorTagsEditable, setIsSponsorTagsEditable] = useState(
    defaultAdditionalData.isSponsorTagsEditable
  );
  const [eventPrice, setEventPrice] = useState(
    defaultAdditionalData.eventPrice
  );
  const [eventCaption1, setEventCaption1] = useState(
    defaultAdditionalData.eventCaption1
  );
  const [eventCaption2, setEventCaption2] = useState(
    defaultAdditionalData.eventCaption2
  );

  const [eventHashtags, setEventHashtags] = useState(
    defaultAdditionalData.eventHashtags
  );

  const [eventImageUrl, setEventImageUrl] = useState(
    defaultAdditionalData.eventImageUrl
  );
  const [eventAdditionalImages, setEventAdditionalImages] = useState(
    defaultAdditionalData.eventAdditionalImages
  );
  const [eventAdditionalDesc, setEventAdditionalDesc] = useState(
    defaultAdditionalData.eventAdditionalDesc
  );
  const [advideoUrl, setAdVideoUrl] = useState(
    defaultAdditionalData.advideoUrl
  );
  const [demoVideoUrl, setDemoVideoUrl] = useState(
    defaultAdditionalData.demoVideoUrl
  );

  // Participation Data
  const participationPost = defaultAdditionalData.participationPost;
  const [ppCaption1, setPpCaption1] = useState(participationPost.caption1);
  const [ppCaption2, setPpCaption2] = useState(participationPost.caption2);
  const [ppHashtags, setPpHashtags] = useState(participationPost.hashtags);
  const [newHashtag, setNewHashtag] = useState("");
  const [frameImageUrl, setFrameImageUrl] = useState(
    participationPost.frameImage
  );
  const [participationCarouselImages, setParticipationCarouselImages] =
    useState(participationPost.carouselImages);
  const [isPPHashtagEditable, setIsPPHashtagEditable] = useState(
    participationPost.isPPHashtagEditable
  );
  const [isPPCaptionEditable, setIsPPCaptionEditable] = useState(
    participationPost.isPPCaptionEditable
  );
  const [arePPSponsorsTagEditable, setArePPSponsorsTagEditable] = useState(
    participationPost.arePPSponsorsTagEditable
  );
  const [isDefaultChecked, setIsDefaultChecked] = useState(
    participationPost.isDefaultChecked
  );

  // Update state when additionalData changes in edit mode
  useEffect(() => {
    if (isEditMode && additionalData) {
      const updatedData = { ...defaultAdditionalData, ...additionalData };
      setVotes(additionalData.votes || defaultAdditionalData.votes);
      setWinners(updatedData.winners);
      setEntities(updatedData.entities);
      setCoinValue(updatedData.coinValue);
      setReferredCoins(updatedData.referredCoins);
      setReferrerCoins(updatedData.referrerCoins);
      setGeneralCategory(updatedData.generalCategory);
      setAgeCategory(updatedData.ageCategory);
      setFollowersCategory(updatedData.followersCategory);
      setGenderCategory(updatedData.genderCategory);
      setIsHashtagsEditable(updatedData.isHashtagsEditable);
      setIsCaptionEditable(updatedData.isCaptionEditable);
      setIsSponsorTagsEditable(updatedData.isSponsorTagsEditable);
      setEventPrice(updatedData.eventPrice);
      setEventCaption1(updatedData.eventCaption1);
      setEventCaption2(updatedData.eventCaption2);
      setEventHashtags(updatedData.eventHashtags);
      setEventImageUrl(updatedData.eventImageUrl);
      setEventAdditionalImages(updatedData.eventAdditionalImages);
      setEventAdditionalDesc(updatedData.eventAdditionalDesc);
      setAdVideoUrl(updatedData.advideoUrl);
      setDemoVideoUrl(updatedData.demoVideoUrl);

      const participationPost = updatedData.participationPost || {};
      setPpCaption1(participationPost.caption1);
      setPpCaption2(participationPost.caption2);
      setPpHashtags(participationPost.hashtags);
      setFrameImageUrl(participationPost.frameImage);
      setParticipationCarouselImages(participationPost.carouselImages);
      setIsPPHashtagEditable(participationPost.isPPHashtagEditable);
      setIsPPCaptionEditable(participationPost.isPPCaptionEditable);
      setArePPSponsorsTagEditable(participationPost.arePPSponsorsTagEditable);
      setIsDefaultChecked(participationPost.isDefaultChecked);
    }
  }, [additionalData, isEditMode]);

  // Update setAdditionalData whenever relevant states change
  useEffect(() => {
    setAdditionalData({
      votes,
      winners,
      entities,
      coinValue,
      referredCoins,
      referrerCoins,
      generalCategory,
      ageCategory,
      followersCategory,
      genderCategory,
      isHashtagsEditable,
      isCaptionEditable,
      isSponsorTagsEditable,
      eventPrice,
      eventCaption1,
      eventCaption2,
      eventHashtags,
      eventImageUrl,
      eventAdditionalImages,
      eventAdditionalDesc,
      advideoUrl,
      demoVideoUrl,
      participationPost: {
        caption1: ppCaption1,
        caption2: ppCaption2,
        hashtags: ppHashtags,
        frameImage: frameImageUrl,
        carouselImages: participationCarouselImages,
        isPPHashtagEditable,
        isPPCaptionEditable,
        arePPSponsorsTagEditable,
        isDefaultChecked,
        
      },
    });
  }, [
    votes,
    winners,
    entities,
    coinValue,
    referredCoins,
    referrerCoins,
    generalCategory,
    ageCategory,
    followersCategory,
    genderCategory,
    isHashtagsEditable,
    isCaptionEditable,
    isSponsorTagsEditable,
    eventPrice,
    eventCaption1,
    eventCaption2,
    eventHashtags,
    eventImageUrl,
    eventAdditionalImages,
    eventAdditionalDesc,
    advideoUrl,
    demoVideoUrl,
    ppCaption1,
    ppCaption2,
    ppHashtags,
    frameImageUrl,
    participationCarouselImages,
    isPPHashtagEditable,
    isPPCaptionEditable,
    arePPSponsorsTagEditable,
    isDefaultChecked,
  ]);

  const handleFileUpload = async (file) => {
    try {
      if (!file) throw new Error("No file provided");

      // Simulate file upload
      const uploadResponse = await uploadFile(file); // Assuming uploadFile is a utility function
      return uploadResponse; // Return the uploaded file URL or identifier
    } catch (error) {
      console.error("File upload failed:", error);
      return null;
    }
  };

  return (
    <div className="p-4 border rounded-md mt-4 mb-10 bg-white shadow-xl">
      <h2 className="text-xl lg:text-2xl font-bold text-blue-600 mb-6">
        {isEditMode
          ? "Edit Event Additional Data"
          : "Add Event Additional Data"}
      </h2>
      <EventDataSection
        handleFileUpload={handleFileUpload}
        isHashtagsEditable={isHashtagsEditable}
        setIsHashtagsEditable={setIsHashtagsEditable}
        isCaptionEditable={isCaptionEditable}
        setIsCaptionEditable={setIsCaptionEditable}
        isSponsorTagsEditable={isSponsorTagsEditable}
        setIsSponsorTagsEditable={setIsSponsorTagsEditable}
        eventPrice={eventPrice}
        setEventPrice={setEventPrice}
        eventCaption1={eventCaption1}
        setEventCaption1={setEventCaption1}
        eventCaption2={eventCaption2}
        setEventCaption2={setEventCaption2}
        eventHashtags={eventHashtags}
        setEventHashtags={setEventHashtags}
        eventImageUrl={eventImageUrl}
        setEventImageUrl={setEventImageUrl}
        eventAdditionalImages={eventAdditionalImages}
        setEventAdditionalImages={setEventAdditionalImages}
        eventAdditionalDesc={eventAdditionalDesc}
        setEventAdditionalDesc={setEventAdditionalDesc}
        advideoUrl={advideoUrl}
        setAdVideoUrl={setAdVideoUrl}
        demoVideoUrl={demoVideoUrl}
        setDemoVideoUrl={setDemoVideoUrl}
        isEditMode={isEditMode}
      />
      <br />
      <ParticipationAdditionalData
          ppCaption1={ppCaption1}
          setPpCaption1={setPpCaption1}
          ppCaption2={ppCaption2}
          setPpCaption2={setPpCaption2}
        ppHashtags={ppHashtags}
        setPpHashtags={setPpHashtags}
        newHashtag={newHashtag}
        setNewHashtag={setNewHashtag}
        frameImageUrl={frameImageUrl}
        setFrameImageUrl={setFrameImageUrl}
        participationCarouselImages={participationCarouselImages}
        setParticipationCarouselImages={setParticipationCarouselImages}
        isPPHashtagEditable={isPPHashtagEditable}
        setIsPPHashtagEditable={setIsPPHashtagEditable}
        isPPCaptionEditable={isPPCaptionEditable}
        setIsPPCaptionEditable={setIsPPCaptionEditable}
        arePPSponsorsTagEditable={arePPSponsorsTagEditable}
        setArePPSponsorsTagEditable={setArePPSponsorsTagEditable}
        isDefaultChecked={isDefaultChecked}
        setIsDefaultChecked={setIsDefaultChecked}
        handleFileUpload={handleFileUpload}
        isEditMode={isEditMode}
      />
      <Winners
        winners={winners}
        setWinners={setWinners}
        isEditMode={isEditMode}
      />
      <Entities
        entities={entities}
        setEntities={setEntities}
        isEditMode={isEditMode}
      />
      <Category
        title="General Category"
        categories={generalCategory}
        setCategories={setGeneralCategory}
        isEditMode={isEditMode}
      />
      <Category
        title="Age Category"
        categories={ageCategory}
        setCategories={setAgeCategory}
        isEditMode={isEditMode}
      />
      <Category
        title="Followers Count Category"
        categories={followersCategory}
        setCategories={setFollowersCategory}
        isEditMode={isEditMode}
      />
      <Category
        title="Gender Category"
        categories={genderCategory}
        setCategories={setGenderCategory}
        options={["All", "Male", "Female", "Others", "Open" ]}
        isDropdown
        isEditMode={isEditMode}
      />

      <CoinValue coinValue={coinValue} setCoinValue={setCoinValue} />
      <ReferralCoins
        referredCoins={referredCoins}
        setReferredCoins={setReferredCoins}
        referrerCoins={referrerCoins}
        setReferrerCoins={setReferrerCoins}
        isEditMode={isEditMode}
      />
      <VotesValue votes={votes} setVotes={setVotes} />
    </div>
  );
};

export default EventAdditionalData;
