import React from "react";
import profileImage from "./profile.jpg";
import dropdown from "./dropdown.png";
import { FaBars, FaBell } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = ({ isMobile, toggleSidebar }) => {
  const [notificationActive, setNotificationActive] = React.useState(true); // State to toggle indicator

  return (
    <nav
      className={`fixed top-0 flex justify-between items-center px-4 py-2 bg-white shadow-md ${
        isMobile ? "w-full left-0" : "left-[18vw] w-[82%]"
      } z-50`}
    >
      <div className="flex items-center">
        {isMobile && (
          <button onClick={toggleSidebar} className="mr-4">
            <FaBars className="text-xl" />
          </button>
        )}
        <span className="text-black font-semibold text-lg lg:text-2xl leading-[91.5%]">
          Trishul Koli
        </span>
        <span role="img" aria-label="Wave" className="text-2xl ml-2">
          👋
        </span>
      </div>
      <div className="flex items-center gap-6">
        {/* Notification Bell with Indicator */}
        <div className="relative">
          <Link to="/notification">
            <FaBell className="text-2xl text-gray-700" />
          </Link>
          {notificationActive && (
            <span className="absolute -top-1 -right-[2px] w-3 h-3 bg-red-500 rounded-full animate-pulse"></span>
          )}
        </div>
        {/* Profile Image and Dropdown */}
        <div className="relative flex items-center gap-2.5">
          <div
            className="w-12 h-12 rounded-full bg-cover bg-center"
            style={{ backgroundImage: `url(${profileImage})` }}
          ></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;





// import React from "react";
// import profileImage from "./profile.jpg";
// import dropdown from "./dropdown.png";
// import { Link } from "react-router-dom";

// const Navbar = () => {
//   // Function to simulate Facebook login status
//   // const isFbLogin = () => {
//   //   // Logic to determine if user is logged in via Facebook
//   //   // For example purposes, we'll just return the value directly
//   //   if (localStorage["facebookConnected"] === "true") return true;

//   //   return false; // Change this to false to simulate a logged-out state
//   // };

//   return (

//       <nav className="fixed top-0 flex justify-between items-center p-6 bg-[#fff]  shadow-md left-[18vw] w-[82%] z-50">
//         <div className="flex items-center">
//           <span className="text-black font-bold text-2xl leading-[91.5%]">John Wick</span>
//           <span role="img" aria-label="Wave" className="text-2xl ml-2">
//             👋
//           </span>
//         </div>
//         <div className="flex items-center gap-6">
//           {/* {isFbLogin() ? (
//             <> */}
//           <span className="text-2xl">🔔</span>
//           <div className="relative flex items-center gap-2.5">
//             <div
//               className="w-12 h-12 rounded-full bg-cover bg-center"
//               style={{ backgroundImage: `url(${profileImage})` }}
//             ></div>
//             <button className="ml-2 p-2 bg-white border-none rounded-md cursor-pointer">
//               <img src={dropdown} alt="Dropdown" />
//             </button>
//           </div>
//           {/* </>
//           ) : (
//             <button className="bg-green-400 text-white px-4 py-2 rounded-md font-medium">
//               <Link to='https://dafukcrew.com/auth/facebook?redirectTo=https://namasvi.indicarena.com'>
//                 Connect
//               </Link>
//             </button>
//           )} */}
//         </div>
//       </nav>
//     // </div>
//   );
// };

// export default Navbar;
