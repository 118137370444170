import React, { useState, useEffect } from "react";
import SideNav from "./SideNav/SideNav";
import Navbar from "./Navbar/Navbar";

const FixedComponents = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
      if (window.innerWidth >= 1024) setIsCollapsed(false); // Keep sidebar open on larger screens
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div>
      <Navbar isMobile={isMobile} toggleSidebar={toggleSidebar} />
      <SideNav
        isMobile={isMobile}
        isCollapsed={isCollapsed}
        toggleSidebar={toggleSidebar}
      />
      {/* Main Content */}
      <div className={`pt-[80px] ${isMobile ? "pl-0 pt-4" : "pl-[18vw]"}`}>
        {/* Your content goes here */}
      </div>
    </div>
  );
};

export default FixedComponents;
