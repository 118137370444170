import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../context/DataContext";
import axios from "axios";
import LoadingScreen from "../utilities/LoadingScreen";
import NonParticipatedNotification from "./NonParticipatedNotification";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

const Notifications = () => {
  const { participant, notifications, loading } = useContext(DataContext);
  if (loading) <LoadingScreen />;
  console.log("notifications", notifications);
  const [participants, setParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [filters, setFilters] = useState({
    event_id: "",
    city: "",
    state: "",
    pincode: "",
    age_category: "",
    gender_category: "",
    followers_count_category: "",
    generic_category: "",
    international: "",
  });
  const [availableFilters, setAvailableFilters] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [additionalData, setAdditionalData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(9);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // Initialize participants and filters
  useEffect(() => {
    if (participant?.participants) {
      setParticipants(participant.participants);
      setFilteredParticipants(participant.participants);
      generateFilterOptions(participant.participants);
    }
  }, [participant]);

  const generateFilterOptions = (data) => {
    const options = {
      event_id: [
        ...new Set(
          data.flatMap(
            (p) => p.participationDetails?.map((d) => d.event_id) || []
          )
        ),
      ],
      city: [
        ...new Set(
          data.flatMap(
            (p) => p.participationDetails?.map((d) => d.city?.String) || []
          )
        ),
      ],
      state: [
        ...new Set(
          data.flatMap(
            (p) => p.participationDetails?.map((d) => d.state?.String) || []
          )
        ),
      ],
      pincode: [
        ...new Set(
          data.flatMap(
            (p) => p.participationDetails?.map((d) => d.pincode?.String) || []
          )
        ),
      ],
      age_category: [
        ...new Set(
          data.flatMap(
            (p) =>
              p.participationDetails?.map((d) => d.age_category?.String) || []
          )
        ),
      ],
      gender_category: [
        ...new Set(
          data.flatMap(
            (p) =>
              p.participationDetails?.map((d) => d.gender_category?.String) ||
              []
          )
        ),
      ],
      followers_count_category: [
        ...new Set(
          data.flatMap(
            (p) =>
              p.participationDetails?.map(
                (d) => d.followers_count_category?.String
              ) || []
          )
        ),
      ],
      generic_category: [
        ...new Set(
          data.flatMap(
            (p) =>
              p.participationDetails?.map((d) => d.generic_category?.String) ||
              []
          )
        ),
      ],
      international: ["true", "false"],
    };
    setAvailableFilters(options);
  };

  // Correct apply filters logic
  useEffect(() => {
    const applyFilters = () => {
      if (!participants) return;

      let filtered = participants;

      if (filters.event_id) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some(
            (detail) => detail.event_id.toString() === filters.event_id
          )
        );
      }

      if (filters.city) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some(
            (detail) => detail.city?.String === filters.city
          )
        );
      }

      if (filters.state) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some(
            (detail) => detail.state?.String === filters.state
          )
        );
      }

      if (filters.pincode) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some(
            (detail) => detail.pincode?.String === filters.pincode
          )
        );
      }

      if (filters.age_category) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some(
            (detail) => detail.age_category?.String === filters.age_category
          )
        );
      }

      if (filters.gender_category) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some(
            (detail) =>
              detail.gender_category?.String === filters.gender_category
          )
        );
      }

      if (filters.followers_count_category) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some(
            (detail) =>
              detail.followers_count_category?.String ===
              filters.followers_count_category
          )
        );
      }

      if (filters.generic_category) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some(
            (detail) =>
              detail.generic_category?.String === filters.generic_category
          )
        );
      }

      if (filters.international !== "") {
        const isInternational = filters.international === "true";
        filtered = filtered.filter((part) =>
          part.participationDetails?.some(
            (detail) => detail.international === isInternational
          )
        );
      }

      setFilteredParticipants(filtered);
    };

    applyFilters();
  }, [filters, participants]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleUserSelection = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const handleSelectAllUsers = () => {
    if (selectedUsers.length === filteredParticipants.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(filteredParticipants.map((user) => user.userId));
    }
  };

  const handleSendNotification = async () => {
    if (!title || !description || selectedUsers.length === 0) {
      alert("Please fill out all fields and select at least one user.");
      return;
    }

    try {
      const selectedUsersString = selectedUsers.join(", ");
      const tokenResponse = await axios.get(
        `${apiDomain}/api/v2/token/get?user_ids=${selectedUsersString}`
      );

      const tokens = selectedUsers
        .map((userId) => {
          const fcmToken = tokenResponse.data[userId];
          if (fcmToken) {
            return {
              user_id: parseInt(userId, 10),
              fcm_token: fcmToken,
            };
          } else {
            console.warn(`No FCM token found for user ID ${userId}`);
            return null;
          }
        })
        .filter((token) => token !== null);

      if (tokens.length === 0) {
        alert("No valid FCM tokens found for selected users.");
        return;
      }

      const requestBody = {
        users: tokens,
        title,
        body: description,
        ...(additionalData && { additional_data: { url: additionalData } }),
      };

      // await axios.post(
      //   `http://localhost:7070/notifications/send`,
      //   requestBody,
        await axios.post(`${apiDomain}/notifications/send`, requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      alert(`Notification sent to ${tokens.length} users.`);
      // Reset form fields and selected users
      setTitle("");
      setDescription("");
      setAdditionalData("");
      setSelectedUsers([]);
    } catch (error) {
      console.error(
        "Error sending notification:",
        error.response?.data || error.message
      );
      alert("Failed to send notification. Please try again.");
    }
  };

  // Pagination logic for notifications
  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className=" min-h-screen lg:ml-[20vw] lg:mr-[2vw] ">
      <div className="border rounded-lg py-2 px-4 bg-blue-50">
        <div className="flex items-center justify-between mt-4 mb-8">
          <h2 className="text-xl font-bold  uppercase">
            Send Notifications to participated user
          </h2>
          <button
            onClick={() => setIsFiltersVisible(!isFiltersVisible)}
            className="p-2 border rounded-full bg-blue-100 hover:bg-gray-200 transition"
          >
            {isFiltersVisible ? <FiChevronUp size={20}/> : <FiChevronDown size={20}/>}
          </button>
        </div>
        {/* Filters Section */}
        {isFiltersVisible && (
          <div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
              {Object.keys(filters).map((key) => (
                <select
                  key={key}
                  name={key}
                  value={filters[key]}
                  onChange={handleFilterChange}
                  className="p-2 border border-gray-300 rounded-lg bg-white"
                >
                  <option value="">{`Select ${key
                    .replace("_", " ")
                    .toUpperCase()}`}</option>
                  {availableFilters[key]?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ))}
            </div>

            {/* Dropdown for Users */}
            <div className="relative mb-6">
              <button
                className="w-full p-3 border border-gray-300 rounded-lg bg-white shadow-sm"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {selectedUsers.length > 0
                  ? `${selectedUsers.length} User(s) Selected`
                  : "Select Users"}
              </button>
              {isDropdownOpen && (
                <div className="absolute z-10 mt-2 w-full bg-white border rounded shadow-lg overflow-y-auto">
                  <ul className="space-y-2 p-2">
                    <li
                      onClick={handleSelectAllUsers}
                      className="cursor-pointer p-2 hover:bg-gray-100"
                    >
                      {selectedUsers.length === filteredParticipants.length
                        ? "Deselect All Users"
                        : "Select All Users"}
                    </li>
                    {filteredParticipants.map((user) => (
                      <li
                        key={user.userId}
                        onClick={() => handleUserSelection(user.userId)}
                        className={`cursor-pointer p-2 ${
                          selectedUsers.includes(user.userId)
                            ? "bg-blue-100"
                            : ""
                        }`}
                      >
                        {user.name || user.instagramUser.username}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Notification Form */}
            <div className="bg-blue-50 rounded">
              <div className="grid gap-4">
                <input
                  type="text"
                  placeholder="Notification Title"
                  className="p-3 border border-gray-300 rounded-lg"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <textarea
                  placeholder="Notification Description"
                  className="p-3 border border-gray-300 rounded-lg"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows="4"
                />
                <input
                  type="url"
                  placeholder="Additional Data (Optional URL)"
                  className="p-3 border border-gray-300 rounded-lg"
                  value={additionalData}
                  onChange={(e) => setAdditionalData(e.target.value)}
                />
              </div>
              <div className="flex justify-center items-center">
                <button
                  onClick={handleSendNotification}
                  className="mt-4  p-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
                >
                  Send Notification
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <NonParticipatedNotification />

      {/* Sent Notifications Section */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Sent Notifications</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {currentNotifications.map((notification, index) => (
            <div
              key={index}
              className="bg-white p-4 grid rounded shadow-sm border hover:scale-105 transform duration-300"
            >
              <div className="flex items-center justify-between mb-4">
                <p className="text-sm text-gray-500">
                  Sent to User IDs: {notification.user_id}
                </p>
                <p className="text-sm text-gray-400">
                  {new Date(notification.created_at).toLocaleString()}
                </p>
              </div>

              <h3 className="font-medium text-lg capitalize">
                Title: <span className="text-base">{notification.title}</span>
              </h3>
              <p className="text-gray-700">Description: {notification.body}</p>
            </div>
          ))}
        </div>

        <div className="flex justify-center my-4">
          {Array.from(
            { length: Math.ceil(notifications.length / notificationsPerPage) },
            (_, i) => (
              <button
                key={i}
                onClick={() => paginate(i + 1)}
                className={`w-8 h-8 mx-1 border rounded-full ${
                  currentPage === i + 1 ? "bg-blue-600 text-white" : "bg-white"
                }`}
              >
                {i + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
