import React from "react";
import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
} from "react-icons/fa";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  onPageSizeChange,
  pageSize,
}) => {
  const handlePageInputChange = (e) => {
    const pageNumber = Math.max(
      1,
      Math.min(totalPages, parseInt(e.target.value) || 1)
    );
    onPageChange(pageNumber);
  };

  return (
    <div className="flex flex-col items-center space-y-6 sm:flex-row sm:justify-between sm:items-center mt-6 px-4">
      {/* Page Size Selector */}
      <div className="flex items-center space-x-3">
        <span className="text-gray-600 font-semibold">Show:</span>
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(parseInt(e.target.value))}
          className="border border-gray-300 rounded-md p-2 text-sm bg-white shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
        >
          {[10, 20, 50, 100].map((size) => (
            <option key={size} value={size}>
              {size} per page
            </option>
          ))}
        </select>
      </div>

      {/* Pagination Controls */}
      <div className="flex items-center space-x-4">
        <button
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
          className={`flex items-center justify-center w-10 h-10 rounded-full ${
            currentPage === 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600 text-white"
          } shadow-lg transform transition-transform active:scale-95`}
        >
          <FaAngleDoubleLeft />
        </button>

        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`flex items-center justify-center w-10 h-10 rounded-full ${
            currentPage === 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600 text-white"
          } shadow-lg transform transition-transform active:scale-95`}
        >
          <FaAngleLeft />
        </button>

        {/* Current Page and Total Pages */}
        <div className="flex items-center space-x-2">
          <span className="text-gray-600 font-semibold">Page</span>
          <input
            type="number"
            value={currentPage}
            onChange={handlePageInputChange}
            min="1"
            max={totalPages}
            className="w-16 border border-gray-300 rounded-md p-2 text-center text-sm shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
          />
          <span className="text-gray-600 font-semibold">of {totalPages}</span>
        </div>

        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`flex items-center justify-center w-10 h-10 rounded-full ${
            currentPage === totalPages
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600 text-white"
          } shadow-lg transform transition-transform active:scale-95`}
        >
          <FaAngleRight />
        </button>

        <button
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
          className={`flex items-center justify-center w-10 h-10 rounded-full ${
            currentPage === totalPages
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600 text-white"
          } shadow-lg transform transition-transform active:scale-95`}
        >
          <FaAngleDoubleRight />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
