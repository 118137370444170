import React from "react";

const VotesValue = ({ votes, setVotes }) => {
  const handleInputChange = (key, field, value) => {
    setVotes((prev) => ({
      ...prev,
      [key]: {
        ...(prev[key] || {}), // Default to empty object if key is undefined
        [field]: value !== "" ? parseInt(value, 10) || 0 : 0,
      },
    }));
  };

  return (
    <section className="mt-8 p-4 border rounded-md bg-blue-50">
      <h3 className="text-2xl font-semibold mb-4">Set Votes & Values</h3>
      <div className="grid lg:grid-cols-2 gap-4">
        {Object.keys(votes || {}).map((key) => (
          <div
            key={key}
            className="flex flex-col lg:flex-row items-center justify-between gap-4 bg-white p-4 shadow-md border rounded-md"
          >
            {/* Votes Weight Input */}
            <div className="w-full lg:w-1/2">
              <label className="block text-sm lg:text-base font-semibold text-gray-800 mb-1">
                {key.charAt(0).toUpperCase() + key.slice(1)} Votes Weight
              </label>
              <input
                type="number"
                value={votes[key]?.votes || 0} // Default to 0 if undefined
                onChange={(e) =>
                  handleInputChange(key, "votes", e.target.value)
                }
                className="w-full border border-gray-300 rounded-md px-4 py-2"
                placeholder="Enter votes weight"
              />
            </div>

            {/* Equal Sign */}
            <div className="hidden lg:block font-bold text-blue-500 text-xl mt-4">
              =
            </div>

            {/* Votes Value Input */}
            <div className="w-full lg:w-1/2">
              <label className="block text-sm lg:text-base font-semibold text-gray-800 mb-1">
                {key.charAt(0).toUpperCase() + key.slice(1)} Votes Value
              </label>
              <input
                type="number"
                value={votes[key]?.value || 0} // Default to 0 if undefined
                onChange={(e) =>
                  handleInputChange(key, "value", e.target.value)
                }
                className="w-full border border-gray-300 rounded-md px-4 py-2"
                placeholder="Enter votes value"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default VotesValue;
