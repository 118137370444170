import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MediaList = ({ userId, token, eventMedia, heading, isiguser }) => {
  const [mediaData, setMediaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null); // State for selected media
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const getMediaDetails = async (url) => {
    try {
      const response = await axios.get(url, {
        params: {
          access_token: token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching media details:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchMediaData = async () => {
      setLoading(true);
      try {
        const initialUrl = `${apiDomain}/api/v2/facebook/user/media?access_token=${token}&id=${userId}&iguser=${
          isiguser === 'true' ? 'true' : ''
        }`;
        const data = await getMediaDetails(initialUrl);
        setMediaData(data.data);
        setNextUrl(data.next_url); // Update nextUrl from response
        setPrevUrl(data.prev_url); // Update prevUrl from response
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    const fetchEventMediaData = async () => {
      setLoading(true);
      try {
        const initialUrl = `${apiDomain}/api/v2/getfilteredigusermedia?userid=${userId}&access_token=${token}&iguser=${
          isiguser === 'true' ? 'true' : ''
        }`;
        const data = await getMediaDetails(initialUrl);
        setMediaData(data.data);
        setNextUrl(data.next_url); // Update nextUrl from response
        setPrevUrl(data.prev_url); // Update prevUrl from response
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (eventMedia) fetchEventMediaData();
    else fetchMediaData();
  }, [userId, eventMedia]);

  const handlePageChange = async (url) => {
    if (url) {
      setLoading(true);
      try {
        const data = await getMediaDetails(url);
        setMediaData(data.data);
        setNextUrl(data.next_url);
        setPrevUrl(data.prev_url);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const openModal = (media) => {
    setSelectedMedia(media); // Set the selected media to state
  };

  const closeModal = () => {
    setSelectedMedia(null); // Close the modal
  };

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error) return <div className="text-center py-4 text-red-500">Error: {error.message}</div>;

  return (
    <div className="container px-4 py-6">
      <h1 className="text-4xl font-extrabold mb-2 text-center text-gray-800 py-1">{heading}</h1>
      <div className="w-full h-1 bg-gradient-to-r from-blue-400 to-purple-600 mb-4"></div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8 lg:gap-10">
        {mediaData &&
          mediaData.map((media) => (
            <div
              key={media.id}
              className="relative group bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer h-[50vh] border-2 border-gray-200 hover:border-blue-500 transition duration-300"
              onClick={() => openModal(media)} // Open modal on click
            >
              <div className="absolute top-0 left-0 z-20 bg-white bg-opacity-90 w-full px-3 py-2">
                <span className="text-gray-900 font-semibold">@{media.username}</span>
              </div>
              <div className="relative h-full">
                {media.media_type === 'IMAGE' && (
                  <img
                    src={media.media_url}
                    alt={media.caption}
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                  />
                )}
                {media.media_type === 'VIDEO' && (
                  <video
                    src={media.media_url}
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                    controls
                  />
                )}
              </div>
            </div>
          ))}
      </div>

      {/* Modal for displaying selected media */}
      {selectedMedia && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-white rounded-lg overflow-hidden relative w-11/12 max-w-4xl">
            <button
              onClick={closeModal}
              className="absolute top-3 right-3 text-gray-800 hover:text-white bg-red-600 rounded-full p-2"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="p-4 flex justify-center items-center">
              {selectedMedia.media_type === 'IMAGE' && (
                <img src={selectedMedia.media_url} alt={selectedMedia.caption} className="w-full h-auto rounded-lg" />
              )}
              {selectedMedia.media_type === 'VIDEO' && (
                <video src={selectedMedia.media_url} className="w-full h-auto rounded-lg" controls />
              )}
            </div>
            <div className="p-4">
              <p className="text-gray-800 font-semibold text-center">{selectedMedia.caption || 'No caption available'}</p>
            </div>
          </div>
        </div>
      )}

      {/* Pagination buttons */}
      <div className="flex justify-between py-4">
        {prevUrl && (
          <button
            onClick={() => handlePageChange(prevUrl)}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
          >
            Previous
          </button>
        )}
        {nextUrl && (
          <button
            onClick={() => handlePageChange(nextUrl)}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default MediaList;
