import React, { useState, useEffect } from "react";
import EventCard from "./EventCard";
import LoadingScreen from '../utilities/LoadingScreen'

const EventCardParent = ({ events }) => {
  const [openCardStates, setOpenCardStates] = useState({});

  useEffect(() => {
    console.log("Events:", events);

    // Set the first card as open
    const initialStates = events.reduce((acc, _, index) => {
      acc[index] = index === 0; // Only the first card is open
      return acc;
    }, {});
    setOpenCardStates(initialStates);
  }, [events]);


  const handleToggleCard = (index) => {
    setOpenCardStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index], // Toggle the open state of the specific card
    }));
  };

  return (
    <div>
      {events.map((event, index) => (
        <div
          key={event.eventID}
          className="relative transition transform rounded-lg overflow-hidden bg-white"
        >
          {/* Serial Number */}
          <div className="absolute top-0 left-0 bg-blue-500 hover:bg-blue-600 hover:cursor-pointer text-gray-50 text-[8px] w-5 h-5 flex items-center justify-center rounded-full font-semibold">
            {index + 1}
          </div>
          <EventCard
            event={event}
            isOpen={!!openCardStates[index]} // Use double negation to ensure boolean value
            onToggle={() => handleToggleCard(index)}
          />
        </div>
      ))}
    </div>
  );
};

export default EventCardParent;





// import React, { useState, useEffect } from "react";
// import EventCard from "./EventCard";

// const EventCardParent = ({ events }) => {
//   // Initialize open states for multiple cards
//   const [openCardStates, setOpenCardStates] = useState({});

//   // Set the first card as open when events change
//   useEffect(() => {
//     const initialStates = events.reduce((acc, _, index) => {
//       acc[index] = index === 0; // Only the first card is open
//       return acc;
//     }, {});
//     setOpenCardStates(initialStates);
//   }, [events]);

//   const handleToggleCard = (index) => {
//     setOpenCardStates((prevStates) => ({
//       ...prevStates,
//       [index]: !prevStates[index], // Toggle the open state of the specific card
//     }));
//   };

//   return (
//     <div>
//       {events.map((event, index) => (
//         <div
//           key={event.id}
//           className="relative transition transform rounded-lg overflow-hidden bg-white"
//         >
//           {/* Serial Number */}
//           <div className="absolute top-0 left-0  bg-gray-800 hover:bg-blue-600 hover:cursor-pointer text-gray-50 text-[8px] w-5 h-5 flex items-center justify-center rounded-full font-semibold">
//             {index + 1}
//           </div>
//           <EventCard
//             event={event}
//             isOpen={!!openCardStates[index]} // Use double negation to ensure boolean value
//             onToggle={() => handleToggleCard(index)}
//           />
//         </div>
//       ))}
//     </div>
//   );
// };

// export default EventCardParent;
