import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaTachometerAlt,
  FaUsers,
  FaTags,
  FaMapMarkerAlt,
  FaVideo,
  FaHashtag,
  FaCog,
  FaGavel,
  FaCalendarCheck,
  FaShieldAlt,
  FaFileContract,
  FaWallet,
  FaTrophy,
  FaHandsHelping,
  FaShareSquare,
  FaHandHoldingUsd,
  FaHandshake, // Icon for Collab Requests
} from "react-icons/fa";

const SideNav = ({ isMobile, isCollapsed, toggleSidebar }) => {
  // const [settingsOpen, setSettingsOpen] = useState(false);
  const [selected, setSelected] = useState("/");

  // const { access_token } = useParams();
  // const location = useLocation();
  // const toggleSettings = () => setSettingsOpen(!settingsOpen);

  const handleNavClick = (path) => {
    setSelected(path);
    if (isMobile) toggleSidebar();
  };

  return (
    <div
      className={`fixed top-0 bottom-0 left-0 h-screen z-50 bg-white text-black transition-all duration-300 ease-in-out overflow-y-auto border-r shadow-lg sidebar ${
        isCollapsed ? "" : "lg:w-[18vw] w-[12rem]"
      } ${isMobile && isCollapsed ? "hidden" : "block"}`}
    >
      <div className="flex items-center justify-between p-2 lg:p-4 mx-auto ">
        {!isCollapsed && (
          <label className="w-full ml-[2vw] font-bold text-[#202224] text-sm lg:text-[1.25vw]">
            <span className="text-[#4880FF]">NAMASVI-</span>Nexus
          </label>
        )}
        {isMobile && (
          <button onClick={toggleSidebar} className="p-2">
            <FaBars className="text-black w-4" />
          </button>
        )}
      </div>

      <div className="flex flex-col lg:space-y-2 px-[1vw] lg:mt-2 lg:mb-4">
        <NavItem
          icon={<FaTachometerAlt />}
          text="Dashboard"
          link="/"
          isCollapsed={isCollapsed}
          isSelected={selected === "/"}
          onClick={() => handleNavClick("/")}
        />
        <NavItem
          icon={<FaCalendarCheck />}
          text="Events"
          link="/events"
          isCollapsed={isCollapsed}
          isSelected={selected === "/events"}
          onClick={() => handleNavClick("/events")}
        />
        <NavItem
          icon={<FaUsers />}
          text="Participants"
          link="/participants"
          isCollapsed={isCollapsed}
          isSelected={selected === "/participants"}
          onClick={() => handleNavClick("/participants")}
        />
        <NavItem
          icon={<FaHandHoldingUsd />}
          text="Sponsors"
          link="/sponsors"
          isCollapsed={isCollapsed}
          isSelected={selected === "/sponsors"}
          onClick={() => handleNavClick("/sponsors")}
        />
        {/* <NavItem
          icon={<FaHandshake />}
          text="Collab Requests"
          link={`/collab-request`}
          isCollapsed={isCollapsed}
          isSelected={selected === `/collab-request`}
          onClick={() => handleNavClick("/collab-request")}
        /> */}
        {/* <NavItem
          icon={<FaTags />}
          text="Category"
          link="/category"
          isCollapsed={isCollapsed}
          isSelected={selected === "/category"}
          onClick={() => handleNavClick("/category")}
        />
        <NavItem
          icon={<FaMapMarkerAlt />}
          text="Venue"
          link="/venue"
          isCollapsed={isCollapsed}
          isSelected={selected === "/venue"}
          onClick={() => handleNavClick("/venue")}
        />
        <NavItem
          icon={<FaHashtag />}
          text="HashTag"
          link="/eventhashtag"
          isCollapsed={isCollapsed}
          isSelected={selected === "/eventhashtag"}
          onClick={() => handleNavClick("/eventhashtag")}
        /> */}
        <NavItem
          icon={<FaVideo />}
          text="Video Package"
          link="/video-package"
          isCollapsed={isCollapsed}
          isSelected={selected === "/video-package"}
          onClick={() => handleNavClick("/video-package")}
        />
        <NavItem
          icon={<FaWallet />}
          text="Wallet"
          link="/wallet"
          isCollapsed={isCollapsed}
          isSelected={selected === "/wallet"}
          onClick={() => handleNavClick("/wallet")}
        />
        <NavItem
          icon={<FaTrophy />}
          text="Winnings"
          link="/winnings"
          isCollapsed={isCollapsed}
          isSelected={selected === "/winnings"}
          onClick={() => handleNavClick("/winnings")}
        />
        <NavItem
          icon={<FaShareSquare />}
          text="Referral"
          link="/referal"
          isCollapsed={isCollapsed}
          isSelected={selected === "/referal"}
          onClick={() => handleNavClick("/referal")}
        />
        <NavItem
          icon={<FaHandsHelping />}
          text="FAQ's"
          link="/faqs"
          isCollapsed={isCollapsed}
          isSelected={selected === "/faqs"}
          onClick={() => handleNavClick("/faqs")}
        />
        <NavItem
          icon={<FaHandsHelping />}
          text="Collab Tickets"
          link="/collabtickets"
          isCollapsed={isCollapsed}
          isSelected={selected === "/collabtickets"}
          onClick={() => handleNavClick("/collabtickets")}
        />
        <NavItem
          icon={<FaHandsHelping />}
          text="Help & Support"
          link="/helpsupport"
          isCollapsed={isCollapsed}
          isSelected={selected === "/helpsupport"}
          onClick={() => handleNavClick("/helpsupport")}
        />
        <NavItem
          icon={<FaFileContract />}
          text="Privacy Policy"
          link="/privacypolicy"
          isCollapsed={isCollapsed}
          isSelected={selected === "/privacypolicy"}
          onClick={() => handleNavClick("/privacypolicy")}
        />
        <NavItem
          icon={<FaShieldAlt />}
          text="Fair Play Policy"
          link="/fairpolicy"
          isCollapsed={isCollapsed}
          isSelected={selected === "/fairpolicy"}
          onClick={() => handleNavClick("/fairpolicy")}
        />
        <NavItem
          icon={<FaGavel />}
          text="Terms & Conditions"
          link="/termcondition"
          isCollapsed={isCollapsed}
          isSelected={selected === "/termcondition"}
          onClick={() => handleNavClick("/termcondition")}
        />
      </div>
    </div>
  );
};

const NavItem = ({ icon, text, link, isCollapsed, isSelected, onClick }) => (
  <Link
    to={link}
    className={`flex items-center p-3 cursor-pointer rounded-md font-medium lg:font-medium transition-colors duration-300 ${
      isSelected ? "bg-[#4880FF] text-white" : "text-[#202224]"
    } hover:bg-[#4880FF] hover:text-white`}
    onClick={onClick}
  >
    <div className="w-8">{icon}</div>
    {!isCollapsed && <span className="lg:ml-2">{text}</span>}
  </Link>
);

export default SideNav;
