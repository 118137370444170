import React from "react";

const CoinValue = ({ coinValue, setCoinValue }) => {
  return (
    <div className="mt-8 p-4 border rounded-md bg-blue-50 shadow-lg">
      <h3 className="text-lg font-semibold mb-4">Coin Value</h3>
      <div className="relative">
        <input
          type="number"
          value={coinValue}
          onChange={(e) => setCoinValue(e.target.value)}
          className="w-full lg:w-1/2 border rounded-md px-4 py-2"
        />
        <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all">
          Coin Value = ₹{coinValue}
        </label>
      </div>
    </div>
  );
};

export default CoinValue;
