import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../context/DataContext";
import EventCardParent from "./EventCardParent";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { FaPlusCircle, FaArrowUp, FaSearch, FaTimes } from "react-icons/fa";
import LoadingScreen from "../utilities/LoadingScreen";

const EventTable = ({ Dashboard, showSponsoredOnly }) => {
  const { eventData, sponsorCategory, loading } = useContext(DataContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState({ value: "All", label: "All Events" });
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const navigate = useNavigate();

  const handleCreateEvent = () => navigate("/event-form");

  // Scroll to Top function
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  // Show scroll button when scrolled down
  useEffect(() => {
    const handleScroll = () => setShowScrollButton(window.scrollY > 100);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Filter and search logic
  useEffect(() => {
    if (!eventData) return;

    // Step 1: Filter sponsored events if required
    let filtered = [...eventData];
    if (showSponsoredOnly) {
      const sponsoredEventIds = new Set(
        sponsorCategory.map((category) => category.eventId)
      );
      filtered = filtered.filter((event) =>
        sponsoredEventIds.has(event.eventID)
      );
    }

    // Step 2: Apply filter type (Ongoing, Upcoming, Completed)
    const now = new Date();
    filtered = filtered.filter((event) => {
      const startDate = new Date(event.startDate);
      const endDate = new Date(event.endDate);

      if (filter.value === "Ongoing") {
        return startDate <= now && endDate >= now;
      }
      if (filter.value === "Upcoming") {
        return startDate > now;
      }
      if (filter.value === "Completed") {
        return endDate < now;
      }
      return true; // for "All" events
    });

    // Step 3: Apply search query if it exists
    const trimmedSearchQuery = searchQuery.trim().toLowerCase();
    if (trimmedSearchQuery) {
      filtered = filtered.filter((event) =>
        event.eventName.toLowerCase().includes(trimmedSearchQuery)
      );
    }

    setFilteredEvents(filtered);
  }, [eventData, filter, searchQuery, showSponsoredOnly, sponsorCategory]);

  // Handle search input change
  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  // Filter options
  const filterOptions = [
    { value: "All", label: "All Events" },
    { value: "Ongoing", label: "Ongoing" },
    { value: "Upcoming", label: "Upcoming" },
    { value: "Completed", label: "Completed" },
  ];

  return (
    <div className="lg:p-2">
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="flex lg:flex-row flex-col space-y-4 lg:space-y-0 items-center justify-between mb-6">
            {/* Create Button */}
            <div className="grid grid-cols-2 gap-4">
              {!Dashboard && (
                <button
                  onClick={handleCreateEvent}
                  className="flex items-center bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-600 transition"
                >
                  <FaPlusCircle className="mr-2" />
                  Create Event
                </button>
              )}

              {/* Filter Select */}
              <Select
                options={filterOptions}
                value={filter}
                onChange={(selectedOption) => setFilter(selectedOption)}
                className="w-40"
              />
            </div>
            {/* Search Input */}
            <div className="relative w-full px-2 md:w-[80%] lg:w-1/2 ">
              <div className="flex items-center bg-white border border-gray-300 rounded-full shadow-sm">
                <input
                  type="text"
                  placeholder="Search events..."
                  className="w-full text-gray-700 bg-transparent px-4 py-3 rounded-full"
                  value={searchQuery}
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
                {searchQuery && (
                  <button
                    onClick={() => setSearchQuery("")}
                    className="text-gray-500 h-full px-3 flex items-center"
                    style={{ position: "absolute", right: "45px" }}
                  >
                    <FaTimes />
                  </button>
                )}
                <button
                  className="text-gray-500 h-full px-3 flex items-center rounded-r-full absolute right-4 lg:right-4"
                  aria-label="Search"
                >
                  <FaSearch />
                </button>
              </div>
            </div>
          </div>

          {/* Event Cards */}
          <div className="grid grid-cols-1 gap-6">
            <EventCardParent events={filteredEvents} />
          </div>

          {/* Scroll Button */}
          {showScrollButton && (
            <button
              onClick={scrollToTop}
              className="fixed bottom-4 right-4 bg-blue-100 p-3 rounded-full shadow-lg"
              aria-label="Scroll to Top"
            >
              <FaArrowUp />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default EventTable;





// import React, { useContext, useState, useEffect } from "react";
// import { DataContext } from "../context/DataContext";
// import EventCardParent from "./EventCardParent";
// import Select from "react-select";
// import { useNavigate } from "react-router-dom";
// import {
//   FaPlusCircle,
//   FaArrowUp,
//   FaSearch,
//   FaTimes,
// } from "react-icons/fa";
// import LoadingScreen from "../utilities/LoadingScreen";

// const EventTable = ({ Dashboard }) => {
//   const { eventData, loading } = useContext(DataContext);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filter, setFilter] = useState({ value: "All", label: "All Events" });
//   const [filteredEvents, setFilteredEvents] = useState([]);
//   const [showScrollButton, setShowScrollButton] = useState(false);
//   const navigate = useNavigate();

//   if (loading) <LoadingScreen />;

//   const handleCreateEvent = () => navigate("/event-form");

//   // Scroll to Top function
//   const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

//   // Show scroll button when scrolled down
//   useEffect(() => {
//     const handleScroll = () => setShowScrollButton(window.scrollY > 100);
//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   // Filter and search logic
//   useEffect(() => {
//     if (!eventData) return;

//     const now = new Date();

//     // Step 1: Apply filter type (Ongoing, Upcoming, Completed)
//     const filteredByType = eventData.filter((event) => {
//       const startDate = new Date(event.startDate);
//       const endDate = new Date(event.endDate);

//       if (filter.value === "Ongoing") {
//         return startDate <= now && endDate >= now;
//       }
//       if (filter.value === "Upcoming") {
//         return startDate > now;
//       }
//       if (filter.value === "Completed") {
//         return endDate < now;
//       }
//       return true; // for "All" events
//     });

//     // Step 2: Apply search query if it exists
//     const trimmedSearchQuery = searchQuery.trim().toLowerCase();
//     const fullyFilteredEvents = trimmedSearchQuery
//       ? filteredByType.filter((event) =>
//           event.eventName.toLowerCase().includes(trimmedSearchQuery)
//         )
//       : filteredByType;

//     setFilteredEvents(fullyFilteredEvents);
//   }, [eventData, filter, searchQuery]);

//   // Filter options
//   const filterOptions = [
//     { value: "All", label: "All Events" },
//     { value: "Ongoing", label: "Ongoing" },
//     { value: "Upcoming", label: "Upcoming" },
//     { value: "Completed", label: "Completed" },
//   ];

//   return (
//     <div className="lg:p-4 ">
//       <div className="flex lg:flex-row flex-col space-y-4 lg:space-y-0 items-center justify-between mb-6 space-x-">
//         {/* Create Button */}
//         <div className=" grid grid-cols-2 gap-4">
//         {!Dashboard && (
//           <button
//             onClick={handleCreateEvent}
//             className="flex items-center bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-600 transition"
//           >
//             <FaPlusCircle className="mr-2" />
//             Create Event
//           </button>
//         )}

//         {/* Filter Select */}
//         <Select
//           options={filterOptions}
//           value={filter}
//           onChange={(selectedOption) => setFilter(selectedOption)}
//           className="w-40"
//         />
//        </div>
//         {/* Search Input */}
//         <div className="relative w-full px-2 md:w-[80%] lg:w-1/2 ">
//           <div className="flex items-center bg-white border border-gray-300 rounded-full shadow-sm">
//             <input
//               type="text"
//               placeholder="Search events..."
//               className="w-full text-gray-700 bg-transparent px-4 py-3 rounded-full"
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//             />
//             {searchQuery && (
//               <button
//                 onClick={() => setSearchQuery("")}
//                 className="text-gray-500 h-full px-3 flex items-center"
//                 style={{ position: "absolute", right: "45px" }}
//               >
//                 <FaTimes />
//               </button>
//             )}
     
//             <button
//               onClick={() => setFilteredEvents(filteredEvents)}
//               className="text-gray-500 h-full px-3 flex items-center rounded-r-full absolute right-4 lg:right-4" 
//             >
//               <FaSearch />
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Event Cards */}
//       <div className="grid grid-cols-1 gap-6">
//         <EventCardParent events={filteredEvents}/>
//       </div>

//       {/* Scroll Button */}
//       {showScrollButton && (
//         <button
//           onClick={scrollToTop}
//           className="fixed bottom-4 right-4 bg-gray-200 p-3 rounded-full shadow-lg"
//           aria-label="Scroll to Top"
//         >
//           <FaArrowUp />
//         </button>
//       )}
//     </div>
//   );
// };

// export default EventTable;










// import React, { useContext, useState, useEffect } from "react";
// import { DataContext } from "../context/DataContext";
// import EventCard from "./EventCard";
// import Select from "react-select";
// import { useNavigate } from "react-router-dom";
// import {
//   FaPlusCircle,
//   FaArrowUp,
//   FaSearch,
//   FaTimes,
//   FaArrowLeft,
// } from "react-icons/fa";
// import LoadingScreen from "../utilities/LoadingScreen";
// import EventCardParent from "./EventCardParent";

// const EventTable = ({ Dashboard }) => {
//   const { eventData, loading } = useContext(DataContext);
//   if (loading) <LoadingScreen />;
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filter, setFilter] = useState({ value: "All", label: "All Events" });
//   const [filteredEvents, setFilteredEvents] = useState([]);
//   const [showScrollButton, setShowScrollButton] = useState(false);
//   const navigate = useNavigate();

//   const handleCreateEvent = () => navigate("/event-form");

//   // Scroll to Top function
//   const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

//   // Show scroll button when scrolled down
//   useEffect(() => {
//     const handleScroll = () => setShowScrollButton(window.scrollY > 100);
//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   // Filter and search logic
//   useEffect(() => {
//     if (!eventData) return;

//     const now = new Date();

//     // Step 1: Apply filter type (Ongoing, Upcoming, Completed)
//     const filteredByType = eventData.filter((event) => {
//       const startDate = new Date(event.startDate);
//       const endDate = new Date(event.endDate);

//       if (filter.value === "Ongoing") {
//         return startDate <= now && endDate >= now;
//       }
//       if (filter.value === "Upcoming") {
//         return startDate > now;
//       }
//       if (filter.value === "Completed") {
//         return endDate < now;
//       }
//       return true; // for "All" events
//     });

//     // Step 2: Apply search query if it exists
//     const trimmedSearchQuery = searchQuery.trim().toLowerCase();
//     const fullyFilteredEvents = trimmedSearchQuery
//       ? filteredByType.filter((event) =>
//           event.eventName.toLowerCase().includes(trimmedSearchQuery)
//         )
//       : filteredByType;

//     setFilteredEvents(fullyFilteredEvents);
//   }, [eventData, filter, searchQuery]);

//   // Filter options
//   const filterOptions = [
//     { value: "All", label: "All Events" },
//     { value: "Ongoing", label: "Ongoing" },
//     { value: "Upcoming", label: "Upcoming" },
//     { value: "Completed", label: "Completed" },
//   ];

//   return (
//     <div className="p-4">
//       <div className="flex items-center justify-between mb-6 space-x-4">
//         {/* Create Button */}
//         {!Dashboard && (
//           <button
//             onClick={handleCreateEvent}
//             className="flex items-center bg-blue-500 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-600 transition"
//           >
//             <FaPlusCircle className="mr-2" />
//             Create Event
//           </button>
//         )}

//         {/* Filter Select */}
//         <Select
//           options={filterOptions}
//           value={filter}
//           onChange={(selectedOption) => setFilter(selectedOption)}
//           className="w-40"
//         />

//         {/* Redesigned YouTube-style Search Input */}
//         <div className="relative w-full lg:w-1/2">
//           <div className="flex items-center bg-white border border-gray-300 rounded-full shadow-sm hover:bg-opacity-90 focus-within:ring-2 focus-within:ring-blue-400 transition">
//             {/* Input Field */}
//             <input
//               type="text"
//               placeholder="Search events..."
//               className="w-full text-gray-700 bg-transparent placeholder-gray-500 focus:outline-none px-4 py-3 rounded-full"
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//             />

//             {/* Clear Icon */}
//             {searchQuery && (
//               <button
//                 onClick={() => setSearchQuery("")}
//                 className="text-gray-500 h-full px-3 flex items-center transition"
//                 style={{
//                   position: "absolute",
//                   right: "45px",
//                   top: 0,
//                   bottom: 0,
//                 }}
//               >
//                 <FaTimes />
//               </button>
//             )}

//             {/* Vertical Line Separator */}
//             <div
//               className="h-full border-l border-gray-300"
//               style={{
//                 position: "absolute",
//                 right: "40px",
//                 top: "50%",
//                 transform: "translateY(-50%)",
//               }}
//             ></div>

//             {/* Search Icon */}
//             <button
//               onClick={() => setFilteredEvents(filteredEvents)}
//               className="text-gray-500 hover:bg-gray-200 h-full px-3 flex items-center transition rounded-r-full"
//               style={{
//                 position: "absolute",
//                 right: "0px",
//                 top: 0,
//                 bottom: 0,
//               }}
//             >
//               <FaSearch />
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Event Cards */}
//       <div className="grid grid-cols-1 gap-6">
//         {filteredEvents.map((event, index) => (
//           <div
//             key={event.index}
//             className="relative transition transform rounded-lg overflow-hidden bg-white"
//           >
//             {/* Serial Number
//             <div className="absolute top-1 left-2 bg-gray-800 hover:bg-blue-600 hover:cursor-pointer text-gray-50 text-[8px] w-5 h-5 flex items-center justify-center rounded-full font-semibold">
//               #{index + 1}
//             </div> */}

//             <EventCardParent events={filteredEvents} />
//           </div>
//         ))}
//       </div>

//       {/* Scroll Button */}
//       {showScrollButton && (
//         <button
//           onClick={scrollToTop}
//           className="fixed bottom-4 right-4 transform -translate-x-1/2 bg-gray-200 bg-opacity-70 text-gray-700 p-3 rounded-full shadow-lg hover:bg-gray-300 transition"
//           aria-label="Scroll to Top"
//         >
//           <FaArrowUp />
//         </button>
//       )}
//     </div>
//   );
// };

// export default EventTable;
