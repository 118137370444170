import React, { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";

const Category = ({
  categories = [], // Default to an empty array
  setCategories,
  title = "Category", // Default title
  options = [], // Default options
  isDropdown = false, // Flag to enable dropdown input
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleAddCategory = () => {
    if (inputValue.trim() && !categories.includes(inputValue)) {
      setCategories([...categories, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleRemoveCategory = (index) => {
    setCategories(categories.filter((_, i) => i !== index));
  };

  const handleDropdownChange = (value) => {
    if (value === "All") {
      setCategories([...new Set([...categories, ...options.filter(opt => opt !== "All")])]);
    } else {
      setInputValue(value);
    }
  };

  return (
    <section className="p-4 border rounded-md bg-blue-50 shadow-md mt-4">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>

      <div className="flex flex-col lg:flex-row gap-4 items-start">
        {/* Input Field or Dropdown */}
        <div className="flex-1">
          {isDropdown ? (
            <select
              className="w-full border px-4 py-2 rounded-md"
              value={inputValue}
              onChange={(e) => handleDropdownChange(e.target.value)}
            >
              <option value="">Select an option</option>
              {options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-full border px-4 py-2 rounded-md"
              placeholder={`Enter ${title}`}
            />
          )}
        </div>

        {/* Add Button */}
        <button
          type="button"
          onClick={handleAddCategory}
          className="flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
        >
          <FaPlus /> Add
        </button>
      </div>

      {/* Category List */}
      <div className="flex flex-wrap gap-2 mt-4">
        {categories.length > 0 ? (
          categories.map((item, index) => (
            <span
              key={index}
              className="bg-gray-200 px-3 py-1 rounded-full flex items-center gap-2"
            >
              {item}
              <button
                type="button"
                onClick={() => handleRemoveCategory(index)}
                className="text-red-600 hover:text-red-800"
              >
                <FaTrash />
              </button>
            </span>
          ))
        ) : (
          <p className="text-gray-500">No {title.toLowerCase()} added yet.</p>
        )}
      </div>
    </section>
  );
};

export default Category;
