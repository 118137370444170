import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../newComponents/context/DataContext";
import Pagination from "../../newComponents/utilities/Pagination";

const ParticipantTable = () => {
  const { participant } = useContext(DataContext);
  const [participants, setParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [filters, setFilters] = useState({
    event_id: "",
    city: "",
    state: "",
    pincode: "",
    age_category: "",
    gender_category: "",
    followers_count_category: "",
    generic_category: "",
    international: "",
  });
  const [availableFilters, setAvailableFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passphrase, setPassphrase] = useState("");
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (participant?.participants) {
      setParticipants(participant.participants);
      setFilteredParticipants(participant.participants);
      generateFilterOptions(participant.participants);
    }
  }, [participant]);

  // Generate unique filter options based on participant data
  const generateFilterOptions = (data) => {
    if (!data) return;
    const options = {
      event_id: [...new Set(data.flatMap((p) => p.participationDetails?.map((d) => d.event_id) || []))],
      city: [...new Set(data.flatMap((p) => p.participationDetails?.map((d) => d.city?.String) || []))],
      state: [...new Set(data.flatMap((p) => p.participationDetails?.map((d) => d.state?.String) || []))],
      pincode: [...new Set(data.flatMap((p) => p.participationDetails?.map((d) => d.pincode?.String) || []))],
      age_category: [...new Set(data.flatMap((p) => p.participationDetails?.map((d) => d.age_category?.String) || []))],
      gender_category: [...new Set(data.flatMap((p) => p.participationDetails?.map((d) => d.gender_category?.String) || []))],
      followers_count_category: [...new Set(data.flatMap((p) => p.participationDetails?.map((d) => d.followers_count_category?.String) || []))],
      generic_category: [...new Set(data.flatMap((p) => p.participationDetails?.map((d) => d.generic_category?.String) || []))],
      international: ["true", "false"],
    };
    setAvailableFilters(options);
  };

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  // Apply filters
  useEffect(() => {
    const applyFilters = () => {
      if (!participants) return;

      let filtered = participants;

      if (filters.event_id) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some((detail) => detail.event_id.toString() === filters.event_id)
        );
      }

      if (filters.city) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some((detail) => detail.city?.String === filters.city)
        );
      }

      if (filters.state) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some((detail) => detail.state?.String === filters.state)
        );
      }

      if (filters.pincode) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some((detail) => detail.pincode?.String === filters.pincode)
        );
      }

      if (filters.age_category) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some((detail) => detail.age_category?.String === filters.age_category)
        );
      }

      if (filters.gender_category) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some((detail) => detail.gender_category?.String === filters.gender_category)
        );
      }

      if (filters.followers_count_category) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some(
            (detail) => detail.followers_count_category?.String === filters.followers_count_category
          )
        );
      }

      if (filters.generic_category) {
        filtered = filtered.filter((part) =>
          part.participationDetails?.some((detail) => detail.generic_category?.String === filters.generic_category)
        );
      }

      if (filters.international !== "") {
        const isInternational = filters.international === "true";
        filtered = filtered.filter((part) =>
          part.participationDetails?.some((detail) => detail.international === isInternational)
        );
      }

      setFilteredParticipants(filtered);
    };

    applyFilters();
  }, [filters, participants]);

  // Pagination Logic
  const totalPages = Math.ceil(filteredParticipants.length / pageSize);
  const paginatedParticipants = filteredParticipants.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  // Handle Row Click
  const handleRowClick = (participant) => {
    setSelectedParticipant(participant);
    navigate(
      `/participants/${participant.userId}/${participant.instagramBusinessAccountId}/${participant.isiguser}`,
      {
        state: {
          participant,
        },
      }
    );
  };

  // Handle Passphrase Submission
  const handlePassphraseSubmit = () => {
    if (passphrase.trim() !== "" && selectedParticipant) {
      navigate(
        `/participants/${selectedParticipant.userId}/${selectedParticipant.instagramBusinessAccountId}`,
        {
          state: {
            participant: selectedParticipant,
          },
        }
      );
      setIsModalOpen(false);
      setPassphrase("");
    }
  };

  return (
    <main className="lg:ml-[20vw] lg:mr-8">
      {/* Filters Section */}
      <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
        <h3 className="text-lg font-bold mb-4">Filters</h3>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {Object.keys(filters).map((key) => (
            <select
              key={key}
              name={key}
              value={filters[key]}
              onChange={handleFilterChange}
              className="p-2 border border-gray-300 rounded-lg bg-white"
            >
              <option value="">{`Select ${key.replace("_", " ").toUpperCase()}`}</option>
              {availableFilters[key]?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ))}
        </div>
      </div>

      {/* Participants Table */}
      <div className="border border-gray-300 rounded-lg overflow-x-auto my-8">
        <table className="min-w-full">
          <thead>
            <tr className="bg-gray-100 text-gray-800 text-left">
              <th className="p-4">Profile Pic.</th>
              <th className="p-4">Insta User Name</th>
              <th className="p-4">Followers</th>
              <th className="p-4">Name</th>
              <th className="p-4">Date Of Joining</th>
              <th className="p-4">Events Participated</th>
            </tr>
          </thead>
          <tbody>
            {paginatedParticipants.map((part, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(part)}
                className="hover:bg-gray-100 cursor-pointer transition duration-200 border-b ease-in-out"
              >
                <td className="p-4">
                  <img
                    src={part.instagramUser.profile_picture_url}
                    alt="profile-pic"
                    className="w-12 h-12 rounded-full border border-gray-300 shadow-sm hover:scale-105 hover:shadow-lg transition-transform"
                  />
                </td>
                <td className="p-4">{part.instagramUser.username}</td>
                <td className="p-4">{part.instagramUser.followers_count}</td>
                <td className="p-4">{part.name}</td>
                <td className="p-4">
                  {new Date(part.joiningTime).toLocaleDateString("en-GB")}
                </td>
                <td className="p-4">{part.participationDetails.length}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Component */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />

      {/* Passphrase Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded p-6 w-full max-w-sm">
            <h3 className="text-lg mb-4">Enter Passphrase</h3>
            <input
              type="text"
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
              placeholder="Passphrase"
              className="border w-full px-3 py-2 rounded mb-4"
            />
            <div className="flex justify-between">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Cancel
              </button>
              <button
                onClick={handlePassphraseSubmit}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default ParticipantTable;









// import React, { useContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { DataContext } from "../../context/DataContext";
// import axios from "axios";
// import Cookies from "js-cookie";

// const ParticipantTable = () => {
//   const { eventData, participant, loading } = useContext(DataContext);

//   const [currentPage, setCurrentPage] = useState(1);
//   const [filter, setFilter] = useState("All");
//   const [selectedEvent, setSelectedEvent] = useState(null);
//   const [participants, setParticipants] = useState([]);
//   const [prevURL, setPrevURL] = useState("");
//   const [nextURL, setNextURL] = useState("");
//   const [pageSize, setPageSize] = useState(10);
//   const [isModalOpen, setIsModalOpen] = useState(false); 
//   const [passphrase, setPassphrase] = useState(""); 
//   const [selectedParticipant, setSelectedParticipant] = useState(null);
//   const navigate = useNavigate();

//   const apiDomain = process.env.REACT_APP_API_DOMAIN;
//   const accessToken = Cookies.get("fbauthToken");

//   const fetchParticipants = async (eventId, page = 1, pageSize = 10) => {
//     try {
//       const response = await axios.get(
//         `${apiDomain}/api/v2/getparticipantsbyevent`,
//         {
//           params: {
//             event_id: eventId,
//             page: page,
//             pageSize: pageSize,
//             access_token: accessToken,
//           },
//         }
//       );
//       return response.data;
//     } catch (error) {
//       console.error("Error fetching participants:", error);
//       throw error;
//     }
//   };

//   useEffect(() => {
//     if (loading || !selectedEvent) {
//       if (participant) setParticipants(participant.participants);
//       return;
//     }

//     const loadParticipants = async () => {
//       try {
//         const data = await fetchParticipants(
//           selectedEvent.eventID,
//           currentPage,
//           pageSize
//         );
//         setParticipants(data.participants);
//         setPrevURL(data.prev_url);
//         setNextURL(data.next_url);
//       } catch (error) {
//         console.error("Failed to load participants:", error);
//       }
//     };

//     loadParticipants();
//   }, [selectedEvent, currentPage, pageSize, loading]);

//   const handleFilterChange = (event) => {
//     const eventName = event.target.value;
//     if (eventName === "All") {
//       setFilter(eventName);
//       setCurrentPage(1);
//       setParticipants(participant.participants);
//       return;
//     }
//     setFilter(eventName);
//     setCurrentPage(1); 
//     const selected = eventData.find((e) => e.eventName === eventName);
//     setSelectedEvent(selected || null);
//   };

//   const handlePagination = (page) => {
//     setCurrentPage(page);
//   };


  // const handleRowClick = (participant) => {
  //   setSelectedParticipant(participant);
  //   console.log("participant: ", participant)
  //   navigate(
  //     `/participants/${participant.userId}/${participant.instagramBusinessAccountId}/${participant.isiguser}`, 
  //     {
  //       state: {
  //         participant: participant,
  //       }
  //     }
  //   );
  // };

  // const handlePassphraseSubmit = () => {
  //   if (passphrase.trim() !== "") {
  //     navigate(
  //       `/participants/${selectedParticipant.userId}/${selectedParticipant.instagramBusinessAccountId}`, 
  //       {
  //         state: {
  //           participant: selectedParticipant,
  //         }
  //       }
  //     );
  //     setIsModalOpen(false); 
  //     setPassphrase(""); 
  //   }
  // };

//   const totalPages = Math.ceil(participants ? participants.length / pageSize : []);
//   const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

//   return (
//     <main className="lg:ml-[20vw] lg:mr-8">
//       <div className="border border-gray-300 rounded-lg overflow-x-auto my-8">
//         <table className="min-w-full ">
//           <thead>
//             <tr className="bg-gray-100 text-gray-800 text-left">
//               <th className="p-4">Profile Pic.</th>
//               <th className="p-4">Insta User Name</th>
//               <th className="p-4">Followers</th>
//               <th className="p-4">Name</th>
//               <th className="p-4">Date Of Joining</th>
//               <th className="p-4">Events Participated</th>
//             </tr>
//           </thead>
//           <tbody>
//             {participants &&
//               participants.map((part, index) => (
//                 <tr
//                   key={index}
//                   onClick={() => handleRowClick(part)}
//                   className="hover:bg-gray-100 cursor-pointer transition duration-200 border-b ease-in-out"
//                 >
//                   <td className="p-4">
//                     <img
//                       src={part.instagramUser.profile_picture_url}
//                       alt="/profile-pic"
//                       className="w-12 h-12 rounded-full border border-gray-300 shadow-sm hover:scale-105 hover:shadow-lg transition-transform"
//                     />
//                   </td>
//                   <td className="p-4">{part.instagramUser.username}</td>
//                   <td className="p-4">{part.instagramUser.followers_count}</td>
//                   <td className="p-4">{part.name}</td>
//                   <td className="p-4">{new Date(part.joiningTime).toLocaleDateString("en-GB")}</td>
//                   <td className="p-4">{part.participationDetails.length}</td>
//                 </tr>
//               ))}
//           </tbody>
//         </table>
//       </div>

//       <div className="flex justify-center items-center space-x-2 mb-4">
//         <button
//           onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
//           className="w-8 h-8 bg-gray-200 text-gray-600 rounded-full shadow-sm hover:bg-gray-300 transition ease-in-out"
//         >
//           &lt;
//         </button>
//         {pageNumbers.map((page) => (
//           <button
//             key={page}
//             onClick={() => handlePagination(page)}
//             className={`w-8 h-8 rounded-full ${currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'} shadow-sm hover:bg-blue-400 hover:text-white transition ease-in-out`}
//           >
//             {page}
//           </button>
//         ))}
//         <button
//           onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
//           className="w-8 h-8 bg-gray-200 text-gray-600 rounded-full shadow-sm hover:bg-gray-300 transition ease-in-out"
//         >
//           &gt;
//         </button>
//       </div>

//        {/* Modal */}
//        {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white rounded p-6 w-full max-w-sm">
//             <h3 className="text-lg mb-4">Enter Passphrase</h3>
//             <input
//               type="text"
//               value={passphrase}
//               onChange={(e) => setPassphrase(e.target.value)}
//               placeholder="Passphrase"
//               className="border w-full px-3 py-2 rounded mb-4"
//             />
//             <div className="flex justify-between">
//             <button
//                 onClick={() => setIsModalOpen(false)}
//                 className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
//               >
//                 Cancel
//               </button>
//               <button
//                 onClick={handlePassphraseSubmit}
//                 className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
//               >
//                 OK
//               </button>
            
//             </div>
//           </div>
//         </div>
//       )}
//     </main>
//   );
// };

// export default ParticipantTable;

 