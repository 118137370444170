import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const apiBase = `${apiDomain}/api/v2/faq`;

const FAQManager = () => {
  const [faqs, setFaqs] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');
  const [editID, setEditID] = useState(null);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const formRef = useRef(null); // Reference for the Add/Edit form

  const fetchFAQs = async () => {
    try {
      const response = await axios.get(`${apiBase}/get`);
      setFaqs(response.data);
    } catch (error) {
      showAlert('Error fetching FAQs', 'error');
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  const showAlert = (message, type) => {
    setAlert({ message, type });
    setTimeout(() => setAlert({ message: '', type: '' }), 3000);
  };

  const handleSave = async () => {
    const payload = { question: newQuestion, answer: newAnswer };
    try {
      if (editID) {
        await axios.put(`${apiBase}/update`, { id: editID, ...payload });
        showAlert('FAQ updated successfully', 'success');
      } else {
        await axios.post(`${apiBase}/create`, payload);
        showAlert('FAQ added successfully', 'success');
      }
      fetchFAQs();
      resetForm();
    } catch (error) {
      showAlert('Error saving FAQ', 'error');
    }
  };

  const confirmDelete = (id) => {
    setDeleteID(id);
    setShowConfirmation(true);
  };

  const handleDelete = async () => {
    setShowConfirmation(false);
    try {
      await axios.delete(`${apiBase}/delete`, { params: { id: deleteID } });
      showAlert('FAQ deleted successfully', 'success');
      fetchFAQs();
    } catch (error) {
      showAlert('Error deleting FAQ', 'error');
    }
  };

  const handleEdit = (faq) => {
    setNewQuestion(faq.question);
    setNewAnswer(faq.answer);
    setEditID(faq.id);
    // Scroll to the Add/Edit Form
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const resetForm = () => {
    setNewQuestion('');
    setNewAnswer('');
    setEditID(null);
  };

  return (
    <div className="min-h-screen p-4 lg:ml-[20vw] lg:mr-[2vw]">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-6">
        FAQ Manager
      </h1>

      {alert.message && (
        <div
          className={`${
            alert.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          } border-l-4 p-4 mb-6 rounded-md`}
        >
          {alert.message}
        </div>
      )}

      {/* Add/Edit Form */}
      <div ref={formRef} className="bg-blue-50 shadow-md rounded-md p-6 mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          {editID ? 'Edit FAQ' : 'Add New FAQ'}
        </h2>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Enter Question"
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <textarea
            placeholder="Enter Answer"
            value={newAnswer}
            onChange={(e) => setNewAnswer(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="5"
          ></textarea>
          <div className="flex items-center gap-4">
            <button
              onClick={handleSave}
              className={`px-6 py-2 text-white rounded-md ${
                editID ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-blue-500 hover:bg-blue-600'
              }`}
            >
              {editID ? 'Update FAQ' : 'Add FAQ'}
            </button>
            {editID && (
              <button
                onClick={resetForm}
                className="px-6 py-2 bg-gray-500 hover:bg-gray-600 text-white rounded-md"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>

      {/* FAQ List */}
      <div className="space-y-6">
        {faqs.map((faq) => (
          <div
            key={faq.id}
            className="bg-blue-50 shadow-md rounded-md p-6 border border-gray-200"
          >
            <h3 className="text-xl font-semibold text-gray-800">{faq.question}</h3>
            <p className="mt-2 text-gray-600">{faq.answer}</p>
            <div className="mt-4 flex items-center gap-4">
              <button
                onClick={() => handleEdit(faq)}
                className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 flex items-center gap-2"
              >
                <FaEdit /> Edit
              </button>
              <button
                onClick={() => confirmDelete(faq.id)}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 flex items-center gap-2"
              >
                <FaTrash /> Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md shadow-md w-96">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Are you sure you want to delete this FAQ?
            </h3>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowConfirmation(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FAQManager;
