import React, { useState } from "react";
import { FaExclamationCircle } from "react-icons/fa";

const Winnings = ({ data, eventData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedWinner, setSelectedWinner] = useState(null); // To store the selected winner for overlay
  const itemsPerPage = 4;
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const getEventName = (eventId) => {
    const event = eventData.find((event) => event.eventID === eventId);
    return event ? event.eventName : "Unknown Event";
  };

  const indexOfLastWinner = currentPage * itemsPerPage;
  const indexOfFirstWinner = indexOfLastWinner - itemsPerPage;
  const currentWinners = data.slice(indexOfFirstWinner, indexOfLastWinner);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`px-3 py-1 mx-1 ${
            i === currentPage ? "bg-blue-500 text-white" : "bg-gray-200"
          } rounded hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-400`}
          aria-label={`Go to page ${i}`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg border border-gray-200">
      <h2 className="text-3xl font-bold text-center mb-6">Winners List</h2>

      {/* No Winners Message */}
      {data.length === 0 && (
        <div className="flex flex-col items-center justify-center py-10">
          <FaExclamationCircle className="text-red-500 text-6xl mb-4" />
          <p className="text-lg font-semibold text-gray-700">
            No winners found for the selected filters. Try changing the filter criteria.
          </p>
        </div>
      )}

      {/* Winners List */}
      {data.length > 0 && (
        <>
          <div className="grid grid-cols-1 gap-6">
            {currentWinners.map((winner, index) => (
              <div
                key={index}
                className="bg-gray-100 p-4 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition-shadow duration-300"
                onClick={() => setSelectedWinner(winner)} // Set selected winner for overlay
                role="button"
                tabIndex="0"
                onKeyDown={(e) => e.key === "Enter" && setSelectedWinner(winner)}
                aria-label={`View details of winner ${winner.iguser.name}`}
              >
                <div className="flex items-center space-x-4">
                  <img
                    src={winner.iguser.profile_picture_url}
                    alt={winner.iguser.name}
                    className="w-16 h-16 rounded-full border-2 border-blue-500"
                  />
                  <div>
                    <h3 className="text-xl font-semibold">{winner.iguser.name}</h3>
                    <p className="text-gray-600">{getEventName(winner.eventID)}</p>
                    <p className="text-gray-500 text-sm">{winner.Category}</p>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-sm font-semibold">
                    Total Votes: <span className="text-blue-600">{winner.votes}</span>
                  </p>
                  <p className="text-sm font-semibold">
                    Followers: <span className="text-blue-600">{winner.iguser.followers_count}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-6 space-x-2">
            {renderPageNumbers()}
          </div>
        </>
      )}

      {/* Winner Overlay */}
      {selectedWinner && (
        <WinnerOverlay winner={selectedWinner} onClose={() => setSelectedWinner(null)} />
      )}
    </div>
  );
};

export default Winnings;

// WinnerOverlay Component
const WinnerOverlay = ({ winner, onClose }) => {
  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
      role="dialog"
      aria-labelledby="winner-overlay-title"
      aria-describedby="winner-overlay-description"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 id="winner-overlay-title" className="text-2xl font-bold mb-4">
          Winner Details
        </h2>
        <img
          src={winner.iguser.profile_picture_url}
          alt={winner.iguser.name}
          className="w-32 h-32 rounded-full border-4 border-blue-500 shadow-lg mb-4"
        />
        <h3 className="text-xl font-semibold">{winner.iguser.name}</h3>
        <p id="winner-overlay-description" className="text-gray-600 font-normal">
          Biography: {winner.iguser.biography}
        </p>
        <p className="text-lg my-4">Amount Won: {winner.winnings}</p>
        <p className="text-lg mt-4 text-center text-blue-600">Congratulations! 🎉</p>
        <button
          onClick={onClose}
          className="mt-4 w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Close
        </button>
      </div>
    </div>
  );
};



// import React, { useContext, useEffect, useState } from "react";
// import { DataContext } from "../context/DataContext";

// const Winnings = ({ data = null }) => {
//   const { eventData, winners, categories, loading } = useContext(DataContext);
//   if (loading) <></>;

//   const [winnerData, setWinnerData] = useState(null);
//   console.log("winerrṛ", eventData, winners, categories);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [selectedWinner, setSelectedWinner] = useState(null);
//   const itemsPerPage = 4; // Number of winners per page
//   const totalPages = Math.ceil(winners ? winners.length / itemsPerPage : 1);
//   if (!winnerData) setWinnerData(winners);
//   useEffect(() => {
//     console.log("winners", data);
//     setWinnerData(data);
//   }, [data]);

//   // Function to get the event name based on event_id from winners
//   const getEventName = (eventId) => {
//     console.log("Finding event name for eventId:", eventId);
//     const event = eventData?.find((event) => event.eventID === eventId);
//     console.log("Event found:", event);
//     return event ? event.eventName : "Unknown Event";
//   };

//   // Function to get the category name based on category_id from winners
//   const getCategoryName = (categoryId) => {
//     const category = categories?.find((cat) => cat.id === categoryId);
//     return category ? category.name : "Unknown Category"; // Return category name if found, otherwise "Unknown Category"
//   };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handleRowClick = (winner) => {
//     setSelectedWinner(winner);
//   };

//   const indexOfLastWinner = currentPage * itemsPerPage;
//   const indexOfFirstWinner = indexOfLastWinner - itemsPerPage;
//   const currentWinners = winners.slice(indexOfFirstWinner, indexOfLastWinner);

//   return (
//     <div className="my-10 p-6 bg-white rounded-lg shadow-lg border border-gray-200">
//       <h2 className="text-3xl font-bold text-center mb-6">Winners List</h2>
//       <table className="min-w-full border-collapse border border-gray-200">
//         <thead>
//           <tr className="bg-gray-100 text-start">
//             <th className="border border-gray-200 p-4">Event Name</th>
//             <th className="border border-gray-200 p-4">Category</th>
//             <th className="border border-gray-200 p-4">Winner Name</th>
//             <th className="border border-gray-200 p-4">Total Votes</th>
//             <th className="border border-gray-200 p-4">Followers</th>
//             <th className="border border-gray-200 p-4">Profile pic</th>
//           </tr>
//         </thead>
//         <tbody>
//           {eventData &&
//             currentWinners &&
//             currentWinners.map((winner, index) => (
//               <tr
//                 key={index}
//                 onClick={() => handleRowClick(winner)}
//                 className="hover:bg-gray-100 cursor-pointer"
//               >
//                 <td className="border border-gray-300 p-4">
//                   {/* {getEventName(winner.eventID)} */}
//                    {winner.eventID}
//                 </td>
//                 <td className="border border-gray-300 p-4">
//                   {winner.Category}
//                 </td>
//                 <td className="border border-gray-300 p-4">
//                   {winner.iguser.name}
//                 </td>
//                 <td className="border border-gray-300 p-4">{winner.votes}</td>
//                 <td className="border border-gray-300 p-4">
//                   {winner.iguser.followers_count}
//                 </td>
//                 <td className="border border-gray-300 p-4 flex items-center justify-center">
//                   <img
//                     src={winner.iguser.profile_picture_url}
//                     alt="/"
//                     className="w-8 h-8 rounded-full"
//                   />
//                 </td>
//               </tr>
//             ))}
//         </tbody>
//       </table>
//       <div className="flex justify-between mt-6">
//         <button
//           onClick={handlePrevPage}
//           className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
//           disabled={currentPage === 1}
//         >
//           Previous
//         </button>
//         <span className="self-center">
//           Page {currentPage} of {totalPages}
//         </span>
//         <button
//           onClick={handleNextPage}
//           className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
//           disabled={currentPage === totalPages}
//         >
//           Next
//         </button>
//       </div>

//       {selectedWinner && (
//         <WinnerOverlay
//           winner={selectedWinner}
//           onClose={() => setSelectedWinner(null)}
//         />
//       )}
//     </div>
//   );
// };

// export default Winnings;

// // Winner Overlay component remains the same
// const WinnerOverlay = ({ winner, onClose }) => {
//   return (
//     <div className="fixed inset-0 w-[50vw] bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
//       <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
//         <h2 className="text-2xl font-bold mb-4">Winner Details</h2>
//         <img
//           src={winner.iguser.profile_picture_url}
//           alt={winner.iguser.name}
//           className="w-32 h-32 rounded-full border-4 border-blue-500 shadow-lg mb-4"
//         />
//         <h3 className="text-xl font-semibold">{winner.iguser.name}</h3>
//         <strong className="text-gray-600 font-normal">
//           Biography: {winner.iguser.biography}
//         </strong>
//         <p className="text-lg my-4">Amount Won: {winner.winnings}</p>
//         <p className="text-lg mt-4 text-center text-blue-600">
//           Congratulations! 🎉
//         </p>
//         <button
//           onClick={onClose}
//           className="mt-4 w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//         >
//           Close
//         </button>
//       </div>
//     </div>
//   );
// };
