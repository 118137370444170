import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../context/DataContext";
import axios from "axios";
import LoadingScreen from "../utilities/LoadingScreen";
import Winnings from "./Winnings";
import { FaSadTear } from "react-icons/fa";

const WinnersList = () => {
  const { eventData, location, loading } = useContext(DataContext);

  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAgeCategory, setSelectedAgeCategory] = useState("");
  const [selectedGenderCategory, setSelectedGenderCategory] = useState("");
  const [selectedFollowerCountCategory, setSelectedFollowerCountCategory] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedPincode, setSelectedPincode] = useState("");
  const [categories, setCategories] = useState([]);
  const [ageCategories, setAgeCategories] = useState([]);
  const [genderCategories, setGenderCategories] = useState([]);
  const [followerCountCategories, setFollowerCountCategories] = useState([]);
  const [winners, setWinners] = useState(null);
  const [error, setError] = useState(null);

  const cities = location?.city || [];
  const states = location?.state || [];
  const pincodes = location?.pin_code || [];
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    if (selectedEvent) {
      const selectedEventData = eventData.find((event) => event.eventID === parseInt(selectedEvent, 10));
      setCategories(selectedEventData?.additionalData?.generalCategory || []);
      setAgeCategories(selectedEventData?.additionalData?.ageCategory || []);
      setGenderCategories(selectedEventData?.additionalData?.genderCategory || []);
      setFollowerCountCategories(selectedEventData?.additionalData?.followersCategory || []);
    } else {
      setCategories([]);
      setAgeCategories([]);
      setGenderCategories([]);
      setFollowerCountCategories([]);
    }
  }, [selectedEvent, eventData]);

  const handleSubmit = async () => {
    setError(null); // Reset error state
    setWinners(null); // Reset winners state
    let url = `${apiDomain}/api/v2/getfilteredwinners`;
    let queryParams = [];

    if (selectedEvent.trim()) queryParams.push(`event_id=${selectedEvent}`);
    if (selectedCategory.trim()) queryParams.push(`categories=${selectedCategory}`);
    if (selectedAgeCategory.trim()) queryParams.push(`agecategories=${selectedAgeCategory}`);
    if (selectedGenderCategory.trim()) queryParams.push(`gendercategories=${selectedGenderCategory}`);
    if (selectedFollowerCountCategory.trim()) queryParams.push(`followercountcategories=${selectedFollowerCountCategory}`);
    if (selectedState.trim()) queryParams.push(`state=${selectedState}`);
    if (selectedPincode.trim()) queryParams.push(`pincode=${selectedPincode}`);
    if (selectedCity.trim()) queryParams.push(`city=${selectedCity}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    try {
      const resp = await axios.get(url);
      if (resp.data.length === 0) {
        setError("No winners found for the selected filters. 😢");
      } else {
        setWinners(resp.data);
      }
    } catch (err) {
      console.error("Error fetching filtered winners:", err);
      setError("Something went wrong while fetching the winners. Please try again. 😢");
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="lg:ml-[18vw] lg:mr-[1vw] p-4">
      <h2 className="text-3xl font-bold text-center mb-6">Filter Winners</h2>

      {/* Horizontal Scrollable Filter Section */}
      <div className="flex gap-4 overflow-x-auto scrollbar-hidden py-4 px-2 bg-gray-100 rounded-lg shadow-lg">
        <div className="min-w-[200px]">
          <label className="block text-gray-700 font-semibold">Event</label>
          <select
            className="block w-full p-2 mt-1 border border-gray-300 rounded"
            value={selectedEvent}
            onChange={(e) => setSelectedEvent(e.target.value)}
          >
            <option value="">Select Event</option>
            {eventData?.map((event) => (
              <option key={event.eventID} value={event.eventID}>
                {event.eventName}
              </option>
            ))}
          </select>
        </div>

        <div className="min-w-[200px]">
          <label className="block text-gray-700 font-semibold">Category</label>
          <select
            className="block w-full p-2 mt-1 border border-gray-300 rounded"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        <div className="min-w-[200px]">
          <label className="block text-gray-700 font-semibold">Age Category</label>
          <select
            className="block w-full p-2 mt-1 border border-gray-300 rounded"
            value={selectedAgeCategory}
            onChange={(e) => setSelectedAgeCategory(e.target.value)}
          >
            <option value="">Select Age Category</option>
            {ageCategories.map((age, index) => (
              <option key={index} value={age}>
                {age}
              </option>
            ))}
          </select>
        </div>

        <div className="min-w-[200px]">
          <label className="block text-gray-700 font-semibold">Gender Category</label>
          <select
            className="block w-full p-2 mt-1 border border-gray-300 rounded"
            value={selectedGenderCategory}
            onChange={(e) => setSelectedGenderCategory(e.target.value)}
          >
            <option value="">Select Gender Category</option>
            {genderCategories.map((gender, index) => (
              <option key={index} value={gender}>
                {gender}
              </option>
            ))}
          </select>
        </div>

        <div className="min-w-[200px]">
          <label className="block text-gray-700 font-semibold">City</label>
          <select
            className="block w-full p-2 mt-1 border border-gray-300 rounded"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value="">Select City</option>
            {cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>

        <div className="min-w-[200px]">
          <label className="block text-gray-700 font-semibold">State</label>
          <select
            className="block w-full p-2 mt-1 border border-gray-300 rounded"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
          >
            <option value="">Select State</option>
            {states.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>

        <div className="min-w-[200px]">
          <label className="block text-gray-700 font-semibold">Pincode</label>
          <select
            className="block w-full p-2 mt-1 border border-gray-300 rounded"
            value={selectedPincode}
            onChange={(e) => setSelectedPincode(e.target.value)}
          >
            <option value="">Select Pincode</option>
            {pincodes.map((pincode, index) => (
              <option key={index} value={pincode}>
                {pincode}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Go Button */}
      <div className="mt-6 flex justify-center">
        <button
          type="button"
          onClick={handleSubmit}
          className="py-2 px-8 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600"
        >
          Go
        </button>
      </div>

      {/* Winners Section or Error Message */}
      {error && (
        <div className="mt-10 flex flex-col items-center justify-center text-center text-gray-700">
          <FaSadTear className="text-6xl text-red-500 mb-4" />
          <p className="text-lg font-semibold">{error}</p>
        </div>
      )}

      {winners && <Winnings data={winners} />}
    </div>
  );
};

export default WinnersList;
