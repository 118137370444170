import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlus, FaTrashAlt, FaSpinner } from "react-icons/fa";
import { uploadFile } from "../../firebase/fileUpload";

// MediaData Class for Default Media Object Structure
class MediaData {
  constructor(label = "", url = "", description = "", duration = 0, mandatory = false, defaultValue = false) {
    this.label = label;
    this.url = url;
    this.description = description;
    this.duration = duration;
    this.mandatory = mandatory;
    this.default = defaultValue;
  }
}

const IndependentMediaUpload = ({ eventId }) => {
  const [imageData, setImageData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [musicData, setMusicData] = useState([]);
  const [videoOutputDuration, setVideoOutputDuration] = useState(0);
  const [musicDuration, setMusicDuration] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // Fetch existing media data on component mount
  useEffect(() => {
    const fetchMediaData = async () => {
      try {
        const response = await axios.get(`${apiDomain}/api/v2/getlongevent`, {
          params: { event_id: eventId },
        });

        if (response.status === 200) {
          const data = response.data;
          setImageData(JSON.parse(data.image_data) || []);
          setVideoData(JSON.parse(data.video_data) || []);
          setMusicData(JSON.parse(data.music_data) || []);
          setVideoOutputDuration(data.video_output_duration_in_secs || 0);
          setMusicDuration(data.music_duration_in_secs || 0);
        }
      } catch (error) {
        console.error("Error fetching media data:", error);
      }
    };

    if (eventId) fetchMediaData();
  }, [eventId]);

  // Handle file uploads
  const handleFileUpload = async (file, type, index) => {
    const url = await uploadFile(file);
    const updateData = (data, setData) => {
      const updated = [...data];
      updated[index] = { ...updated[index], url };
      setData(updated);
    };
    if (type === "image") updateData(imageData, setImageData);
    if (type === "video") updateData(videoData, setVideoData);
    if (type === "music") updateData(musicData, setMusicData);
  };

  // Handle removing a media item
  const handleRemove = (index, type) => {
    const updateData = (data, setData) => {
      const updated = data.filter((_, i) => i !== index);
      setData(updated);
    };
    if (type === "image") updateData(imageData, setImageData);
    if (type === "video") updateData(videoData, setVideoData);
    if (type === "music") updateData(musicData, setMusicData);
  };

  // Handle media field changes (label, description, duration, etc.)
  const handleMediaChange = (index, value, type, field) => {
    const updateData = (data, setData) => {
      const updated = [...data];
      updated[index][field] = value;
      setData(updated);
    };
    if (type === "image") updateData(imageData, setImageData);
    if (type === "video") updateData(videoData, setVideoData);
    if (type === "music") updateData(musicData, setMusicData);
  };

  // Save media data to server
  const handleSaveMedia = async () => {
    setIsUploading(true);
    setSaveSuccess(false); // Reset success status

    const formData = {
      event_id: eventId,
      number_of_images: imageData.length,
      number_of_videos: videoData.length,
      number_of_music: musicData.length,
      image_data: JSON.stringify(imageData),
      video_data: JSON.stringify(videoData),
      music_data: JSON.stringify(musicData),
      video_output_duration_in_secs: parseInt(videoOutputDuration, 10) || 0,
      music_duration_in_secs: parseInt(musicDuration, 10) || 0,
    };

    try {
      const response = await axios.post(
        `${apiDomain}/api/v2/longevent/createorupdate`,
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 3000); // Hide success message
      }
    } catch (error) {
      console.error("Error saving media data:", error);
    } finally {
      setIsUploading(false);
    }
  };

  // Render individual media item
  const renderMediaFrame = (mediaArray, type) =>
    mediaArray.map((media, index) => (
      <div
        key={`${type}-${index}`}
        className="p-4 border rounded-lg shadow-md bg-white relative flex flex-col gap-4"
      >
        {/* Remove Button */}
        <button
          type="button"
          className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition"
          onClick={() => handleRemove(index, type)}
        >
          <FaTrashAlt />
        </button>

        {/* File Upload Section */}
        <div
          className="flex justify-center items-center w-full h-40 border-dashed border-2 rounded-md cursor-pointer hover:border-blue-500 transition"
          onClick={() => document.getElementById(`${type}-file-input-${index}`).click()}
        >
          {media.url ? (
            type === "image" ? (
              <img src={media.url} alt="preview" className="object-cover w-full h-full rounded-md" />
            ) : type === "video" ? (
              <video src={media.url} controls className="object-cover w-full h-full rounded-md" />
            ) : (
              <audio src={media.url} controls className="w-full" />
            )
          ) : (
            <div className="text-gray-400 flex flex-col items-center">
              <FaPlus className="text-xl" />
              <span className="text-sm">Add {type}</span>
            </div>
          )}
          <input
            id={`${type}-file-input-${index}`}
            type="file"
            accept={type === "image" ? "image/*" : type === "video" ? "video/*" : "audio/*"}
            className="hidden"
            onChange={(e) => handleFileUpload(e.target.files[0], type, index)}
          />
        </div>

        {/* Input Fields */}
        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-1">Label</label>
          <input
            type="text"
            value={media.label || ""}
            onChange={(e) => handleMediaChange(index, e.target.value, type, "label")}
            placeholder="Label"
            className="border rounded px-3 py-2 w-full"
          />
        </div>

        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-1">Description</label>
          <textarea
            value={media.description || ""}
            onChange={(e) => handleMediaChange(index, e.target.value, type, "description")}
            placeholder="Description"
            className="border rounded px-3 py-2 w-full resize-none"
          />
        </div>

        <div>
          <label className="block text-sm font-semibold text-gray-700 mb-1">Duration (secs)</label>
          <input
            type="number"
            value={media.duration || 0}
            onChange={(e) => handleMediaChange(index, e.target.value, type, "duration")}
            placeholder="Duration (secs)"
            className="border rounded px-3 py-2 w-full"
          />
        </div>

        <div className="flex items-center gap-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={media.mandatory || false}
              onChange={(e) => handleMediaChange(index, e.target.checked, type, "mandatory")}
              className="mr-2"
            />
            <span className="text-gray-700">Mandatory</span>
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={media.default || false}
              onChange={(e) => handleMediaChange(index, e.target.checked, type, "default")}
              className="mr-2"
            />
            <span className="text-gray-700">Default</span>
          </label>
        </div>
      </div>
    ));

  return (
    <div className="p-6 border rounded-lg shadow-lg bg-gray-50">
      <h2 className="text-2xl font-semibold text-blue-600 mb-6">Manage Media</h2>

      {/* Image Section */}
      <div className="mb-8">
        <h3 className="text-lg font-bold mb-4">Images</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {renderMediaFrame(imageData, "image")}
        </div>
        <button
          type="button"
          onClick={() => setImageData([...imageData, new MediaData()])}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          Add Image
        </button>
      </div>

      {/* Video Section */}
      <div className="mb-8">
        <h3 className="text-lg font-bold mb-4">Videos</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {renderMediaFrame(videoData, "video")}
        </div>
        <button
          type="button"
          onClick={() => setVideoData([...videoData, new MediaData()])}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          Add Video
        </button>
      </div>

      {/* Music Section */}
      <div className="mb-8">
        <h3 className="text-lg font-bold mb-4">Music</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {renderMediaFrame(musicData, "music")}
        </div>
        <button
          type="button"
          onClick={() => setMusicData([...musicData, new MediaData()])}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          Add Music
        </button>
      </div>

      {/* Duration Inputs */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Video Output Duration (secs)</label>
          <input
            type="number"
            value={videoOutputDuration}
            onChange={(e) => setVideoOutputDuration(e.target.value)}
            className="border rounded px-3 py-2 w-full"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Music Duration (secs)</label>
          <input
            type="number"
            value={musicDuration}
            onChange={(e) => setMusicDuration(e.target.value)}
            className="border rounded px-3 py-2 w-full"
          />
        </div>
      </div>

      {/* Save Button */}
      <button
        type="button"
        onClick={handleSaveMedia}
        className="mt-8 bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 transition"
      >
        {isUploading ? <FaSpinner className="animate-spin" /> : "Save Media"}
      </button>

      {/* Success Message */}
      {saveSuccess && <p className="mt-4 text-green-600">Media saved successfully!</p>}
    </div>
  );
};

export default IndependentMediaUpload;




