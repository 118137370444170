import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { FaAngleLeft, FaArrowLeft, FaSpinner } from "react-icons/fa";
import collapseIcon from "./assets/reduce.png";
import expandIcon from "./assets/expand-arrows.png";
import { uploadFile } from "../../firebase/fileUpload";
import EventAdditionalData from "../../components/eventAdditionalData/EventAdditionalData";
import SponsorDetailForm from "./SponsorDetailForm";
import IndependentMediaUpload from "./IndependentMediaUpload";

const EventType = Object.freeze({
  Short: "short",
  Long: "long",
});

const formatDateForInput = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const day = ("0" + d.getDate()).slice(-2);
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

const EventForm = () => {
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const location = useLocation();
  const navigate = useNavigate();

  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({
    eventName: "",
    description: "",
    startDate: "",
    endDate: "",
    votingDuration: 0,
    eventType: "",
    termsConditions: "",
    instructions: "",
    sponsorData: [],
    templateData: null,
    additionalData: {
      isPublish: false, // Default value for isPublish
    },
  });
  const [templateData, setTemplateData] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(true);
  const [eventId, setEventId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (location.state?.event) {
      setIsEditMode(true);
      const event = location.state.event;
      setFormData(event);
      setTemplateData(event.templateData);
      setImagePreview(event.templateData);
      setEventId(event.eventID);
      // Update to correctly reference formData.additionalData
      setFormData((prev) => ({
        ...prev,
        sponsorData: event.sponsorData || [],
        additionalData: {
          ...prev.additionalData,
          isPublish: event.additionalData?.isPublish || false, // Ensure isPublish exists
        },
      }));

      if (event.templateData) {
        setTemplateData(event.templateData);
        setImagePreview(event.templateData);
      }
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "votingDuration" ? parseInt(value, 10) || 0 : value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      additionalData: {
        ...prevData.additionalData,
        isPublish: checked, // Update isPublish dynamically
      },
    }));
  };

  const handleTextareaResize = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const validateFormData = () => {
    if (!formData.eventName.trim()) {
      alert("Event name is required.");
      return false;
    }
    if (!formData.startDate || !formData.endDate) {
      alert("Start and end dates are required.");
      return false;
    }
    if (!formData.eventType) {
      alert("Event type is required.");
      return false;
    }
    return true;
  };

  const handleTemplateUpload = async (file) => {
    try {
      if (!file) throw new Error("No file provided");
      const uploadedUrl = await uploadFile(file);
      setTemplateData(uploadedUrl);
      setImagePreview(URL.createObjectURL(file));
      return uploadedUrl;
    } catch (error) {
      console.error("Template upload failed:", error);
      alert("Error uploading template file. Please try again.");
      return null;
    }
  };

  const handleCreateSubmit = () => {
    if (!validateFormData()) return;
    setModalOpen(true);
  };

  const confirmCreateEvent = async () => {
    setModalOpen(false);
    setIsSubmitting(true);
    const accessToken = Cookies.get("fbauthToken");
    const payload = { ...formData, templateData };
  
    try {
      const response = isEditMode
        ? await axios.put(
            `${apiDomain}/api/v2/updateevent?id=${eventId}&access_token=${accessToken}`,
            payload,
            { headers: { "Content-Type": "application/json" } }
          )
        : await axios.post(
            `${apiDomain}/api/v2/createevent?access_token=${accessToken}`,
            payload,
            { headers: { "Content-Type": "application/json" } }
          );
  
      if (response.status === 200) {
        alert(
          isEditMode
            ? "Event updated successfully! All changes have been saved."
            : "Event created successfully! Please proceed to upload media data."
        );
        navigate("/events"); // Redirecting to events list or dashboard after success
      }
    } catch (error) {
      console.error("Error saving event:", error);
      alert("There was an error saving the event. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  const onDeleteSponsor = (index) => {
    setFormData((prev) => ({
      ...prev,
      sponsorData: prev.sponsorData.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className="flex flex-col items-start m-2 lg:ml-[20vw] lg:mr-8">
      <div className="flex items-center mb-4">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center text-gray-700 rounded-full hover:text-gray-800 transition "
        >
          <FaAngleLeft size={28} />
        </button>
        <p className="font-semibold text-gray-700 text-2xl">
          {isEditMode ? "Update Event" : "Create Event"}
        </p>
      </div>

      <form className="w-full bg-white p-4 lg:p-6 rounded-lg shadow-md">
        <div className="w-full p-4 border rounded-md mb-4 bg-blue-50">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-blue-600">
              Event Details
            </h3>
            <button
              type="button"
              onClick={() => setIsEventDetailsOpen(!isEventDetailsOpen)}
              className="p-1"
            >
              <img
                src={isEventDetailsOpen ? collapseIcon : expandIcon}
                alt="Toggle"
                className="w-6 h-6"
              />
            </button>
          </div>
          {isEventDetailsOpen && (
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-6 mt-4 p-2">
              <div className="flex flex-col items-start space-y-2">
                <span className="starlabel font-medium text-lg">
                  Event Type <span className="text-red-500">*</span>
                </span>
                <span></span>
                <select
                  name="eventType"
                  value={formData.eventType}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      eventType: e.target.value,
                    }))
                  }
                  className="w-full h-12 border rounded px-4"
                >
                  <option value="">Select Event Type</option>
                  {Object.values(EventType).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col items-start space-y-2">
                <span className="starlabel font-medium text-lg">
                  Event Name <span className="text-red-500">*</span>
                </span>
                <input
                  type="text"
                  name="eventName"
                  value={formData.eventName}
                  onChange={handleChange}
                  placeholder="Enter Event Name"
                  className="w-full h-12 border rounded px-4 starlabel"
                />
              </div>
              <div className="flex flex-col items-start space-y-2">
                <span className="starlabel font-medium text-lg">
                  Event Description <span className="text-red-500">*</span>
                </span>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  onInput={handleTextareaResize}
                  placeholder="Enter Event Description"
                  className="w-full lg:min-h-40 border rounded px-4 py-2"
                ></textarea>
              </div>
              <div className="flex flex-col items-start space-y-2">
                <span className="starlabel font-medium text-lg">
                  Terms & Conditions <span className="text-red-500">*</span>
                </span>
                <textarea
                  name="termsConditions"
                  value={formData.termsConditions}
                  onChange={handleChange}
                  onInput={handleTextareaResize}
                  placeholder="Enter Terms & Conditions"
                  className="w-full lg:min-h-40 border rounded px-4 py-2"
                ></textarea>
              </div>
              <div className="flex flex-col items-start space-y-2">
                <span className="starlabel font-medium text-lg">
                  Start Date <span className="text-red-500">*</span>
                </span>
                <input
                  type="date"
                  name="startDate"
                  value={formatDateForInput(formData.startDate)}
                  onChange={handleChange}
                  className="w-full h-12 border rounded px-4 starlabel"
                />
              </div>
              <div className="flex flex-col items-start space-y-2">
                <span className="starlabel font-medium text-lg">
                  End Date <span className="text-red-500">*</span>
                </span>
                <input
                  type="date"
                  name="endDate"
                  value={formatDateForInput(formData.endDate)}
                  onChange={handleChange}
                  className="w-full h-12 border rounded px-4 starlabel"
                />
              </div>
            </div>
          )}
        </div>

        <EventAdditionalData
          additionalData={formData.additionalData}
          setAdditionalData={(data) =>
            setFormData((prev) => ({
              ...prev,
              additionalData: { ...prev.additionalData, ...data },
            }))
          }
          isEditMode={isEditMode}
        />
        <SponsorDetailForm
          savedCards={formData.sponsorData}
          onSponsorDataChange={(data) =>
            setFormData((prev) => ({
              ...prev,
              sponsorData: [...prev.sponsorData, data],
            }))
          }
          onDeleteSponsor={onDeleteSponsor}
        />

        <div className="w-full p-4 border rounded-md mt-8 bg-blue-50 shadow-md">
          <h3 className="text-xl lg:text-2xl font-semibold text-gray-700">
            Template Frame Image
          </h3>
          <input
            type="file"
            onChange={async (e) => {
              const file = e.target.files[0];
              if (file) {
                const uploadedUrl = await handleTemplateUpload(file);
                if (uploadedUrl) {
                  setFormData((prev) => ({
                    ...prev,
                    templateData: uploadedUrl,
                  }));
                }
              }
            }}
            className="w-fit mt-2 bg-white rounded-lg p-4 "
          />
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Template Preview"
              className="mt-4 w-60 h-60 lg:w-96 lg:h-96"
            />
          )}
        </div>

        {formData.eventType === EventType.Long && eventId && (
          <IndependentMediaUpload eventId={eventId} />
        )}

        {/* Existing event details form sections */}
        <div className="w-full mt-8">
          <label className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={formData.additionalData.isPublish}
              onChange={handleCheckboxChange}
              className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <span className="text-gray-700 font-medium">Publish Event</span>
          </label>
        </div>

        <button
          type="button"
          onClick={handleCreateSubmit}
          className="mt-8 bg-blue-600 text-white px-4 py-2 rounded-md flex items-center"
        >
          {isSubmitting && <FaSpinner className="mr-2 animate-spin" />}
          {isEditMode ? "Update Event" : "Create Event"}
        </button>
      </form>

      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
            <h3 className="text-xl font-bold text-center text-blue-600 mb-4">
              Confirm Event Details
            </h3>
            <p className="text-gray-700 text-center mb-6">
              Are you sure you want to save this event? Please review all
              details before confirming.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                className="px-6 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                onClick={confirmCreateEvent}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventForm;
