import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { DataContext } from "../context/DataContext";
import { uploadFile } from "../../firebase/fileUpload";
import { FaPlus, FaTrashAlt, FaToggleOn, FaToggleOff } from "react-icons/fa";
import Select from "react-select";

const VideoPackage = () => {
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null); // Selected event for filtering
  const [isEventPackageView, setIsEventPackageView] = useState(true); // Toggle for package view
  const [modalMessage, setModalMessage] = useState(""); // Modal message
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // Get event data from DataContext
  const { eventData } = useContext(DataContext);

  // Video package form state
  const [packageForm, setPackageForm] = useState({
    title: "",
    description: "",
    price: "",
    category: "",
    thumbnail: "",
    is_event_package: false,
    event_id: null,
  });

  // Videos state with file and URL for Firebase upload
  const [videos, setVideos] = useState([
    { title: "", file: null, videoUrl: "" },
  ]);

  // Fetch existing video packages on component mount
  useEffect(() => {
    fetchVideoPackages();
  }, []);

  // Fetch video packages
  const fetchVideoPackages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiDomain}/api/v2/video-packages`);
      setPackages(response.data || []);
    } catch (err) {
      setError("Failed to fetch video packages.");
    } finally {
      setLoading(false);
    }
  };

  // Handle package form changes
  const handlePackageChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setPackageForm({ ...packageForm, [name]: val });
  };

  // Handle event selection from dropdown
  const handleEventChange = (e) => {
    const selectedEventId = parseInt(e.target.value, 10);
    setPackageForm({ ...packageForm, event_id: selectedEventId });
  };

  // Handle video form changes
  const handleVideoChange = (index, field, value) => {
    const updatedVideos = [...videos];
    updatedVideos[index][field] = value;
    setVideos(updatedVideos);
  };

  // Add a new video field
  const addVideoField = () => {
    setVideos([...videos, { title: "", file: null, videoUrl: "" }]);
  };

  // Remove a video field
  const removeVideoField = (index) => {
    const updatedVideos = videos.filter((_, i) => i !== index);
    setVideos(updatedVideos);
  };

  // Handle video file upload
  const handleFileUpload = async (index, file) => {
    if (file) {
      const url = await uploadFile(file); // Upload to Firebase and get the URL
      const updatedVideos = [...videos];
      updatedVideos[index].videoUrl = url;
      setVideos(updatedVideos);
    }
  };

  // Create a new video package with Firebase URLs
  const createVideoPackage = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      for (let i = 0; i < videos.length; i++) {
        if (videos[i].file && !videos[i].videoUrl) {
          await handleFileUpload(i, videos[i].file);
        }
      }

      const packageData = {
        video_package: {
          title: packageForm.title,
          description: packageForm.description,
          price: parseFloat(packageForm.price),
          category: packageForm.category,
          thumbnail: packageForm.thumbnail,
          is_event_package: packageForm.is_event_package,
          event_id: packageForm.is_event_package ? packageForm.event_id : null,
        },
        videos: videos.map((video) => ({
          title: video.title,
          video_url: video.videoUrl,
        })),
      };

      await axios.post(`${apiDomain}/api/v2/video-package`, packageData);

      fetchVideoPackages();
      resetForm();
    } catch (err) {
      console.error(
        "Error creating video package:",
        err.response ? err.response.data : err.message
      );
      setError("Failed to create video package.");
    } finally {
      setLoading(false);
    }
  };

  // Reset form after successful submission
  const resetForm = () => {
    setPackageForm({
      title: "",
      description: "",
      price: "",
      category: "",
      thumbnail: "",
      is_event_package: false,
      event_id: null,
    });
    setVideos([{ title: "", file: null, videoUrl: "" }]);
  };

  // Delete individual video
  const deleteVideo = async (videoId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this video?"
    );
    if (confirmed) {
      setLoading(true);
      try {
        await axios.delete(
          `${apiDomain}/api/v2/video-package/video/${videoId}`
        );
        fetchVideoPackages(); // Refresh the list after deletion
      } catch (err) {
        setError("Failed to delete video.");
      } finally {
        setLoading(false);
      }
    }
  };

  // Delete entire package
  const deletePackage = async (packageId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this entire package?"
    );
    if (confirmed) {
      setLoading(true);
      try {
        await axios.delete(`${apiDomain}/api/v2/video-package/${packageId}`);
        fetchVideoPackages(); // Refresh the list after deletion
      } catch (err) {
        setError("Failed to delete package.");
      } finally {
        setLoading(false);
      }
    }
  };
  // Filter packages by selected event
  const handleEventFilter = (selectedOption) => {
    setSelectedEvent(selectedOption);
    if (selectedOption) {
      const filtered = packages.filter(
        (pkg) => pkg.is_event_package && pkg.event_id === selectedOption.value
      );
      setFilteredPackages(filtered);

      if (filtered.length === 0) {
        // Automatically switch to general packages if no event packages exist
        // Show modal if no event packages are found
        showModal("No video package created for this event yet.");
        setIsEventPackageView(false);
      } else {
        setIsEventPackageView(true);
      }
    } else {
      // Reset to all packages if no event is selected
      setFilteredPackages(packages);
      setIsEventPackageView(true);
    }
  };

  const showModal = (message) => {
    setModalMessage(message);
    setIsModalVisible(true);
    setTimeout(() => {
      setIsModalVisible(false);
    }, 3000);
  };

  const eventPackages = packages.filter((pkg) => pkg.is_event_package);
  const generalPackages = packages.filter((pkg) => !pkg.is_event_package);

  return (
    <div className=" min-h-screen text-white lg:ml-[18vw] py-1 px-4">
      {/* <h1 className="text-4xl font-bold text-center mb-6">Manage Video Packages</h1> */}

      {error && <p className="text-red-500 text-center">{error}</p>}

      {/* Video Package Creation Section */}
      <form
        onSubmit={createVideoPackage}
        className="bg-gray-800 p-6 rounded-md shadow-md mx-auto mb-8"
      >
        <h2 className="text-2xl font-bold mb-4">Create Video Package</h2>
        <div className="grid gap-4">
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={packageForm.title}
            onChange={handlePackageChange}
            className="w-full p-2 bg-gray-700 rounded text-white"
            required
          />
          <textarea
            name="description"
            placeholder="Description"
            value={packageForm.description}
            onChange={handlePackageChange}
            className="w-full p-2 bg-gray-700 rounded text-white"
          ></textarea>
          <input
            type="number"
            name="price"
            placeholder="Price"
            value={packageForm.price}
            onChange={handlePackageChange}
            className="w-full p-2 bg-gray-700 rounded text-white"
          />
          <input
            type="text"
            name="category"
            placeholder="Category"
            value={packageForm.category}
            onChange={handlePackageChange}
            className="w-full p-2 bg-gray-700 rounded text-white"
          />
          <input
            type="text"
            name="thumbnail"
            placeholder="Thumbnail URL"
            value={packageForm.thumbnail}
            onChange={handlePackageChange}
            className="w-full p-2 bg-gray-700 rounded text-white"
          />
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="is_event_package"
              checked={packageForm.is_event_package}
              onChange={handlePackageChange}
              className="mr-2"
            />
            Event Package
          </label>
          {packageForm.is_event_package && (
            <select
              name="event_id"
              value={packageForm.event_id || ""}
              onChange={handleEventChange}
              className="w-full p-2 bg-gray-700 rounded text-white"
              required
            >
              <option value="">Select Event</option>
              {eventData.map((event) => (
                <option key={event.eventID} value={event.eventID}>
                  {event.eventName}
                </option>
              ))}
            </select>
          )}
          <h3 className="text-xl font-semibold mt-4">Videos</h3>
          {videos.map((video, index) => (
            <div key={index} className="flex gap-2 items-center mb-2">
              <input
                type="text"
                name="title"
                placeholder="Video Title"
                value={video.title}
                onChange={(e) =>
                  handleVideoChange(index, "title", e.target.value)
                }
                className="w-1/2 p-2 bg-gray-700 rounded text-white"
                required
              />
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  handleVideoChange(index, "file", file);
                  handleFileUpload(index, file);
                }}
                className="w-1/2 p-2 bg-gray-700 rounded text-white"
              />
              {video.videoUrl && (
                <video
                  src={video.videoUrl}
                  className="w-24 h-16 rounded-md"
                  controls
                />
              )}
              <button
                type="button"
                onClick={() => removeVideoField(index)}
                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white"
              >
                <FaTrashAlt />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addVideoField}
            className="mt-2 bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded text-white"
          >
            <FaPlus className="inline mr-2" /> Add Video
          </button>
        </div>
        <button
          type="submit"
          disabled={loading}
          className="mt-4 w-full bg-green-500 hover:bg-green-600 px-4 py-2 rounded text-white"
        >
          {loading ? "Submitting..." : "Create Package"}
        </button>
      </form>

        {/* Modal */}
        {isModalVisible && (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white text-black p-6 rounded-md shadow-md">
            <p>{modalMessage}</p>
          </div>
        </div>
      )}

      {/* Video Package Filter Section */}
      <div className="bg-gray-800 p-4 rounded-md mb-6">
        <h2 className="text-xl font-semibold mb-4">Filter by Event</h2>
        <div className="flex items-center justify-between">
          <div>
            <Select
              options={eventData.map((event) => ({
                value: event.eventID,
                label: event.eventName,
              }))}
              value={selectedEvent}
              onChange={handleEventFilter}
              isClearable
              placeholder="Select an event to filter packages"
              className="text-black"
            />
          </div>
          <div className="flex justify-start mb-6">
            <button
              className={`px-6 py-2 rounded-l-lg ${
                isEventPackageView ? "bg-blue-600" : "bg-gray-700"
              }`}
              onClick={() => setIsEventPackageView(true)}
            >
              <FaToggleOn className="inline mr-2" /> Event Packages
            </button>
            <button
              className={`px-6 py-2 rounded-r-lg ${
                !isEventPackageView ? "bg-blue-600" : "bg-gray-700"
              }`}
              onClick={() => setIsEventPackageView(false)}
            >
              <FaToggleOff className="inline mr-2" /> General Packages
            </button>
          </div>
        </div>
      </div>

      <div className="mt-8">
        {loading && <p className="text-center ">Loading packages...</p>}
        {!loading &&
          isEventPackageView &&
          eventPackages.length === 0 &&
          <></>
        }
        {!loading && !isEventPackageView && generalPackages.length === 0 && (
          <p className="text-center">No general video packages found.</p>
        )}
        <div className="grid gap-6 lg:grid-cols-2">
          {(isEventPackageView ? eventPackages : generalPackages).map((pkg) => (
            <div key={pkg.id} className="bg-gray-800 p-6 rounded-md shadow-md">
              <h3 className="text-2xl font-bold mb-2">{pkg.title}</h3>
              <p className="text-gray-300 mb-2">{pkg.description}</p>
              <p>
                <strong>Price:</strong> ₹{pkg.price}
              </p>
              <p>
                <strong>Category:</strong> {pkg.category}
              </p>
              {pkg.is_event_package && (
                <p>
                  <strong>Event ID:</strong> {pkg.event_id}
                </p>
              )}
              <div className="mt-4 flex justify-between">
                <button
                  onClick={() => deletePackage(pkg.id)}
                  className="bg-red-600 hover:bg-red-700 px-4 py-2 rounded text-white"
                >
                  Delete Package
                </button>
              </div>

              <div className="mt-4">
                <p className="font-semibold">Videos:</p>
                <ul className="space-y-2 mt-2">
                  {(pkg.videos || []).map((video) => (
                    <li key={video.id} className="flex items-center">
                      <video
                        src={video.video_url}
                        controls
                        className="w-40 h-40 mr-4 rounded-md"
                      />
                      <span className="text-gray-300">{video.title}</span>
                      <button
                        onClick={() => deleteVideo(video.id)}
                        className="ml-auto bg-red-500 hover:bg-red-600 px-2 py-1 rounded text-white"
                      >
                        <FaTrashAlt />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoPackage;

// import React, { useState, useEffect, useContext } from 'react';
// import axios from 'axios';
// import { DataContext } from '../context/DataContext';
// import { uploadFile } from '../../firebase/fileUpload';
// import { FaPlus, FaTrashAlt } from 'react-icons/fa';

// const VideoPackage = () => {
//   const [packages, setPackages] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const apiDomain = process.env.REACT_APP_API_DOMAIN;

//   // Get event data from DataContext
//   const { eventData } = useContext(DataContext);

//   // Video package form state
//   const [packageForm, setPackageForm] = useState({
//     title: '',
//     description: '',
//     price: '',
//     category: '',
//     thumbnail: '',
//     is_event_package: false,
//     event_id: null,
//     id: '',
//   });

//   // Videos state with file and URL for Firebase upload
//   const [videos, setVideos] = useState([{ title: '', file: null, videoUrl: '' }]);

//   // Fetch existing video packages on component mount
//   useEffect(() => {
//     fetchVideoPackages();
//   }, []);

//   // Fetch video packages
//   const fetchVideoPackages = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get(`${apiDomain}/api/v2/video-packages`);
//       setPackages(response.data || []);
//     } catch (err) {
//       setError('Failed to fetch video packages.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Handle package form changes
//   const handlePackageChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     const val = type === 'checkbox' ? checked : value;
//     setPackageForm({ ...packageForm, [name]: val });
//   };

//   // Handle event selection from dropdown
//   const handleEventChange = (e) => {
//     const selectedEventId = parseInt(e.target.value, 10);
//     setPackageForm({ ...packageForm, event_id: selectedEventId });
//   };

//   // Handle video form changes
//   const handleVideoChange = (index, field, value) => {
//     const updatedVideos = [...videos];
//     updatedVideos[index][field] = value;
//     setVideos(updatedVideos);
//   };

//   // Add a new video field
//   const addVideoField = () => {
//     setVideos([...videos, { title: '', file: null, videoUrl: '' }]);
//   };

//   // Remove a video field
//   const removeVideoField = (index) => {
//     const updatedVideos = videos.filter((_, i) => i !== index);
//     setVideos(updatedVideos);
//   };

//   // Handle video file upload
//   const handleFileUpload = async (index, file) => {
//     if (file) {
//       const url = await uploadFile(file); // Upload to Firebase and get the URL
//       const updatedVideos = [...videos];
//       updatedVideos[index].videoUrl = url;
//       setVideos(updatedVideos);
//     }
//   };

//   // Create a new video package with Firebase URLs
//   const createVideoPackage = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       for (let i = 0; i < videos.length; i++) {
//         if (videos[i].file && !videos[i].videoUrl) {
//           await handleFileUpload(i, videos[i].file);
//         }
//       }

//       const packageData = {
//         video_package: {
//           title: packageForm.title,
//           description: packageForm.description,
//           price: parseFloat(packageForm.price),
//           category: packageForm.category,
//           thumbnail: packageForm.thumbnail,
//           is_event_package: packageForm.is_event_package,
//           event_id: packageForm.is_event_package ? packageForm.event_id : null,
//         },
//         videos: videos.map((video) => ({
//           title: video.title,
//           video_url: video.videoUrl,
//         })),
//       };

//       await axios.post(`${apiDomain}/api/v2/video-package`, packageData);

//       fetchVideoPackages();
//       resetForm();
//     } catch (err) {
//       console.error('Error creating video package:', err.response ? err.response.data : err.message);
//       setError('Failed to create video package.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Reset form after successful submission
//   const resetForm = () => {
//     setPackageForm({
//       title: '',
//       description: '',
//       price: '',
//       category: '',
//       thumbnail: '',
//       is_event_package: false,
//       event_id: null,
//       id: '',
//     });
//     setVideos([{ title: '', file: null, videoUrl: '' }]);
//   };

//   // Delete individual video
//   const deleteVideo = async (videoId) => {
//     const confirmed = window.confirm('Are you sure you want to delete this video?');
//     if (confirmed) {
//       setLoading(true);
//       try {
//         await axios.delete(`${apiDomain}/api/v2/video-package/video/${videoId}`);
//         fetchVideoPackages(); // Refresh the list after deletion
//       } catch (err) {
//         setError('Failed to delete video.');
//       } finally {
//         setLoading(false);
//       }
//     }
//   };

//   // Delete entire package
//   const deletePackage = async (packageId) => {
//     const confirmed = window.confirm('Are you sure you want to delete this entire package?');
//     if (confirmed) {
//       setLoading(true);
//       try {
//         await axios.delete(`${apiDomain}/api/v2/video-package/${packageId}`);
//         fetchVideoPackages(); // Refresh the list after deletion
//       } catch (err) {
//         setError('Failed to delete package.');
//       } finally {
//         setLoading(false);
//       }
//     }
//   };

//   return (
//     <div className="bg-gray-900 min-h-screen text-white lg:ml-[18vw] py-12">
//       <h1 className="text-4xl font-bold text-center mb-6">Manage Video Packages</h1>

//       {error && <p className="text-red-500 text-center">{error}</p>}

//       <form onSubmit={createVideoPackage} className="bg-gray-800 p-6 rounded-md shadow-md max-w-2xl mx-auto">
//         {/* Form fields for package creation */}
//         <div className="grid gap-4">
//           <input
//             type="text"
//             name="title"
//             placeholder="Title"
//             value={packageForm.title}
//             onChange={handlePackageChange}
//             className="w-full p-2 bg-gray-700 rounded text-white"
//             required
//           />
//           <textarea
//             name="description"
//             placeholder="Description"
//             value={packageForm.description}
//             onChange={handlePackageChange}
//             className="w-full p-2 bg-gray-700 rounded text-white"
//           ></textarea>
//           <input
//             type="number"
//             name="price"
//             placeholder="Price"
//             value={packageForm.price}
//             onChange={handlePackageChange}
//             className="w-full p-2 bg-gray-700 rounded text-white"
//           />
//           <input
//             type="text"
//             name="category"
//             placeholder="Category"
//             value={packageForm.category}
//             onChange={handlePackageChange}
//             className="w-full p-2 bg-gray-700 rounded text-white"
//           />

//           <input
//             type="text"
//             name="thumbnail"
//             placeholder="Thumbnail URL"
//             value={packageForm.thumbnail}
//             onChange={handlePackageChange}
//             className="w-full p-2 bg-gray-700 rounded text-white"
//           />
//  <input
// type="text"
// name="music_duration"
// placeholder="Music Duration (In Seconds)"
// value={packageForm.music_duration}
// onChange={handlePackageChange}
// className="w-full p-2 bg-gray-700 rounded text-white"
// />

//           <label className="inline-flex items-center">
//             <input
//               type="checkbox"
//               name="is_event_package"
//               checked={packageForm.is_event_package}
//               onChange={handlePackageChange}
//               className="mr-2"
//             />
//             Event Package
//           </label>

//           {packageForm.is_event_package && (
//             <select
//               name="event_id"
//               value={packageForm.event_id || ''}
//               onChange={handleEventChange}
//               className="w-full p-2 bg-gray-700 rounded text-white"
//               required
//             >
//               <option value="">Select Event</option>
//               {eventData.map((event) => (
//                 <option key={event.eventID} value={event.eventID}>
//                   {event.eventName}
//                 </option>
//               ))}
//             </select>
//           )}

//           <h3 className="text-xl font-semibold mt-4">Videos</h3>
//           {videos.map((video, index) => (
//             <div key={index} className="flex gap-2 items-center mb-2">
//               <input
//                 type="text"
//                 name="title"
//                 placeholder="Video Title"
//                 value={video.title}
//                 onChange={(e) => handleVideoChange(index, 'title', e.target.value)}
//                 className="w-1/2 p-2 bg-gray-700 rounded text-white"
//                 required
//               />
//               <input
//                 type="file"
//                 onChange={(e) => {
//                   const file = e.target.files[0];
//                   handleVideoChange(index, 'file', file);
//                   handleFileUpload(index, file);
//                 }}
//                 className="w-1/2 p-2 bg-gray-700 rounded text-white"
//               />
//               {video.videoUrl && (
//                 <video src={video.videoUrl} className="w-24 h-16 rounded-md" controls />
//               )}
//               <button
//                 type="button"
//                 onClick={() => removeVideoField(index)}
//                 className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white"
//               >
//                 <FaTrashAlt />
//               </button>
//             </div>
//           ))}
//           <button
//             type="button"
//             onClick={addVideoField}
//             className="mt-2 bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded text-white"
//           >
//             <FaPlus className="inline mr-2" /> Add Video
//           </button>
//         </div>

//         <button
//           type="submit"
//           disabled={loading}
//           className="mt-4 w-full bg-green-500 hover:bg-green-600 px-4 py-2 rounded text-white"
//         >
//           {loading ? 'Submitting...' : 'Create Package'}
//         </button>
//       </form>

//       {/* Render the video packages */}
//       <div className="mt-12 max-w-5xl mx-auto">
//         <h2 className="text-3xl font-bold text-center mb-8">Existing Video Packages</h2>
//         {loading && <p className="text-center">Loading packages...</p>}
//         {!loading && packages.length === 0 && <p className="text-center">No video packages found.</p>}
//         <div className="grid gap-6 lg:grid-cols-2">
//           {packages.map((pkg) => (
//             <div key={pkg.id} className="bg-gray-800 p-6 rounded-md shadow-md">
//               <h3 className="text-2xl font-bold mb-2">{pkg.title}</h3>
//               <p className="text-gray-300 mb-2">{pkg.description}</p>
//               <p><strong>Price:</strong> ₹{pkg.price}</p>
//               <p><strong>Category:</strong> {pkg.category}</p>
// <p>Music Duration: {pkg.music_duration} seconds</p>
//               <p><strong>Event Package:</strong> {pkg.is_event_package ? 'Yes' : 'No'}</p>
//               {pkg.is_event_package && <p><strong>Event ID:</strong> {pkg.event_id}</p>}

//               <div className="mt-4 flex justify-between">
//                 <button
//                   onClick={() => deletePackage(pkg.id)}
//                   className="bg-red-600 hover:bg-red-700 px-4 py-2 rounded text-white"
//                 >
//                   Delete Package
//                 </button>
//               </div>

//               <div className="mt-4">
//                 <p className="font-semibold">Videos:</p>
//                 <ul className="space-y-2 mt-2">
//                   {(pkg.videos || []).map((video) => (
//                     <li key={video.id} className="flex items-center">
//                       <video src={video.video_url} controls className="w-24 h-16 mr-4 rounded-md" />
//                       <span className="text-gray-300">{video.title}</span>
//                       <button
//                         onClick={() => deleteVideo(video.id)}
//                         className="ml-auto bg-red-500 hover:bg-red-600 px-2 py-1 rounded text-white"
//                       >
//                         <FaTrashAlt />
//                       </button>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoPackage;
