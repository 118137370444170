import React, { useContext, useState } from "react";
import { DataContext } from "../context/DataContext";
import axios from "axios";
import { uploadFile } from "../../firebase/fileUpload";
import LoadingScreen from "../utilities/LoadingScreen";

const CollabTickets = () => {
  const { collabTickets, loading } = useContext(DataContext);
  console.log('collab tickets[0]', collabTickets.tickets[0])
  console.log('collab tickets[0].ticket', collabTickets.tickets[0].ticket)
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // State variables
  const [showTicketDetail, setShowTicketDetail] = useState(false);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [reply, setReply] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // For image overlay preview
  const [description, setDescription] = useState(""); // Editable description
  const [replies, setReplies] = useState(null);
  const [instaUser, setInstaUser] = useState(null); // User details for tickets
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch user details for a ticket
  const fetchUserDetails = async (userId) => {
    try {
      const tokenResponse = await axios.get(
        `${apiDomain}/api/v2/admin/getrefreshtoken?id=${userId}`
      );
      const accessToken = tokenResponse.data.accessToken;

      const userResponse = await axios.get(`${apiDomain}/api/v2/user`, {
        params: { access_token: accessToken },
      });

      const igUserResponse = await axios.get(
        `${apiDomain}/api/v2/facebook/instagramuser`,
        {
          params: {
            id: userResponse.data.instagram_business_account_id,
            access_token: accessToken,
          },
        }
      );
      setInstaUser(igUserResponse.data);
    } catch (err) {
      setError(err.message);
    }
  };

  // Fetch ticket details
  const fetchTicketDetails = async (ticketId) => {
    try {
      const response = await axios.get(
        // `http://localhost:5050/support/ticket/${ticketId}`
        `${apiDomain}/support/ticket/${ticketId}`
      );
      setCurrentTicket(response.data.ticket);
      setReplies(response.data.replies);
      setDescription(response.data.ticket.description); // Set the editable description
      await fetchUserDetails(response.data.ticket.user_id); // Fetch user details
      setShowTicketDetail(true);
    } catch (err) {
      setError(err.message);
    }
  };

 
  // Handle approve collaboration and create a ticket
  const handleApproveRequest = async () => {
    if (!currentTicket) return;
    try {
      setIsSubmitting(true);
      const newTicket = {
        user_id: parseInt(currentTicket.request.sender),
        title: currentTicket.title,
        description,
        isCollabTicket: true,
        collabRequestId: currentTicket.request.requestid, // Use the collaboration request ID
      };

      // Create the collaboration ticket
      try {
        // await axios.post(`http://localhost:5050/support/ticket`, newTicket)
         await axios.post(`${apiDomain}/support/ticket`, newTicket)
      } catch (e) {
        // console.log("e", e);
      }

      alert("Collaboration ticket approved and created successfully!");
      setIsSubmitting(false);
      setShowTicketDetail(false); // Return to ticket list
    } catch (err) {
      setError(err.message);
      setIsSubmitting(false);
    }
  };

  // Handle reply submission
  const handleReplySubmit = async () => {
    if (!currentTicket) return;

    try {
      setIsSubmitting(true);
      const newReply = {
        ticket_id: currentTicket.id,
        user_id: currentTicket.user_id,
        message: reply,
        parent_reply_id: null,
        source: "admin",
      };
      const replyResp = await axios.post(
        // `http://localhost:5050/support/reply`,newReply
         `${apiDomain}/support/reply`,newReply
        
      );

     

      // Upload attachment if exists
      if (attachment) {
        const fileUrl = await uploadFile(attachment);
        // await axios.post(`http://localhost:5050/support/attachment`, 
        await axios.post(`${apiDomain}/support/attachment`, 
          
          {
          reply_id: replyResp.data.id,
          file_url: fileUrl,
        });
      }

      alert("Reply submitted successfully!");
      setReply("");
      setAttachment(null);
      setIsSubmitting(false);

      // Refresh replies
      fetchTicketDetails(currentTicket.id);
    } catch (err) {
      setError(err.message);
      setIsSubmitting(false);
    }
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setAttachment(file);
    } else {
      alert("Please upload an image file (JPG, PNG, etc.)");
    }
  };

  // Back to ticket list
  const handleBackToList = () => {
    setShowTicketDetail(false);
    setCurrentTicket(null);
    setReplies(null);
    setDescription("");
  };

  // Image preview close
  const closeImagePreview = () => setImagePreview(null);

  return (
    <div className="lg:ml-[20vw] lg:mr-[2vw] p-4 min-h-screen">
      <h2 className="text-3xl font-bold mb-6">Collaboration Ticket</h2>

      {loading ? (
        <LoadingScreen />
      ) : !showTicketDetail ? (
        <div className="bg-white shadow-lg rounded-lg p-6 border">
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="border px-4 py-2 text-left">Ticket ID</th>
                <th className="border px-4 py-2 text-left">User ID</th>
                <th className="border px-4 py-2 text-left">Title</th>
                <th className="border px-4 py-2 text-left">Time</th>
                <th className="border px-4 py-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {collabTickets &&
                collabTickets.tickets.map((ticket) => (

              // for deployment server

              <tr key={ticket.id} className="bg-white hover:bg-gray-100">
                    <td className="border px-4 py-2">{ticket.ticket.id}</td>
                    <td className="border px-4 py-2">{ticket.ticket.user_id}</td>
                    <td className="border px-4 py-2">{ticket.ticket.title}</td>
                    <td className="border px-4 py-2">
                      {new Date(ticket.ticket.created_at).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => fetchTicketDetails(ticket.ticket.id)}
                        className="mr-2 px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                      >
                        View
                      </button>
                    </td>
                  </tr>

                  // for local machine
                //   <tr key={ticket.id} className="bg-white hover:bg-gray-100">
                //   <td className="border px-4 py-2">{ticket.id}</td>
                //   <td className="border px-4 py-2">{ticket.user_id}</td>
                //   <td className="border px-4 py-2">{ticket.title}</td>
                //   <td className="border px-4 py-2">
                //     {new Date(ticket.created_at).toLocaleString("en-GB", {
                //       day: "2-digit",
                //       month: "2-digit",
                //       hour: "2-digit",
                //       minute: "2-digit",
                //     })}
                //   </td>
                //   <td className="border px-4 py-2">
                //     <button
                //       onClick={() => fetchTicketDetails(ticket.id)}
                //       className="mr-2 px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                //     >
                //       View
                //     </button>
                //   </td>
                // </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="bg-white shadow-lg rounded-lg p-6">
          <button
            onClick={handleBackToList}
            className="px-4 py-2 bg-gray-500 text-white rounded mb-6 hover:bg-gray-600"
          >
            Back to Tickets
          </button>
          <h3 className="text-xl font-semibold mb-6">
            Ticket #{currentTicket.id}
          </h3>
          <textarea
            className="w-full p-2 mb-4 border border-gray-300 rounded"
            rows="4"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Edit ticket description"
          />
          { (currentTicket.user_id != currentTicket.request.sender) &&
            <button
              onClick={handleApproveRequest}
              className="px-4 py-2 bg-green-600 text-white rounded mb-4 hover:bg-green-700"
              disabled={isSubmitting}
            >
              Create Collaboration Ticket
            </button>
          }

          <h4 className="font-semibold mb-2">Replies</h4>
          {replies &&
            replies.map((reply) => (
              <div key={reply.reply.id} className="border p-4 mb-4 rounded">
                <p className="font-semibold">{reply.reply.source}</p>
                <p>{reply.reply.message}</p>
                {reply.attachments &&
                  reply.attachments.map((attachment) => (
                    <img
                      key={attachment.file_path}
                      src={attachment.file_path}
                      alt="attachment"
                      className="w-32 h-32 object-cover cursor-pointer rounded-md"
                      onClick={() => setImagePreview(attachment.file_path)}
                    />
                  ))}
              </div>
            ))}
          <h4 className="font-semibold mt-6 mb-2">Add a Reply</h4>
          <textarea
            className="w-full p-2 mb-4 border border-gray-300 rounded"
            rows="4"
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            placeholder="Write your reply"
          />
          <input type="file" onChange={handleFileChange} className="mb-4" />
          <button
            onClick={handleReplySubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded"
            disabled={isSubmitting}
          >
            Submit Reply
          </button>
        </div>
      )}

      {imagePreview && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeImagePreview}
        >
          <img
            src={imagePreview}
            alt="Full-size preview"
            className="max-w-full max-h-full"
          />
        </div>
      )}
    </div>
  );
};

export default CollabTickets;
