import React, { useState, useEffect } from "react";
import Select from "react-select";
import { uploadFile } from "../../firebase/fileUpload";
import { loadPincodes } from "../utilities/PincodeLoader";
import collapseIcon from "../event/assets/reduce.png";
import expandIcon from "../event/assets/expand-arrows.png";
import { FaTrash } from "react-icons/fa";

const SponsorDetailForm = ({
  savedCards = [],
  onSponsorDataChange,
  onDeleteSponsor,
  readOnly = false,
}) => {
  const initialSponsorData = {
    sponsorCategory: "",
    noOfCoupons: "999999",
    noOfGiftHampers: "",
    description: "",
    price: "",
    additional_data: {
      country: "India",
      state: [],
      city: [],
      pincode: [],
      is_national: true,
      is_state: false,
      is_city: false,
      is_pincode: false,
      is_international: false,
      location: "National",
      no_of_seats: "N/A",
      proposal_url: "N/A",
    },
  };

  const [sponsorData, setSponsorData] = useState(initialSponsorData);

  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [pincodeOptions, setPincodeOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({
    state: [],
    city: [],
    pincode: [],
  });
  const [isSponsorOpen, setIsSponsorOpen] = useState(true);

  useEffect(() => {
    const fetchPincodes = async () => {
      const pincodeData = await loadPincodes();
      const uniqueStates = [
        ...new Set(pincodeData.map((item) => item.stateName)),
      ];
      setStateOptions(
        uniqueStates.map((state) => ({ value: state, label: state }))
      );
    };

    fetchPincodes();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSponsorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle Additional Data Changes
  const handleAdditionalDataChange = (field, value) => {
    setSponsorData((prev) => ({
      ...prev,
      additional_data: {
        ...prev.additional_data,
        [field]: value,
      },
    }));
  };

  const handleCheckboxChange = (level) => {
    if (level === "is_national") {
      setSponsorData((prevData) => ({
        ...prevData,
        additional_data: {
          ...prevData.additional_data,
          is_national: !prevData.additional_data.is_national,
          is_state: false,
          is_city: false,
          is_pincode: false,
          is_international: false,
          location: !prevData.additional_data.is_national ? "National" : "",
          state: [],
          city: [],
          pincode: [],
        },
      }));
      setSelectedCategories({ state: [], city: [], pincode: [] });
    } else if (level === "is_international") {
      setSponsorData((prevData) => ({
        ...prevData,
        additional_data: {
          ...prevData.additional_data,
          is_international: !prevData.additional_data.is_international,
          is_national: false,
          is_state: false,
          is_city: false,
          is_pincode: false,
          location: !prevData.additional_data.is_international
            ? "International"
            : "",
          state: [],
          city: [],
          pincode: [],
        },
      }));
      setSelectedCategories({ state: [], city: [], pincode: [] });
    } else {
      setSponsorData((prevData) => ({
        ...prevData,
        additional_data: {
          ...prevData.additional_data,
          is_international: false,
          is_national: false,
          is_state: level === "is_state",
          is_city: level === "is_city",
          is_pincode: level === "is_pincode",
          location: "",
        },
      }));
      // Reset selected categories if necessary
      if (level !== "is_state") {
        setSelectedCategories((prevState) => ({ ...prevState, state: [] }));
        handleAdditionalDataChange("state", []);
      }
      if (level !== "is_city") {
        setSelectedCategories((prevState) => ({ ...prevState, city: [] }));
        handleAdditionalDataChange("city", []);
      }
      if (level !== "is_pincode") {
        setSelectedCategories((prevState) => ({ ...prevState, pincode: [] }));
        handleAdditionalDataChange("pincode", []);
      }
    }
  };

  const loadCityOptions = async (selectedStates) => {
    const pincodeData = await loadPincodes();
    const filteredCities = pincodeData
      .filter((item) => selectedStates.includes(item.stateName))
      .map((item) => item.districtName);
    const uniqueCities = [...new Set(filteredCities)];
    setCityOptions(uniqueCities.map((city) => ({ value: city, label: city })));
  };

  const loadPincodeOptions = async (selectedCities) => {
    const pincodeData = await loadPincodes();
    const filteredPincodes = pincodeData
      .filter((item) => selectedCities.includes(item.districtName))
      .map((item) => item.pincode);
    const uniquePincodes = [...new Set(filteredPincodes)];
    setPincodeOptions(
      uniquePincodes.map((pincode) => ({ value: pincode, label: pincode }))
    );
  };

  const handleCategoryChange = (selectedOptions, categoryType) => {
    let options = selectedOptions || [];
    if (options.some((option) => option.value === "all")) {
      options =
        categoryType === "state"
          ? stateOptions
          : categoryType === "city"
          ? cityOptions
          : pincodeOptions;
    }

    const selectedValues = options.map((opt) => opt.value);
    setSelectedCategories((prevState) => ({
      ...prevState,
      [categoryType]: options,
    }));

    handleAdditionalDataChange(categoryType, selectedValues);

    if (categoryType === "state") {
      // When states change, reset cities and pincodes
      setSelectedCategories((prevState) => ({
        ...prevState,
        city: [],
        pincode: [],
      }));
      handleAdditionalDataChange("city", []);
      handleAdditionalDataChange("pincode", []);
      loadCityOptions(selectedValues);
    } else if (categoryType === "city") {
      // When cities change, reset pincodes
      setSelectedCategories((prevState) => ({ ...prevState, pincode: [] }));
      handleAdditionalDataChange("pincode", []);
      loadPincodeOptions(selectedValues);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const url = await uploadFile(file);
        handleAdditionalDataChange("proposal_url", url);
      } catch (error) {
        console.error("File upload failed:", error);
      }
    }
  };

  const handleSaveData = () => {
    if (
      !sponsorData.sponsorCategory ||
      !sponsorData.description ||
      !sponsorData.price
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    onSponsorDataChange(sponsorData);

    // Reset the form
    setSponsorData({
      sponsorCategory: "",
      noOfCoupons: "999999",
      noOfGiftHampers: "",
      description: "",
      price: "",
      additional_data: {
        country: "India",
        state: [],
        city: [],
        pincode: [],
        is_national: true,
        location: "National",
        no_of_seats: "N/A",
        proposal_url: "N/A",
      },
    });
  };

  const handleDeleteCard = (index) => {
    if (onDeleteSponsor) {
      onDeleteSponsor(index); // Call the parent-provided delete handler
    }
  };

  const handleTextareaResize = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <div className="bg-blue-50 p-4 rounded-lg shadow-lg border my-4">
      {!readOnly && (
        <div className="w-full flex items-center justify-between">
          <h3 className="text-xl font-semibold text-blue-600 rounded-md mb-5">
            Sponsor Details
          </h3>
          <button
            type="button"
            onClick={() => setIsSponsorOpen(!isSponsorOpen)}
            className="flex items-center"
          >
            <img
              src={isSponsorOpen ? collapseIcon : expandIcon}
              alt={isSponsorOpen ? "Collapse" : "Expand"}
              className="w-6 h-6"
            />
          </button>
        </div>
      )}
      {isSponsorOpen && !readOnly && (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="relative">
              <label className="block text-sm lg:text-lg font-medium text-gray-700 mb-2">
                Sponsor Category <span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                name="sponsorCategory"
                value={sponsorData.sponsorCategory}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="Enter sponsor category"
              />
            </div>
            <div className="relative">
              <label className="block text-sm lg:text-lg font-medium text-gray-700 mb-2">
                No of Coupons (Default: 999999)
              </label>
              <input
                type="number"
                name="noOfCoupons"
                value={sponsorData.noOfCoupons}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="Enter number of coupons"
              />
            </div>
            <div className="relative">
              <label className="block text-sm lg:text-lg font-medium text-gray-700 mb-2">
                No of Gift Hampers <span className="text-red-600"> *</span>
              </label>
              <input
                type="number"
                name="noOfGiftHampers"
                value={sponsorData.noOfGiftHampers}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="Enter number of gift hampers"
              />
            </div>
            <div className="relative">
              <label className="block text-sm lg:text-lg font-medium text-gray-700 mb-2">
                Price <span className="text-red-600"> *</span>
              </label>
              <input
                type="number"
                name="price"
                value={sponsorData.price}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="Enter price"
              />
            </div>
          </div>

          <div className="my-8 lg:w-full relative">
            <label className="block text-sm lg:text-lg font-medium text-gray-700 mb-2">
              Description <span className="text-red-600"> *</span>
            </label>
            <textarea
              name="description"
              value={sponsorData.description}
              onChange={handleInputChange}
              onInput={handleTextareaResize}
              className="w-full min-h-[150px] border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              placeholder="Enter description"
            />
          </div>

          <h3 className="text-lg font-medium text-gray-700 mb-3">
            Sponsor Location Details
          </h3>
          <div className="mb-4 flex flex-wrap gap-8">
            <label className="mr-4 flex items-center">
              <input
                type="checkbox"
                checked={sponsorData.additional_data.is_national}
                onChange={() => handleCheckboxChange("is_national")}
              />
              <span className="ml-2">National</span>
            </label>
            <label className="mr-4 flex items-center">
              <input
                type="checkbox"
                checked={sponsorData.additional_data.is_state}
                onChange={() => handleCheckboxChange("is_state")}
              />
              <span className="ml-2">State</span>
            </label>
            <label className="mr-4 flex items-center">
              <input
                type="checkbox"
                checked={sponsorData.additional_data.is_city}
                onChange={() => handleCheckboxChange("is_city")}
              />
              <span className="ml-2">City</span>
            </label>
            <label className="mr-4 flex items-center">
              <input
                type="checkbox"
                checked={sponsorData.additional_data.is_pincode}
                onChange={() => handleCheckboxChange("is_pincode")}
              />
              <span className="ml-2">Pincode</span>
            </label>
            <label className="mr-4 flex items-center">
              <input
                type="checkbox"
                checked={sponsorData.additional_data.is_international}
                onChange={() => handleCheckboxChange("is_international")}
              />
              <span className="ml-2">International</span>
            </label>
          </div>

          {/* Dropdowns for State, City, Pincode */}
          {sponsorData.additional_data.is_state && (
            <div className="mt-6 mb-12">
              <Select
                options={[
                  { value: "all", label: "Select All" },
                  ...stateOptions,
                ]}
                isMulti
                onChange={(selectedOptions) =>
                  handleCategoryChange(selectedOptions, "state")
                }
                value={selectedCategories.state}
                placeholder="Select State"
                className="w-full"
              />
            </div>
          )}

          {sponsorData.additional_data.is_city && (
            <div className="grid lg:grid-cols-2 gap-8 mt-8 mb-4">
              <Select
                options={[
                  { value: "all", label: "Select All" },
                  ...stateOptions,
                ]}
                isMulti
                onChange={(selectedOptions) =>
                  handleCategoryChange(selectedOptions, "state")
                }
                value={selectedCategories.state}
                placeholder="Select State"
                className="w-full"
              />
              <Select
                options={[
                  { value: "all", label: "Select All" },
                  ...cityOptions,
                ]}
                isMulti
                onChange={(selectedOptions) =>
                  handleCategoryChange(selectedOptions, "city")
                }
                value={selectedCategories.city}
                placeholder="Select City"
                className="w-full"
              />
            </div>
          )}

          {sponsorData.additional_data.is_pincode && (
            <div className="grid lg:grid-cols-3 gap-8 mt-8 mb-20">
              <Select
                options={[
                  { value: "all", label: "Select All" },
                  ...stateOptions,
                ]}
                isMulti
                onChange={(selectedOptions) =>
                  handleCategoryChange(selectedOptions, "state")
                }
                value={selectedCategories.state}
                placeholder="Select State"
                className="w-full"
              />
              <Select
                options={[
                  { value: "all", label: "Select All" },
                  ...cityOptions,
                ]}
                isMulti
                onChange={(selectedOptions) =>
                  handleCategoryChange(selectedOptions, "city")
                }
                value={selectedCategories.city}
                placeholder="Select City"
                className="w-full"
              />
              <Select
                options={[
                  { value: "all", label: "Select All" },
                  ...pincodeOptions,
                ]}
                isMulti
                onChange={(selectedOptions) =>
                  handleCategoryChange(selectedOptions, "pincode")
                }
                value={selectedCategories.pincode}
                placeholder="Select Pincode"
                className="w-full"
              />
            </div>
          )}

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="my-4">
              <label className="block text-sm lg:text-lg font-medium text-gray-700 mb-2">
                Sponsor Entry (No of Seats){" "}
                <span className="text-red-600"> *</span>
              </label>
              <input
                type="number"
                value={sponsorData.additional_data.no_of_seats}
                onChange={(e) =>
                  handleAdditionalDataChange("no_of_seats", e.target.value)
                }
                className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="Enter number of seats"
              />
            </div>
            <div className="my-4">
              <label className="block text-sm lg:text-lg font-medium text-gray-700 mb-2">
                Proposal Document
              </label>
              <input
                type="file"
                onChange={handleFileUpload}
                className="w-full border bg-white border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-blue-500 focus:outline-none file:mr-4 file:py- file:px-4 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
            </div>
          </div>

          <button
            type="button"
            onClick={handleSaveData}
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Save Sponsor Data
          </button>
        </>
      )}

      {savedCards &&
        savedCards.map((card, index) => (
          <div
            key={index}
            className="bg-white p-4 mt-6 rounded-lg shadow-md relative"
          >
            {!readOnly && (
              <button
                type="button"
                onClick={() => handleDeleteCard(index)}
                className="absolute top-2 right-2 text-red-500 hover:text-red-700"
              >
                <FaTrash />
              </button>
            )}
            <h3 className="text-lg font-semibold mb-4">
              Saved Sponsor Details
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <p>
                <strong>Category:</strong> {card.sponsorCategory || "N/A"}
              </p>
              <p>
                <strong>No of Coupons:</strong> {card.noOfCoupons || "N/A"}
              </p>
              <p>
                <strong>No of Gift Hampers:</strong>{" "}
                {card.noOfGiftHampers || "N/A"}
              </p>
              <p>
                <strong>Price:</strong> {card.price || "N/A"}
              </p>
              <p className="md:col-span-2">
                <strong>Description:</strong> {card.description || "N/A"}
              </p>
              <p>
                <strong>Location:</strong>{" "}
                {card.additional_data.location || "N/A"}
              </p>
              <p>
                <strong>States:</strong>{" "}
                {Array.isArray(card.additional_data.state) &&
                card.additional_data.state.length > 0
                  ? card.additional_data.state.join(", ")
                  : "N/A"}
              </p>
              <p>
                <strong>Cities:</strong>{" "}
                {Array.isArray(card.additional_data.city) &&
                card.additional_data.city.length > 0
                  ? card.additional_data.city.join(", ")
                  : "N/A"}
              </p>
              <p>
                <strong>Pincodes:</strong>{" "}
                {Array.isArray(card.additional_data.pincode) &&
                card.additional_data.pincode.length > 0
                  ? card.additional_data.pincode.join(", ")
                  : "N/A"}
              </p>
              <p>
                <strong>No of Seats:</strong>{" "}
                {card.additional_data.no_of_seats || "N/A"}
              </p>
              <p>
                <strong>Proposal URL:</strong>{" "}
                {card.additional_data.proposal_url !== "N/A" ? (
                  <a
                    href={card.additional_data.proposal_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                  >
                    View Document
                  </a>
                ) : (
                  "N/A"
                )}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SponsorDetailForm;

// import React, { useState, useEffect } from "react";
// import Select from "react-select";
// import { uploadFile } from "../../firebase/fileUpload";
// import { loadPincodes } from "../utilities/PincodeLoader";
// import collapseIcon from "../event/assets/reduce.png";
// import expandIcon from "../event/assets/expand-arrows.png";

// const SponsorDetailForm = ({ onSponsorDataChange }) => {
//   const [sponsorData, setSponsorData] = useState({
//     sponsorCategory: "",
//     noOfCoupons: "999999", // Default value for unlimited coupons
//     noOfGiftHampers: "",
//     description: "",
//     price: "",
//     additional_data: {
//       country: "India",
//       state: [],
//       city: [],
//       pincode: [],
//       is_national: true,
//       is_state: false,
//       is_city: false,
//       is_pincode: false,
//       no_of_seats: "",
//       proposal_url: "",
//     },
//   });

//   const [stateOptions, setStateOptions] = useState([]);
//   const [cityOptions, setCityOptions] = useState([]);
//   const [pincodeOptions, setPincodeOptions] = useState([]);
//   const [selectedCategories, setSelectedCategories] = useState({
//     state: [],
//     city: [],
//     pincode: [],
//   });
//   const [isSponsorOpen, setIsSponsorOpen] = useState(true);

//   useEffect(() => {
//     const fetchPincodes = async () => {
//       const pincodeData = await loadPincodes();
//       const uniqueStates = [
//         ...new Set(pincodeData.map((item) => item.stateName)),
//       ];
//       setStateOptions(
//         uniqueStates.map((state) => ({ value: state, label: state }))
//       );
//     };

//     fetchPincodes();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setSponsorData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleAdditionalDataChange = (field, value) => {
//     setSponsorData((prevData) => ({
//       ...prevData,
//       additional_data: {
//         ...prevData.additional_data,
//         [field]: value,
//       },
//     }));
//   };

//   const handleCheckboxChange = (level) => {
//     if (level === "is_national") {
//       setSponsorData((prevData) => ({
//         ...prevData,
//         additional_data: {
//           ...prevData.additional_data,
//           is_national: !prevData.additional_data.is_national,
//           is_state: false,
//           is_city: false,
//           is_pincode: false,
//         },
//       }));
//     } else {
//       setSponsorData((prevData) => ({
//         ...prevData,
//         additional_data: {
//           ...prevData.additional_data,
//           is_national: false,
//           is_state: level === "is_state",
//           is_city: level === "is_city",
//           is_pincode: level === "is_pincode",
//         },
//       }));
//     }
//   };

//   const loadCityOptions = async (selectedStates) => {
//     const pincodeData = await loadPincodes();
//     const filteredCities = pincodeData
//       .filter((item) => selectedStates.includes(item.stateName))
//       .map((item) => item.districtName);
//     const uniqueCities = [...new Set(filteredCities)];
//     setCityOptions(
//       uniqueCities.map((city) => ({ value: city, label: city }))
//     );
//   };

//   const loadPincodeOptions = async (selectedCities) => {
//     const pincodeData = await loadPincodes();
//     const filteredPincodes = pincodeData
//       .filter((item) => selectedCities.includes(item.districtName))
//       .map((item) => item.pincode);
//     const uniquePincodes = [...new Set(filteredPincodes)];
//     setPincodeOptions(
//       uniquePincodes.map((pincode) => ({ value: pincode, label: pincode }))
//     );
//   };

//   const handleCategoryChange = (selectedOptions, categoryType) => {
//     let options = selectedOptions || [];
//     if (options.some((option) => option.value === "all")) {
//       options = categoryType === "state"
//         ? stateOptions
//         : categoryType === "city"
//         ? cityOptions
//         : pincodeOptions;
//     }

//     const selectedValues = options.map((opt) => opt.value);
//     setSelectedCategories((prevState) => ({
//       ...prevState,
//       [categoryType]: options,
//     }));

//     if (categoryType === "state") {
//       handleAdditionalDataChange("state", selectedValues.join(","));
//       loadCityOptions(selectedValues);
//       setSelectedCategories((prevState) => ({ ...prevState, city: [] }));
//       setSelectedCategories((prevState) => ({ ...prevState, pincode: [] }));
//     } else if (categoryType === "city") {
//       handleAdditionalDataChange("city", selectedValues.join(","));
//       loadPincodeOptions(selectedValues);
//       setSelectedCategories((prevState) => ({ ...prevState, pincode: [] }));
//     } else {
//       handleAdditionalDataChange("pincode", selectedValues.join(","));
//     }
//   };

//   const handleFileUpload = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       try {
//         const url = await uploadFile(file);
//         handleAdditionalDataChange("proposal_url", url);
//       } catch (error) {
//         console.error("File upload failed:", error);
//       }
//     }
//   };

//   const handleSaveData = () => {
//     // if (!sponsorData.sponsorCategory || !sponsorData.noOfCoupons) {
//     //   alert("Please fill in the Sponsor Category and Number of Coupons.");
//     //   return;
//     // }

//     const updatedSponsorData = {
//       ...sponsorData,
//       additional_data: {
//         ...sponsorData.additional_data,
//         no_of_seats: sponsorData.additional_data.no_of_seats || "N/A",
//         proposal_url: sponsorData.additional_data.proposal_url || "N/A",
//       },
//     };

//     onSponsorDataChange(updatedSponsorData);

//     setSponsorData({
//       sponsorCategory: "",
//       noOfCoupons: "999999", // Resetting to default after saving
//       noOfGiftHampers: "",
//       description: "",
//       price: "",
//       additional_data: {
//         country: "India",
//         state: [],
//         city: [],
//         pincode: [],
//         is_national: true,
//         is_state: false,
//         is_city: false,
//         is_pincode: false,
//       },
//     });
//   };

//   return (
//     <div className="bg-white p-1 lg:p-4 rounded-lg shadow-sm border my-4">
//       <div className="w-full flex items-center justify-between">
//         <h3 className="text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-2">
//           Sponsor Details
//         </h3>
//         <button
//           type="button"
//           onClick={() => setIsSponsorOpen(!isSponsorOpen)}
//           className="flex items-center"
//         >
//           <img
//             src={isSponsorOpen ? collapseIcon : expandIcon}
//             alt={isSponsorOpen ? "Collapse" : "Expand"}
//             className="w-6 h-6"
//           />
//         </button>
//       </div>
//       {isSponsorOpen && (
//         <>
//           <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
//             <div className="relative">
//               <input
//                 type="text"
//                 name="sponsorCategory"
//                 value={sponsorData.sponsorCategory}
//                 onChange={handleInputChange}
//                 className="w-full border border-gray-300 rounded-md p-3 peer"
//                 placeholder=" "
//                 required
//               />
//               <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
//                 Sponsor Category
//               </label>
//             </div>
//             <div className="relative">
//               <input
//                 type="number"
//                 name="noOfCoupons"
//                 value={sponsorData.noOfCoupons}
//                 onChange={handleInputChange}
//                 className="w-full border border-gray-300 rounded-md p-3 peer"
//                 placeholder=" "
//               />
//               <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
//                 No of Coupons (Default: 999999)
//               </label>
//             </div>
//             <div className="relative">
//               <input
//                 type="number"
//                 name="noOfGiftHampers"
//                 value={sponsorData.noOfGiftHampers}
//                 onChange={handleInputChange}
//                 className="w-full border border-gray-300 rounded-md p-3 peer"
//                 placeholder=" "
//               />
//               <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
//                 No of Gift Hampers
//               </label>
//             </div>
//             <div className="relative">
//               <input
//                 type="number"
//                 name="price"
//                 value={sponsorData.price}
//                 onChange={handleInputChange}
//                 className="w-full border border-gray-300 rounded-md p-3 peer"
//                 placeholder=" "
//               />
//               <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
//                 Price
//               </label>
//             </div>
//           </div>

//           <div className="my-8 lg:w-1/2 relative">
//             <textarea
//               name="description"
//               value={sponsorData.description}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded-md p-3 peer"
//               placeholder=" "
//             />
//             <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
//               Description
//             </label>
//           </div>

//           <h3 className="text-lg font-medium text-gray-700 mb-3">Sponsor Additional Data</h3>
//           <div className="mb-4 flex  gap-8">
//             <label className="mr-4">
//               <input
//                 type="checkbox"
//                 checked={sponsorData.additional_data.is_national}
//                 onChange={() => handleCheckboxChange("is_national")}
//               />
//               <span className="ml-2">National</span>
//             </label>
//             <label className="mr-4">
//               <input
//                 type="checkbox"
//                 checked={sponsorData.additional_data.is_state}
//                 onChange={() => handleCheckboxChange("is_state")}
//               />
//               <span className="ml-2">State</span>
//             </label>
//             <label className="mr-4">
//               <input
//                 type="checkbox"
//                 checked={sponsorData.additional_data.is_city}
//                 onChange={() => handleCheckboxChange("is_city")}
//               />
//               <span className="ml-2">City</span>
//             </label>
//             <label className="mr-4">
//               <input
//                 type="checkbox"
//                 checked={sponsorData.additional_data.is_pincode}
//                 onChange={() => handleCheckboxChange("is_pincode")}
//               />
//               <span className="ml-2">Pincode</span>
//             </label>
//           </div>

//           {sponsorData.additional_data.is_state && (
//             <div className="mt-6 mb-12">
//             <Select
//               options={[{ value: "all", label: "Select All" }, ...stateOptions]}
//               isMulti
//               onChange={(selectedOptions) =>
//                 handleCategoryChange(selectedOptions, "state")
//               }
//               value={selectedCategories.state}
//               placeholder="Select State"
//               className="w-full"
//             />
//             </div>
//           )}

//           {sponsorData.additional_data.is_city && (
//             <div className="grid lg:grid-cols-2 gap-8 mt-8 mb-4">
//               <Select
//                 options={[{ value: "all", label: "Select All" }, ...stateOptions]}
//                 isMulti
//                 onChange={(selectedOptions) =>
//                   handleCategoryChange(selectedOptions, "state")
//                 }
//                 value={selectedCategories.state}
//                 placeholder="Select State"
//                 className="w-full"
//               />
//               <Select
//                 options={[{ value: "all", label: "Select All" }, ...cityOptions]}
//                 isMulti
//                 onChange={(selectedOptions) =>
//                   handleCategoryChange(selectedOptions, "city")
//                 }
//                 value={selectedCategories.city}
//                 placeholder="Select City"
//                 className="w-full"
//               />
//             </div>
//           )}

//           {sponsorData.additional_data.is_pincode && (
//             <div className="grid lg:grid-cols-3 gap-8 mt-8 mb-20">
//               <Select
//                 options={[{ value: "all", label: "Select All" }, ...stateOptions]}
//                 isMulti
//                 onChange={(selectedOptions) =>
//                   handleCategoryChange(selectedOptions, "state")
//                 }
//                 value={selectedCategories.state}
//                 placeholder="Select State"
//                 className="w-full"
//               />
//               <Select
//                 options={[{ value: "all", label: "Select All" }, ...cityOptions]}
//                 isMulti
//                 onChange={(selectedOptions) =>
//                   handleCategoryChange(selectedOptions, "city")
//                 }
//                 value={selectedCategories.city}
//                 placeholder="Select City"
//                 className="w-full"
//               />
//               <Select
//                 options={[{ value: "all", label: "Select All" }, ...pincodeOptions]}
//                 isMulti
//                 onChange={(selectedOptions) =>
//                   handleCategoryChange(selectedOptions, "pincode")
//                 }
//                 value={selectedCategories.pincode}
//                 placeholder="Select Pincode"
//                 className="w-full"
//               />
//             </div>
//           )}

//           <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
//             <div className="my-4 relative">
//               <input
//                 type="number"
//                 value={sponsorData.additional_data.no_of_seats}
//                 onChange={(e) => handleAdditionalDataChange("no_of_seats", e.target.value)}
//                 className="w-full border border-gray-300 rounded-md p-3 peer"
//                 placeholder=" "
//               />
//               <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-3 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
//                 Sponsor Entry (No of Seats)
//               </label>
//             </div>
//             <div className="my-4 relative">
//               <input
//                 type="file"
//                 onChange={handleFileUpload}
//                 className="w-full border border-gray-300 rounded-md p-3 peer"
//                 placeholder=" "
//               />
//               <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-4 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
//                 Proposal Document
//               </label>
//             </div>
//           </div>
//           <button
//             type="button"
//             onClick={handleSaveData}
//             className="bg-blue-500 text-white px-4 py-2 rounded-md"
//           >
//             Save Sponsor Data
//           </button>
//         </>
//       )}
//     </div>
//   );
// };

// export default SponsorDetailForm;
