import React, { useEffect, useState } from "react";
import { FaTrash, FaPlus } from "react-icons/fa";

const Entities = ({ entities = [], setEntities, isEditMode }) => {
  const [localEntities, setLocalEntities] = useState([]);

  // Initialize local state with parent entities in edit mode
  useEffect(() => {
    if (isEditMode) {
      setLocalEntities(entities);
    }
  }, [entities, isEditMode]);

  // Add Entity
  const handleAddEntity = () => {
    const newEntity = { entityName: "", isEntity: false };
    const updatedEntities = [...localEntities, newEntity];
    setLocalEntities(updatedEntities);
    setEntities(updatedEntities); // Sync with parent state
  };

  // Remove Entity
  const handleRemoveEntity = (index) => {
    const updatedEntities = localEntities.filter((_, i) => i !== index);
    setLocalEntities(updatedEntities);
    setEntities(updatedEntities); // Sync with parent state
  };

  // Update Entity
  const handleEntityChange = (index, key, value) => {
    const updatedEntities = localEntities.map((entity, i) =>
      i === index ? { ...entity, [key]: value } : entity
    );
    setLocalEntities(updatedEntities);
    setEntities(updatedEntities); // Sync with parent state
  };

  return (
    <section className="p-4 border rounded-md bg-blue-50 shadow-md mt-4">
      <h3 className="text-lg font-semibold mb-4">Entities</h3>
      <div className="grid lg:grid-cols-2 gap-4">
        {localEntities && localEntities.length > 0 ? (
          localEntities && localEntities.map((entity, index) => (
            <div
              key={index}
              className="flex flex-col lg:flex-row items-center gap-4 p-4 bg-white shadow-md rounded-md"
            >
              {/* Entity Name */}
              <input
                type="text"
                value={entity.entityName}
                onChange={(e) =>
                  handleEntityChange(index, "entityName", e.target.value)
                }
                className="flex-1 border rounded-md px-4 py-2"
                placeholder="Entity Name"
              />

              {/* Checkbox */}
              <div className="flex items-center gap-2">
                <label className="text-sm font-medium text-gray-600">
                  Is Entity
                </label>
                <input
                  type="checkbox"
                  checked={entity.isEntity}
                  onChange={(e) =>
                    handleEntityChange(index, "isEntity", e.target.checked)
                  }
                  className="h-5 w-5 text-blue-600"
                />
              </div>

              {/* Remove Button */}
              <button
                type="button"
                onClick={() => handleRemoveEntity(index)}
                className="text-red-600 bg-gray-200 px-3 py-2 rounded-md hover:bg-gray-300 flex items-center gap-2"
              >
                <FaTrash /> Remove
              </button>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No entities added yet.</p>
        )}
      </div>
      {/* Add Entity Button */}
      <button
        type="button"
        onClick={handleAddEntity}
        className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md mt-4 hover:bg-blue-500"
      >
        <FaPlus /> Add Entity
      </button>
    </section>
  );
};

export default Entities;
