import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaSave, FaChevronUp, FaChevronDown, FaCheckCircle, FaTimesCircle,  } from "react-icons/fa";
import SponsorDetailForm from "./SponsorDetailForm";

const EventCard = ({ event, isOpen, onToggle }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [eventData, setEventData] = useState(event);
  console.log("eventData......", eventData);
  const navigate = useNavigate();

  const toggleEdit = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      window.scrollTo(0, 0);
      navigate("/event-form", { state: { event } });
    }
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "N/A";
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year
    return `${day}/${month}/${year}`;
  };

    // Determine publish status
    const isPublished =
    eventData.additionalData?.isPublish !== undefined
      ? eventData.additionalData?.isPublish
      : false;

  const renderEditableField = (name, value, type = "text", index = null) =>
    isEditing ? (
      <input
        type={type}
        name={index !== null ? `${name}_${index}` : name}
        value={value}
        onChange={(e) =>
          setEventData((prevData) => ({
            ...prevData,
            sponsorData:
              index !== null
                ? prevData.sponsorData.map((s, i) =>
                    i === index ? { ...s, [name]: e.target.value } : s
                  )
                : { ...prevData, [name]: e.target.value },
          }))
        }
        className="border border-gray-300 rounded-lg px-2 py-1 w-full"
      />
    ) : type === "date" ? (
      formatDate(value)
    ) : (
      value || "N/A"
    );

  return (
    <div className="bg-blue-50 shadow-lg rounded-lg overflow-hidden border border-gray-200 p-4 mb-6">

<div className="flex items-center mb-4">
  {isPublished ? (
    <div className="flex items-center bg-green-100 text-green-700 px-4 py-2 rounded-full shadow-sm">
      <FaCheckCircle className="mr-2 text-green-600" size={18} />
      <span className="font-semibold">Published</span>
    </div>
  ) : (
    <div className="flex items-center bg-red-100 text-red-700 px-4 py-2 rounded-full shadow-sm">
      <FaTimesCircle className="mr-2 text-red-600" size={18} />
      <span className="font-semibold">Not Published</span>
    </div>
  )}
</div>
      <div className="flex justify-between items-start lg:items-center mb-8">
        <h2 className="text-lg lg:text-2xl font-bold text-gray-700 capitalize">
          {isOpen ? "Event Overview" : eventData.eventName}
        </h2>
        <button
          onClick={toggleEdit}
          className="bg-blue-500 text-white text-[10px] md:text-sm px-4 py-2 rounded-full flex items-center hover:bg-blue-600 transition"
        >
          {isEditing ? (
            <FaSave className="mr-2" />
          ) : (
            <FaEdit className="mr-2" />
          )}
          {isEditing ? "Save" : "Edit Event"}
        </button>
        <button
          onClick={onToggle}
          className="bg-gray-200 px-4 py-2 text-[10px] md:text-sm rounded-full flex items-center hover:bg-gray-300 transition"
        >
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>

      {isOpen && (
        <article>
          {/* Event Details */}
          <div className="bg-gray-50 shadow rounded-lg lg:p-4 p-1 lg:mb-6 mb-3">
            <h3 className="text-2xl font-semibold text-blue-600 mb-4">
              Event Details
            </h3>
            <div className="grid grid-cols-1 gap-2">
              <p className="text-lg capitalize">
                <strong className="text-gray-700">Event Name:</strong>{" "}
                {renderEditableField("eventName", eventData.eventName, "text")}
              </p>
              {eventData && eventData.additionalData && (
                <p className="text-lg capitalize">
                  <strong className="text-gray-700">Event Price:</strong>{" "}
                  {eventData.additionalData.eventPrice || "N/A"}
                </p>
              )}
              <p className="text-lg capitalize">
                <strong className="text-gray-700">Start Date:</strong>{" "}
                {renderEditableField("startDate", eventData.startDate, "date")}
              </p>
              <p className="text-lg">
                <strong className="text-gray-700">End Date:</strong>{" "}
                {renderEditableField("endDate", eventData.endDate, "date")}
              </p>
              <p className="text-lg">
                <strong className="text-gray-700">Participant Count:</strong>{" "}
                {renderEditableField(
                  "endDate",
                  eventData.participantsCount,
                  "number"
                )}
              </p>
            </div>
            <div className="text-lg my-2">
              <strong className="text-gray-700">Description:</strong>{" "}
              {renderEditableField(
                "description",
                eventData.description,
                "text"
              )}
            </div>
            {/* <div className="text-lg my-2">
                <strong className="text-gray-700">Description:</strong>{" "}
                {renderEditableField("description", eventData.additionalData || "N/A", "text")}
              </div> */}
          </div>

          {/* Sponsor Details */}
          <div className="shadow rounded-lg lg:p-4 p-1 lg:mb-6 mb-3">
            <h3 className="text-2xl font-semibold text-blue-600 mb-4">
              Sponsor Details
            </h3>
            <SponsorDetailForm
              savedCards={eventData.sponsorData}
              readOnly={true}
            />
          </div>

          {/* Template Data */}
          <div className="bg-blue-50 shadow rounded-lg lg:p-4 p-1 lg:mb-6 mb-3">
            <h3 className="text-2xl font-semibold text-blue-600 mb-4">
              Template Image
            </h3>
            {eventData.templateData ? (
              <img
                src={eventData.templateData}
                alt="Template"
                className="w-60 h-60 lg:w-96 lg:h-96 rounded-md border border-gray-300"
              />
            ) : (
              <p className="text-gray-600">No Image Available</p>
            )}
          </div>
        </article>
      )}
    </div>
  );
};

export default EventCard;

// import React, { useContext, useState } from "react";
// import { DataContext } from "../context/DataContext";
// import { useNavigate } from "react-router-dom";
// import { FaEdit, FaSave, FaChevronUp, FaChevronDown } from "react-icons/fa";

// const EventCard = ({ event, isOpen, onToggle }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [eventData, setEventData] = useState(event);
//   const navigate = useNavigate();
//   const {
//     categories,
//     ageCategories,
//     genderCategories,
//     followerCountCategories,
//     venues,
//     loading,
//   } = useContext(DataContext);

//   if (loading) return null;

//   const toggleEdit = () => {
//     setIsEditing(!isEditing);
//     if (!isEditing) {
//       window.scrollTo(0, 0); // Ensures the page scrolls to the top
//       navigate("/event-form", { state: { event } }); // Navigate to the edit form with event data
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setEventData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const getCategoryNames = (categoryIds, categoryList) =>
//     categoryIds
//       ? categoryList
//           .filter((category) => categoryIds.includes(category.id))
//           .map(
//             (category) =>
//               category.name ||
//               category.gender ||
//               `${category.startCount}-${category.endCount}`
//           )
//           .join(", ")
//       : "N/A";

//   // const venueDetails = venues.find((venue) => venue.id === eventData.venue);

//   return (
//     <div className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 p-4 mb-6">
//       <div className="flex justify-between items-start lg:items-center mb-8">
//         <h2 className="text-lg lg:text-2xl font-bold text-gray-700">
//           {isOpen ? "Event Overview" : eventData.eventName}
//         </h2>
//         <button
//           onClick={toggleEdit}
//           className="bg-blue-500 text-white text-[10px] md:text-sm px-4 py-2 rounded-full flex items-center hover:bg-blue-600 transition"
//         >
//           {isEditing ? (
//             <FaSave className="mr-2" />
//           ) : (
//             <FaEdit className="mr-2" />
//           )}
//           {isEditing ? "Save" : "Edit Event"}
//         </button>
//         <div className="flex justify-end mb-4">
//           <button
//             onClick={onToggle} // Call onToggle prop to toggle this card's open state
//             className="bg-gray-200 px-4 py-2 text-[10px] md:text-sm rounded-full flex items-center hover:bg-gray-300 transition"
//           >
//             <span className="ml-2">
//               {isOpen ? "Hide Card Details" : "Show Card Details"}
//             </span>
//             {isOpen ? <FaChevronUp /> : <FaChevronDown />}
//           </button>
//         </div>
//       </div>

//       {isOpen && (
//         <article>
//           {/* Event Details Card */}
//           <div className="bg-gray-50 shadow rounded-lg lg:p-4 p-1 lg:mb-6 mb-3">
//             <h3 className="text-2xl font-semibold text-blue-600 mb-4">
//               Event Details
//             </h3>
//             <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
//               <p className="text-lg">
//                 <strong className="text-gray-700">Event Name:</strong>{" "}
//                 {isEditing ? (
//                   <input
//                     type="text"
//                     name="eventName"
//                     value={eventData.eventName}
//                     onChange={handleChange}
//                     className="border border-gray-300 rounded-lg lg:p-4 p-1 lg:mb-6 mb-3 w-full"
//                   />
//                 ) : (
//                   eventData.eventName
//                 )}
//               </p>
//               <p className="text-lg">
//                 <strong className="text-gray-700">Start Date:</strong>{" "}
//                 {isEditing ? (
//                   <input
//                     type="date"
//                     name="startDate"
//                     value={eventData.startDate}
//                     onChange={handleChange}
//                     className="border border-gray-300 rounded-lg lg:p-4 p-1 lg:mb-6 mb-3 w-full"
//                   />
//                 ) : (
//                   new Date(eventData.startDate).toLocaleDateString("en-GB")
//                 )}
//               </p>
//               <p className="text-lg">
//                 <strong className="text-gray-700">End Date:</strong>{" "}
//                 {isEditing ? (
//                   <input
//                     type="date"
//                     name="endDate"
//                     value={eventData.endDate}
//                     onChange={handleChange}
//                     className="border border-gray-300 rounded-lg lg:p-4 p-1 lg:mb-6 mb-3 w-full"
//                   />
//                 ) : (
//                   new Date(eventData.endDate).toLocaleDateString("en-GB")
//                 )}
//               </p>
//               {/* <p className="text-lg">
//                 <strong className="text-gray-700">Venue:</strong>{" "}
//                 {isEditing ? (
//                   <input
//                     type="text"
//                     name="venue"
//                     value={eventData.venue}
//                     onChange={handleChange}
//                     className="border border-gray-300 rounded-lg lg:p-4 p-1 lg:mb-6 mb-3 w-full"
//                   />
//                 ) : venues.find((venue) => venue.id == eventData.venue) ? (
//                   Object.entries(
//                     venues.find((venue) => venue.id == eventData.venue)
//                   )
//                     .filter(([key]) => key !== "id")
//                     .map(([, value]) => value)
//                     .join(", ")
//                 ) : (
//                   "Venue"
//                 )}
//               </p> */}
//             </div>
//           </div>

//           {/* Category Details Card
//           <div className="bg-gray-50 shadow rounded-lg lg:p-4 p-1 lg:mb-6 mb-3">
//             <h3 className="text-2xl font-semibold text-blue-600 mb-4">
//               Category Details
//             </h3>
//             <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
//               <p className="text-lg">
//                 <strong className="text-gray-700">Generic:</strong>{" "}
//                 {isEditing ? (
//                   <input
//                     type="text"
//                     name="categories"
//                     value={getCategoryNames(eventData.categories, categories)}
//                     onChange={handleChange}
//                     className="border border-gray-300 rounded-lg px-2 py-1 w-full"
//                   />
//                 ) : (
//                   getCategoryNames(eventData.categories, categories)
//                 )}
//               </p>
//               <p className="text-lg">
//                 <strong className="text-gray-700">Age:</strong>{" "}
//                 {isEditing ? (
//                   <input
//                     type="text"
//                     name="ageCategories"
//                     value={getCategoryNames(
//                       eventData.ageCategories,
//                       ageCategories
//                     )}
//                     onChange={handleChange}
//                     className="border border-gray-300 rounded-lg px-2 py-1 w-full"
//                   />
//                 ) : (
//                   getCategoryNames(eventData.ageCategories, ageCategories)
//                 )}
//               </p>
//               <p className="text-lg">
//                 <strong className="text-gray-700">Gender:</strong>{" "}
//                 {isEditing ? (
//                   <input
//                     type="text"
//                     name="genderCategories"
//                     value={getCategoryNames(
//                       eventData.genderCategories,
//                       genderCategories
//                     )}
//                     onChange={handleChange}
//                     className="border border-gray-300 rounded-lg px-2 py-1 w-full"
//                   />
//                 ) : (
//                   getCategoryNames(eventData.genderCategories, genderCategories)
//                 )}
//               </p>
//               <p className="text-lg">
//                 <strong className="text-gray-700">Followers:</strong>{" "}
//                 {isEditing ? (
//                   <input
//                     type="text"
//                     name="followerCountCategories"
//                     value={getCategoryNames(
//                       eventData.followerCountCategories,
//                       followerCountCategories
//                     )}
//                     onChange={handleChange}
//                     className="border border-gray-300 rounded-lg px-2 py-1 w-full"
//                   />
//                 ) : (
//                   getCategoryNames(
//                     eventData.followerCountCategories,
//                     followerCountCategories
//                   )
//                 )}
//               </p>
//             </div>
//           </div> */}

//           {/* Sponsor Details Card  i want to add more fields value additional data in this crd*/}
//           <div className="bg-gray-50 shadow rounded-lg lg:p-4 p-1 lg:mb-6 mb-3 ">
//             <h3 className="text-2xl font-semibold text-blue-600 mb-4">
//               Sponsor Details
//             </h3>
//             {eventData.sponsorData && eventData.sponsorData.length > 0 ? (
//               eventData.sponsorData.map((sponsor, index) => (
//                 <div
//                   key={index}
//                   className="bg-white p-4 rounded-lg shadow-sm mb-4 border"
//                 >
//                   <p className="text-lg">
//                     <strong className="text-gray-700">Sponsor Category:</strong>{" "}
//                     {isEditing ? (
//                       <input
//                         type="text"
//                         name={`sponsorCategory_${index}`}
//                         value={sponsor.sponsorCategory}
//                         onChange={(e) =>
//                           setEventData({
//                             ...eventData,
//                             sponsorData: eventData.sponsorData.map((s, i) =>
//                               i === index
//                                 ? { ...s, sponsorCategory: e.target.value }
//                                 : s
//                             ),
//                           })
//                         }
//                         className="border border-gray-300 rounded-lg px-2 py-1 w-full"
//                       />
//                     ) : (
//                       sponsor.sponsorCategory
//                     )}
//                   </p>
//                   <p className="text-lg">
//                     <strong className="text-gray-700">Coupons:</strong>{" "}
//                     {isEditing ? (
//                       <input
//                         type="number"
//                         name={`coupons_${index}`}
//                         value={sponsor.noOfCoupons}
//                         onChange={(e) =>
//                           setEventData({
//                             ...eventData,
//                             sponsorData: eventData.sponsorData.map((s, i) =>
//                               i === index
//                                 ? { ...s, noOfCoupons: e.target.value }
//                                 : s
//                             ),
//                           })
//                         }
//                         className="border border-gray-300 rounded-lg px-2 py-1 w-full"
//                       />
//                     ) : (
//                       sponsor.noOfCoupons
//                     )}
//                   </p>

//                   <p className="text-lg">
//                     <strong className="text-gray-700">Gift Hampers:</strong>{" "}
//                     {isEditing ? (
//                       <input
//                         type="number"
//                         name={`giftHampers_${index}`}
//                         value={sponsor.noOfGiftHampers}
//                         onChange={(e) =>
//                           setEventData({
//                             ...eventData,
//                             sponsorData: eventData.sponsorData.map((s, i) =>
//                               i === index
//                                 ? { ...s, noOfGiftHampers: e.target.value }
//                                 : s
//                             ),
//                           })
//                         }
//                         className="border border-gray-300 rounded-lg px-2 py-1 w-full"
//                       />
//                     ) : (
//                       sponsor.noOfGiftHampers
//                     )}
//                   </p>
//                   <p className="text-lg">
//                     <strong className="text-gray-700">Price:</strong>{" "}
//                     {isEditing ? (
//                       <input
//                         type="number"
//                         name={`price_${index}`}
//                         value={sponsor.price}
//                         onChange={(e) =>
//                           setEventData({
//                             ...eventData,
//                             sponsorData: eventData.sponsorData.map((s, i) =>
//                               i === index ? { ...s, price: e.target.value } : s
//                             ),
//                           })
//                         }
//                         className="border border-gray-300 rounded-lg px-2 py-1 w-full"
//                       />
//                     ) : (
//                       sponsor.price
//                     )}
//                   </p>
//                   <p className="text-lg">
//                     <strong className="text-gray-700">Description:</strong>{" "}
//                     {isEditing ? (
//                       <textarea
//                         name={`description_${index}`}
//                         value={sponsor.description}
//                         onChange={(e) =>
//                           setEventData({
//                             ...eventData,
//                             sponsorData: eventData.sponsorData.map((s, i) =>
//                               i === index
//                                 ? { ...s, description: e.target.value }
//                                 : s
//                             ),
//                           })
//                         }
//                         className="border border-gray-300 rounded-lg px-2 py-1 w-full"
//                       />
//                     ) : (
//                       sponsor.description
//                     )}
//                   </p>
//                   {/* Additional Fields from SponsorDetailForm */}
//                   <p className="text-lg">
//                     <strong className="text-gray-700">Coupons:</strong>{" "}
//                     {sponsor.noOfCoupons || "N/A"}
//                   </p>
//                   <p className="text-lg">
//                     <strong className="text-gray-700">Gift Hampers:</strong>{" "}
//                     {sponsor.noOfGiftHampers || "N/A"}
//                   </p>
//                   <p className="text-lg">
//                     <strong className="text-gray-700">Price:</strong>{" "}
//                     {sponsor.price || "N/A"}
//                   </p>
//                   <p className="text-lg">
//                     <strong className="text-gray-700">Description:</strong>{" "}
//                     {sponsor.description || "N/A"}
//                   </p>
//                   {sponsor.additional_data && (
//                     <>
//                       <p className="text-lg">
//                         <strong className="text-gray-700">Country:</strong>{" "}
//                         {sponsor.additional_data.country}
//                       </p>
//                       <p className="text-lg">
//                         <strong className="text-gray-700">Seats:</strong>{" "}
//                         {sponsor.additional_data.no_of_seats || "N/A"}
//                       </p>
//                       <p className="text-lg">
//                         <strong className="text-gray-700">Proposal URL:</strong>{" "}
//                         {sponsor.additional_data.proposal_url || "N/A"}
//                       </p>
//                       <p className="text-lg">
//                         <strong className="text-gray-700">Location:</strong>{" "}
//                         {sponsor.additional_data.is_national
//                           ? "National"
//                           : sponsor.additional_data.is_state
//                           ? "State"
//                           : sponsor.additional_data.is_city
//                           ? "City"
//                           : sponsor.additional_data.is_pincode
//                           ? "Pincode"
//                           : "N/A"}
//                       </p>
//                     </>
//                   )}
//                 </div>
//               ))
//             ) : (
//               <p className="text-gray-600">No Sponsor Data Available</p>
//             )}
//           </div>

//           {/* Template Data Card */}
//           <div className="bg-gray-50 shadow rounded-lg lg:p-4 p-1 lg:mb-6 mb-3">
//             <h3 className="text-2xl font-semibold text-blue-600 mb-4">
//               Template Image
//             </h3>
//             {eventData.templateData ? (
//               <img
//                 src={eventData.templateData}
//                 alt="Template"
//                 className="w-60 h-60 lg:w-96 lg:h-96 rounded-md border border-gray-300 "
//               />
//             ) : (
//               <p className="text-gray-600">No Image Available</p>
//             )}
//           </div>
//         </article>
//       )}
//     </div>
//   );
// };

// export default EventCard;
