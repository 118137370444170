import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import MediaList from "../../../participant/MediaList";
import { getAccessToken } from "../../../getAccessToken/getAccessToken";
import { DataContext } from "../../../../newComponents/context/DataContext";
import FacebookInsightsChart from "../../../participant/FacebookInsightsChart";
import { SponsorshipDetail } from "./SponsorshipDetail";
import { CollabRequest } from "./CollabRequest";
import UserDetail from "../../../participant/UserDetail";
import LoadingScreen from "../../../../newComponents/utilities/LoadingScreen";

export const SponsorInsta = () => {
  const accessToken = Cookies.get("fbauthToken");
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username");
  const passphrase = searchParams.get("passphrase");
  const { id, instagramBusinessAccountId } = useParams();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [userFbToken, setUserFbToken] = useState(null);

  const location = useLocation();
  const sponsorData = location.state.sponsor;

  // Button states
  const [allMedia, setAllMedia] = useState(true);
  const [eventMedia, setEventMedia] = useState(false);
  const [sponsorDetail, setSponsorDetail] = useState(false);
  const [insights, setInsights] = useState(false);
  const [userDetails, setUserDetails] = useState(false);

  const {
    eventData,
    categories,
    ageCategories,
    genderCategories,
    followerCountCategories,
    usersInfo,
    loading,
  } = useContext(DataContext);

  if (loading) <LoadingScreen />
  
  const getResponse = async (url) => {
    if (!userFbToken) return;
    try {
      const response = await axios.get(url, {
        params: {
          access_token: userFbToken,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching media details:", error);
      throw error;
    }
  };
  const fetchInstagramUser = async (accessToken, igId ) => {
    var token = await getAccessToken(
      `${apiDomain}/api/v2/admin/getrefreshtoken`,
      id
    );
    setUserFbToken(token);
    console.log("token", token);
    try {
      const response = await axios.get(
        `${apiDomain}/api/v2/facebook/instagramuser`,
        {
          params: {
            access_token: token,
            id: igId,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Instagram user data:", error);
      throw error;
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        if (accessToken && instagramBusinessAccountId) {
          const data = await fetchInstagramUser(
            accessToken,
            instagramBusinessAccountId
          );
          setUserData(data);
        } else {
          setError("Missing access token or Instagram Business Account ID");
        }
      } catch (err) {
        setError("Failed to fetch Instagram user data.");
      }
    };

    // Convert await calls into Promise.then structure
    getUserData();
  }, [accessToken, instagramBusinessAccountId]);
  // Handler to update the state based on the clicked button
  const handleButtonClick = (button) => {
    setAllMedia(button === "allMedia");
    setEventMedia(button === "eventMedia");
    setSponsorDetail(button === "sponsorDetail");
    setInsights(button === "insights");
    setUserDetails(button === "userDetails");
  };

    // Find the user details from the usersInfo array based on user_id
    const currentUser = usersInfo?.find((user) => user.user_id === Number(id));

    if (error) return <div>Error: {error}</div>;
    if (!userData && !currentUser) return <div></div>;

  return (
    <main className="lg:ml-[20vw] lg:mr-[2vw]">
      <div className="w-full p-4 bg-white shadow-md rounded-lg mt-4">
        <div className="flex items-center space-x-6">
          <img
            src={userData?.profile_picture_url}
            alt={userData?.name}
            className="w-32 h-32 rounded-full border-2 border-gray-300"
          />
          <div className="flex flex-col">
            <h1 className="text-3xl font-semibold">{userData?.name}</h1>
            <p className="text-lg text-gray-600">@{userData?.username}</p>
            <p className="text-sm text-gray-500">{userData?.biography}</p>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-300 pt-4">
          <div className="flex space-x-8 text-center">
            <div>
              <p className="text-xl font-semibold">
                {userData?.followers_count}
              </p>
              <p className="text-sm text-gray-500">Followers</p>
            </div>
            <div>
              <p className="text-xl font-semibold">{userData?.follows_count}</p>
              <p className="text-sm text-gray-500">Following</p>
            </div>
            <div>
              <p className="text-xl font-semibold">{userData?.media_count}</p>
              <p className="text-sm text-gray-500">Posts</p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <p className="text-sm text-gray-500">Website:</p>
          <a
            href={userData?.website}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            {userData?.website}
          </a>
        </div>
      </div>

      {/* Filter buttons */}
      <div className="filter-btn my-8 mt-16 flex items-center space-x-2">
        <button
          className={`border rounded-md px-6 py-2 ${
            allMedia ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleButtonClick("allMedia")}
        >
          All Media
        </button>
        <button
          className={`border  rounded-md px-6 py-2 ${
            eventMedia ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleButtonClick("eventMedia")}
        >
          Sponsorship Detail
        </button>
        <button
          className={`border rounded-md px-6 py-2 ${
            sponsorDetail ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleButtonClick("sponsorDetail")}
        >
          Collaboration Requests
        </button>
        <button
          className={`border  rounded-md px-6 py-2 ${
            insights ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleButtonClick("insights")}
        >
          Insights
        </button>
        <button
          className={`border rounded-md px-6 py-2 ${
            userDetails ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleButtonClick("userDetails")}
        >
          User Detail
        </button>
      </div>

      <div className="w-full h-[2px] bg-gray-700 mb-4"></div>

      {allMedia && userFbToken && (
        <MediaList
          userId={instagramBusinessAccountId}
          token={userFbToken}
          eventMedia={false}
        />
      )}
      {eventMedia && eventData && userFbToken && (
        <SponsorshipDetail
          details={sponsorData}
          eventData={eventData}
          token={userFbToken}
        />
      )}

      <div>
        {insights && userFbToken && instagramBusinessAccountId && (
          <FacebookInsightsChart
            instagramBusinessAccountId={instagramBusinessAccountId}
            accessToken={userFbToken}
          />
        )}
      </div>
      <div>
        {sponsorDetail &&
          userFbToken &&
          instagramBusinessAccountId &&
          id && (
            <CollabRequest
              id={id}
              instagramBusinessAccountId={instagramBusinessAccountId}
              token={userFbToken}
            />
          )}
      </div>
      {userDetails && (
        <UserDetail currentUser={currentUser} instagramData={userData} />
      )}
    </main>
  );
};