import React from "react";
import ParticipantTable from "./ParticipantTable";
const Participants = () => {
  return (
    <div className="">
      <ParticipantTable />
    </div>
  );
};

export default Participants;
