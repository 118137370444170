import React, { useState, useEffect, useCallback } from "react";
import { FaTrash, FaPlus } from "react-icons/fa";
import debounce from "lodash.debounce";

const EventDataSection = ({
  isHashtagsEditable,
  setIsHashtagsEditable,
  isCaptionEditable,
  setIsCaptionEditable,
  isSponsorTagsEditable,
  setIsSponsorTagsEditable,
  eventPrice,
  setEventPrice,
  eventCaption1,
  eventCaption2,
  setEventCaption1,
  setEventCaption2,
  eventHashtags,
  setEventHashtags,
  eventImageUrl,
  setEventImageUrl,
  eventAdditionalImages,
  setEventAdditionalImages,
  eventAdditionalDesc,
  setEventAdditionalDesc,
  advideoUrl,
  setAdVideoUrl,
  demoVideoUrl,
  setDemoVideoUrl,
  handleFileUpload,
  isEditMode,
}) => {
  const [localEventImagePreview, setLocalEventImagePreview] = useState(null);
  const [localAdditionalImagePreviews, setLocalAdditionalImagePreviews] =
    useState([]);
  const [localAdVideoPreview, setLocalAdVideoPreview] = useState(null);
  const [localDemoVideoPreview, setLocalDemoVideoPreview] = useState(null);
  const [localEventCaption1, setLocalEventCaption1] = useState(
    eventCaption1 || ""
  );
  const [localEventCaption2, setLocalEventCaption2] = useState(
    eventCaption2 || ""
  );
  const [newHashtag, setNewHashtag] = useState("");

  // Handle Event Image Upload
  const handleEventImageUpload = useCallback(
    async (file) => {
      if (file) {
        const previewUrl = URL.createObjectURL(file);
        setLocalEventImagePreview(previewUrl);

        const uploadedUrl = await handleFileUpload(file);
        setEventImageUrl(uploadedUrl);
      }
    },
    [handleFileUpload, setEventImageUrl]
  );

  // Handle Additional Image Upload
  const handleAdditionalImageUpload = useCallback(
    async (file, index) => {
      if (file) {
        const previewUrl = URL.createObjectURL(file);
        setLocalAdditionalImagePreviews((prev) => {
          const updatedPreviews = [...prev];
          updatedPreviews[index] = previewUrl || null;
          return updatedPreviews;
        });

        const uploadedUrl = await handleFileUpload(file);
        setEventAdditionalImages((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index] = { url: uploadedUrl || null };
          return updatedImages;
        });
      }
    },
    [handleFileUpload, setEventAdditionalImages]
  );

  // Add Additional Image
  const handleAddImage = useCallback(() => {
    setEventAdditionalImages((prev) => [...prev, { url: null }]);
    setLocalAdditionalImagePreviews((prev) => [...prev, null]);
  }, [setEventAdditionalImages]);

  // Remove Additional Image
  const handleRemoveImage = useCallback(
    (index) => {
      setEventAdditionalImages((prev) => prev.filter((_, i) => i !== index));
      setLocalAdditionalImagePreviews((prev) =>
        prev.filter((_, i) => i !== index)
      );
    },
    [setEventAdditionalImages]
  );

  const handleTextareaResize = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  // Handle Video Upload (Ad and Demo Videos)
  const handleVideoUpload = useCallback(
    async (file, setVideoUrl, setVideoPreview) => {
      if (file) {
        const previewUrl = URL.createObjectURL(file);
        setVideoPreview(previewUrl);

        const uploadedUrl = await handleFileUpload(file);
        setVideoUrl(uploadedUrl);
      }
    },
    [handleFileUpload]
  );

  // Sync Previews with Edit Mode Data
  useEffect(() => {
    if (isEditMode) {
      setLocalEventImagePreview(eventImageUrl || null);

      // Safely handle eventAdditionalImages, ensuring it's an array before mapping
      setLocalAdditionalImagePreviews(
        Array.isArray(eventAdditionalImages)
          ? eventAdditionalImages.map((img) => (img && img.url) || null)
          : []
      );

      setLocalAdVideoPreview(advideoUrl || null);
      setLocalDemoVideoPreview(demoVideoUrl || null);
      setLocalEventCaption1(eventCaption1 || "");
      setLocalEventCaption2(eventCaption2 || "");
    }
  }, [
    isEditMode,
    eventCaption1,
    eventCaption2,
    eventImageUrl,
    eventAdditionalImages,
    advideoUrl,
    demoVideoUrl,
  ]);

  // Debounced update to parent state for captions
  const debouncedUpdateEventCaption1 = useCallback(
    debounce((value) => {
      setEventCaption1(value);
    }, 300),
    [setEventCaption1]
  );

  const debouncedUpdateEventCaption2 = useCallback(
    debounce((value) => {
      setEventCaption2(value);
    }, 300),
    [setEventCaption2]
  );

  const handleCaption1Change = (e) => {
    const value = e.target.value;
    setLocalEventCaption1(value);
    debouncedUpdateEventCaption1(value);
  };

  const handleCaption2Change = (e) => {
    const value = e.target.value;
    setLocalEventCaption2(value);
    debouncedUpdateEventCaption2(value);
  };

  // Manage Hashtags
  const addHashtag = useCallback(() => {
    if (newHashtag.trim() && !eventHashtags.includes(newHashtag.trim())) {
      setEventHashtags([...eventHashtags, newHashtag.trim()]);
      setNewHashtag("");
    }
  }, [newHashtag, eventHashtags, setEventHashtags]);

  const removeHashtag = useCallback(
    (index) => {
      setEventHashtags(eventHashtags.filter((_, i) => i !== index));
    },
    [eventHashtags, setEventHashtags]
  );

  return (
    <div className="p-4 border rounded-md bg-blue-50 shadow-md">
      {/* Toggles */}
      <h2 className="text-lg font-semibold mb-2">Event Editable Checkbox</h2>
      <section className="grid grid-cols-1 lg:grid-cols-3 gap-16 bg-gray-50 p-4 border rounded-md mb-5">
        <div className="flex items-center gap-4">
          <label className="text-base lg:text-lg font-medium">Hashtags Editable</label>
          <input
            type="checkbox"
            checked={isHashtagsEditable}
            onChange={() => setIsHashtagsEditable(!isHashtagsEditable)}
            className="h-5 w-5"
          />
        </div>
        <div className="flex items-center gap-4">
          <label className="text-base lg:text-lg font-medium">Caption Editable</label>
          <input
            type="checkbox"
            checked={isCaptionEditable}
            onChange={() => setIsCaptionEditable(!isCaptionEditable)}
            className="h-5 w-5"
          />
        </div>
        <div className="flex items-center gap-4 ">
          <label className="text-base lg:text-lg font-medium">Sponsor Tags Editable</label>
          <input
            type="checkbox"
            checked={isSponsorTagsEditable}
            onChange={() => setIsSponsorTagsEditable(!isSponsorTagsEditable)}
            className="h-5 w-5"
          />
        </div>
      </section>

      {/* Event Price */}
      <div className="mb-4">
        <label className="text-lg font-semibold mb-2">Event Price</label>
        <input
          type="number"
          value={eventPrice}
          onChange={(e) => setEventPrice(Number(e.target.value))}
          className="w-full border rounded-md px-4 py-2"
        />
      </div>

      {/* Event Captions */}
      <div className="mb-4">
        <label className="text-lg font-semibold mb-6">Event Caption 1</label>
        <textarea
          type="text"
          value={localEventCaption1}
          onChange={handleCaption1Change}
          onInput={handleTextareaResize}
          className="w-full lg:min-h-40 border rounded-md px-4 py-2"
        />
      </div>
      <div className="mb-4">
        <label className="text-lg font-semibold mb-6">Event Caption 2</label>
        <textarea
          type="text"
          value={localEventCaption2}
          onChange={handleCaption2Change}
          onInput={handleTextareaResize}
          className="w-full lg:min-h-40 border rounded-md px-4 py-2"
        />
      </div>

      {/* Event Hashtags */}
      <div className="mb-4">
        <label className="text-lg font-semibold mb-2">Event Hashtags</label>
        <div className="flex items-center gap-4 mb-4">
          <input
            type="text"
            value={newHashtag}
            onChange={(e) => setNewHashtag(e.target.value)}
            className="flex-grow border rounded-md px-4 py-2"
            placeholder="Add a hashtag"
          />
          <button
            type="button"
            onClick={addHashtag}
            className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center gap-2"
          >
            <FaPlus /> Add
          </button>
        </div>
        <div className="flex flex-wrap gap-2">
          {eventHashtags &&
            eventHashtags.map((tag, index) => (
              <span
                key={index}
                className="bg-gray-200 px-3 py-1 rounded-full flex items-center gap-2"
              >
                #{tag}
                <button
                  type="button"
                  onClick={() => removeHashtag(index)}
                  className="text-red-600"
                >
                  <FaTrash />
                </button>
              </span>
            ))}
        </div>
      </div>

      {/* Event Image */}
      <div className="mb-4">
        <label className="block text-lg font-semibold mb-2">Event Image</label>
        <input
          type="file"
          onChange={(e) => handleEventImageUpload(e.target.files[0])}
          className="border rounded-md p-2"
        />
        {localEventImagePreview && (
          <img
            src={localEventImagePreview}
            alt="Event"
            className="mt-4 w-60 h-60 object-cover rounded-md"
          />
        )}
      </div>

      {/* Additional Images */}
      <div className="mb-4">
        <label className="block text-lg font-medium mb-2">Add Images that you want to show in event share time!</label>
        <div className="flex flex-wrap gap-4">
          {eventAdditionalImages.map((image, index) => (
            <div key={index} className="relative">
              <input
                type="file"
                onChange={(e) =>
                  handleAdditionalImageUpload(e.target.files[0], index)
                }
                className="border p-2 rounded-md"
              />
              {image && image.url && (
                <img
                  src={image.url}
                  alt={`Additional ${index + 1}`}
                  className="mt-2 w-60 h-60 object-cover rounded-md"
                />
              )}
              <button
                type="button"
                onClick={() => handleRemoveImage(index)}
                className="absolute top-2 right-2 text-red-600 bg-gray-200 p-2 rounded-full"
              >
                <FaTrash />
              </button>
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={handleAddImage}
          className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md flex items-center gap-2"
        >
          <FaPlus /> Add Image
        </button>
      </div>

      {/* Additional Description */}
      <div className="mb-4">
        <label className="block text-lg font-medium mb-2">
          Add Description that you want to show in event share time!
        </label>
        <textarea
          value={eventAdditionalDesc}
          onChange={(e) => setEventAdditionalDesc(e.target.value)}
          onInput={handleTextareaResize}
          className="w-full lg:min-h-40 border rounded-md px-4 py-2"
        />
      </div>

      {/* Ad Video */}
      <div className="mb-4 grid grid-cols-1 lg:grid-cols-2">
        <div>
          <label className="block text-lg font-medium mb-2">Ad Video</label>
          <input
            type="file"
            onChange={(e) =>
              handleVideoUpload(
                e.target.files[0],
                setAdVideoUrl,
                setLocalAdVideoPreview
              )
            }
            className="border rounded-md p-2"
          />
          {localAdVideoPreview && (
            <video
              src={localAdVideoPreview}
              controls
              className="mt-4 w-60 h-40 rounded-md"
            />
          )}
        </div>

        {/* Demo Video */}
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Demo Video</label>
          <input
            type="file"
            onChange={(e) =>
              handleVideoUpload(
                e.target.files[0],
                setDemoVideoUrl,
                setLocalDemoVideoPreview
              )
            }
            className="border rounded-md p-2"
          />
          {localDemoVideoPreview && (
            <video
              src={localDemoVideoPreview}
              controls
              className="mt-4 w-60 h-40 rounded-md"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDataSection;


// import React from "react";
// import { FaTrash, FaPlus } from "react-icons/fa";

// const EventDataSection = ({
//   // Editable Toggles
//   isHashtagsEditable,
//   setIsHashtagsEditable,
//   isCaptionEditable,
//   setIsCaptionEditable,
//   isSponsorTagsEditable,
//   setIsSponsorTagsEditable,

//   // Event Data
//   eventPrice,
//   setEventPrice,
//   eventCaption,
//   setEventCaption,
//   eventImageUrl,
//   setEventImageUrl,
//   eventAdditionalImages,
//   handleAddImage,
//   handleRemoveImage,
//   handleImageUpload,
//   eventAdditionalDesc,
//   setEventAdditionalDesc,

//   // Ad Video and Demo Video
//   advideoUrl,
//   setAdVideoUrl,
//   demoVideoUrl,
//   setDemoVideoUrl,
//   handleVideoUpload,
// }) => {
//   return (
//     <div className="p-4 border rounded-md bg-gray-100 shadow-md">
//       <h3 className="text-xl font-bold mb-4">Event Data</h3>

//       {/* Editable Toggles */}
//       <section className="grid grid-cols-1 lg:grid-cols-3 gap-16 bg-gray-50 p-4 border rounded-md">
//         <div className="mb-4 flex items-center justify-between p-2 pr-8 rounded-xl shadow-xl">
//           <label className="block text-base font-medium text-gray-900">
//             Are Event Hashtags Editable?
//           </label>
//           <input
//             type="checkbox"
//             checked={isHashtagsEditable}
//             onChange={() => setIsHashtagsEditable(!isHashtagsEditable)}
//             className="h-5 w-5 text-blue-600"
//           />
//         </div>
//         <div className="mb-4 flex items-center justify-between p-2 pr-8 rounded-xl shadow-xl">
//           <label className="block text-base font-medium text-gray-900">
//             Is Event Caption Editable?
//           </label>
//           <input
//             type="checkbox"
//             checked={isCaptionEditable}
//             onChange={() => setIsCaptionEditable(!isCaptionEditable)}
//             className="h-5 w-5 text-blue-600"
//           />
//         </div>
//         <div className="mb-4 flex items-center justify-between p-2 pr-8 rounded-xl shadow-xl">
//           <label className="block text-base font-medium text-gray-900">
//             Are Event Sponsor Tags Editable?
//           </label>
//           <input
//             type="checkbox"
//             checked={isSponsorTagsEditable}
//             onChange={() => setIsSponsorTagsEditable(!isSponsorTagsEditable)}
//             className="h-5 w-5 text-blue-600"
//           />
//         </div>
//       </section>

//       {/* Event Price */}
//       <div className="mb-4">
//         <label className="block text-lg font-medium">Event Price</label>
//         <input
//           type="number"
//           value={eventPrice}
//           onChange={(e) => setEventPrice(Number(e.target.value))}
//           className="w-full border rounded-md px-4 py-2"
//         />
//       </div>

//       {/* Event Caption */}
//       <div className="mb-4">
//         <label className="block text-lg font-medium">Event Caption</label>
//         <input
//           type="text"
//           value={eventCaption}
//           onChange={(e) => setEventCaption(e.target.value)}
//           className="w-full border rounded-md px-4 py-2"
//         />
//       </div>

//       {/* Event Image Upload */}
//       <div className="mb-4">
//         <label className="block text-lg font-medium">Event Image</label>
//         <input
//           type="file"
//           onChange={(e) => handleImageUpload(e.target.files[0], setEventImageUrl)}
//           className="border"
//         />
//         {eventImageUrl && (
//           <img src={eventImageUrl} alt="Event" className="mt-4 w-60 h-60" />
//         )}
//       </div>

//       {/* Event Additional Description */}
//       <div className="mb-4">
//         <label className="block text-lg font-medium">
//           Event Additional Description
//         </label>
//         <textarea
//           value={eventAdditionalDesc}
//           onChange={(e) => setEventAdditionalDesc(e.target.value)}
//           className="w-full border rounded-md px-4 py-2"
//         />
//       </div>

//       {/* Additional Event Images */}
//       <div className="mb-6">
//         <label className="block text-lg font-medium mb-2">
//           Additional Event Images
//         </label>
//         <div className="flex flex-wrap gap-4">
//           {eventAdditionalImages.map((image, index) => (
//             <div key={index} className="relative">
//               <input
//                 type="file"
//                 onChange={(e) => handleImageUpload(e.target.files[0], index)}
//                 className="border px-3 py-2 rounded-md"
//               />
//               {image.url && (
//                 <img
//                   src={image.url}
//                   alt={`Additional ${index + 1}`}
//                   className="mt-2 w-60 h-60 object-cover rounded-md"
//                 />
//               )}
//               <button
//               type="button"
//                 onClick={() => handleRemoveImage(index)}
//                 className="absolute top-2 right-2 text-red-600 bg-gray-200 px-2 rounded-full"
//               >
//                 <FaTrash />
//               </button>
//             </div>
//           ))}
//         </div>
//         <button
//           type="button"
//           onClick={handleAddImage}
//           className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md flex items-center gap-2"
//         >
//           <FaPlus /> Add Image
//         </button>
//       </div>

//       {/* Ad Video Upload */}
//       <div className="mb-6">
//         <label className="block text-lg font-medium mb-2">Ad Video</label>
//         <input
//           type="file"
//           accept="video/*"
//           onChange={(e) => handleVideoUpload(e.target.files[0], setAdVideoUrl)}
//           className="border px-3 py-2 rounded-md"
//         />
//         {advideoUrl && (
//           <video
//             src={advideoUrl}
//             controls
//             className="mt-4 w-60 h-40 object-cover rounded-md"
//           />
//         )}
//       </div>

//       {/* Demo Video Upload */}
//       <div className="mb-6">
//         <label className="block text-lg font-medium mb-2">Demo Video</label>
//         <input
//           type="file"
//           accept="video/*"
//           onChange={(e) => handleVideoUpload(e.target.files[0], setDemoVideoUrl)}
//           className="border px-3 py-2 rounded-md"
//         />
//         {demoVideoUrl && (
//           <video
//             src={demoVideoUrl}
//             controls
//             className="mt-4 w-60 h-40 object-cover rounded-md"
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default EventDataSection;
