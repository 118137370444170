import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../context/DataContext";
import axios from "axios";
import LoadingScreen from "../utilities/LoadingScreen";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const NonParticipatedNotification = () => {
  const { noneParticipated, loading } = useContext(DataContext);
  if (loading) <LoadingScreen />;
  console.log("noneParticipated", noneParticipated);
  const [participants, setParticipants] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [additionalData, setAdditionalData] = useState("");
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // Initialize participants and filters
  useEffect(() => {
    if (noneParticipated?.participants) {
      setParticipants(noneParticipated.participants);
    }
  }, [noneParticipated]);

  const handleUserSelection = (id) => {
    setSelectedUsers((prev) =>
      prev.includes(id) ? prev.filter((id) => id !== id) : [...prev, id]
    );
  };

  const handleSelectAllUsers = () => {
    if (selectedUsers.length === noneParticipated.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(noneParticipated.map((user) => user.id));
    }
  };

  const handleSendNotification = async () => {
    if (!title || !description || selectedUsers.length === 0) {
      alert("Please fill out all fields and select at least one user.");
      return;
    }

    try {
      const selectedUsersString = selectedUsers.join(", ");
      const tokenResponse = await axios.get(
        `${apiDomain}/api/v2/token/get?user_ids=${selectedUsersString}`
      );

      const tokens = selectedUsers
        .map((id) => {
          const fcmToken = tokenResponse.data[id];
          if (fcmToken) {
            return {
              user_id: parseInt(id, 10),
              fcm_token: fcmToken,
            };
          } else {
            console.warn(`No FCM token found for user ID ${id}`);
            return null;
          }
        })
        .filter((token) => token !== null);

      if (tokens.length === 0) {
        alert("No valid FCM tokens found for selected users.");
        return;
      }

      const requestBody = {
        users: tokens,
        title,
        body: description,
        ...(additionalData && { additional_data: { url: additionalData } }),
      };

    //   await axios.post(
        // `http://localhost:7070/notifications/send`, requestBody,
       
        await axios.post(`${apiDomain}/notifications/send`, requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      alert(`Notification sent to ${tokens.length} users.`);
      // Reset form fields and selected users
      setTitle("");
      setDescription("");
      setAdditionalData("");
      setSelectedUsers([]);
    } catch (error) {
      console.error(
        "Error sending notification:",
        error.response?.data || error.message
      );
      alert("Failed to send notification. Please try again.");
    }
  };

  return (
    <div className="mt-6 border p-4 rounded-lg bg-blue-50 ">
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl font-bold uppercase">
          Send Notifications to None participated user
        </h2>
        <button
          onClick={() => setIsFiltersVisible(!isFiltersVisible)}
          className="p-2 border rounded-full bg-blue-100 hover:bg-blue-200 transition"
        >
          {isFiltersVisible ? <FiChevronUp size={20}/> : <FiChevronDown size={20}/>}
        </button>
      </div>

      {/* Dropdown for Users */}
      {isFiltersVisible && (
        <>
          <div className="relative mb-6">
            <button
              className="w-full p-3 border border-gray-300 rounded-lg bg-white shadow-sm"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedUsers.length > 0
                ? `${selectedUsers.length} User(s) Selected`
                : "Select Users"}
            </button>
            {isDropdownOpen && (
              <div className="absolute z-10 mt-2 w-full bg-white border rounded shadow-lg max-h-60 overflow-y-auto">
                <ul className="space-y-2 p-2">
                  <li
                    onClick={handleSelectAllUsers}
                    className="cursor-pointer p-2 hover:bg-gray-100"
                  >
                    {selectedUsers.length === noneParticipated.length
                      ? "Deselect All None Participated Users"
                      : "Select All None Participated Users"}
                  </li>
                  {noneParticipated.map((user) => (
                    <li
                      key={user.id}
                      onClick={() => handleUserSelection(user.id)}
                      className={`cursor-pointer p-2 ${
                        selectedUsers.includes(user.id) ? "bg-blue-100" : ""
                      }`}
                    >
                      {user.igUser.name || user.igUser.username}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {/* Notification Form */}
          <div className="bg-blue-50 rounded">
            <div className="grid gap-4">
              <input
                type="text"
                placeholder="Notification Title"
                className="p-3 border border-gray-300 rounded-lg"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <textarea
                placeholder="Notification Description"
                className="p-3 border border-gray-300 rounded-lg"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="4"
              />
              <input
                type="url"
                placeholder="Additional Data (Optional URL)"
                className="p-3 border border-gray-300 rounded-lg"
                value={additionalData}
                onChange={(e) => setAdditionalData(e.target.value)}
              />
            </div>
            <div className="flex justify-center items-center">
              <button
                onClick={handleSendNotification}
                className="mt-4  p-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
              >
                Send Notification
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NonParticipatedNotification;
