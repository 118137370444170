import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { DataContext } from "../context/DataContext";
import LoadingScreen from "../utilities/LoadingScreen";

const apiDomain = process.env.REACT_APP_API_DOMAIN; // Assuming you're using this for API domain

const Wallet = () => {
  const [selectedUser, setSelectedUser] = useState(null); // Set initial user
  const [participants, setParticipants] = useState(null);

  const [balance, setBalance] = useState(0); // Current user balance
  const [transactions, setTransactions] = useState([]); // User transaction history
  const { participant, loading } = useContext(DataContext);
  if (loading) <LoadingScreen />;

  console.log('participants',participant )

  useEffect(() => {
    if (
      participant &&
      participant.participants &&
      participant.participants.length > 0
    ) {
      setParticipants(participant.participants);
      setSelectedUser(participant.participants[0]);
    }
  }, [participant, participants]);

  // Function to fetch wallet balance and transactions for a selected user
  const fetchWalletDetails = async (userId) => {
    try {
      // Fetch wallet balance
      const balanceResponse = await axios.get(`${apiDomain}/api/v2/getwallet`, {
        params: { user_id: userId },
      });
      setBalance(balanceResponse.data.amount);

      // Fetch transaction history
      const transactionResponse = await axios.get(
        `${apiDomain}/api/v2/filteredtransactions`,
        {
          params: { user_id: userId },
        }
      );
      setTransactions(transactionResponse.data ? transactionResponse.data : []);
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      // alert("Failed to fetch wallet details");
    }
  };

  // Handle adding funds
  const handleAddFunds = async () => {
    const amount = prompt("Enter the amount to add:");
    if (amount && parseFloat(amount) > 0) {
      try {
        const response = await axios.post(
          `${apiDomain}/api/v2/wallet/add`,
          null,
          {
            params: { user_id: selectedUser.userId, amount: amount },
          }
        );
        setBalance(response.data.new_balance);
        await addTransaction("credit", amount); // Record the transaction
        alert("Funds added successfully!");
      } catch (error) {
        console.error("Error adding funds:", error);
        alert("Failed to add funds");
      }
    }
  };

  // Handle deducting funds
  const handleDeductFunds = async () => {
    const amount = prompt("Enter the amount to deduct:");
    if (amount && parseFloat(amount) > 0) {
      try {
        const response = await axios.delete(
          `${apiDomain}/api/v2/wallet/deduct`,
          {
            params: { user_id: selectedUser.userId, amount: amount },
          }
        );
        setBalance(response.data.new_balance);
        await addTransaction("debit", -amount); // Record the transaction
        alert("Funds deducted successfully!");
      } catch (error) {
        console.error("Error deducting funds:", error);
        alert("Failed to deduct funds");
      }
    }
  };

  // Function to record a transaction (Add or Deduct)
  const addTransaction = async (type, amount) => {
    const transaction = {
      transaction_id: `TXN-${Date.now()}`, // Unique transaction ID
      timestamp: new Date(),
      type: type,
      amount: parseFloat(amount),
      status: "success",
      user_id: parseInt(selectedUser.userId),
    };

    try {
      await axios.post(`${apiDomain}/api/v2/transaction/create`, transaction);
      setTransactions((prev) => [transaction, ...prev]); // Update the transaction list
    } catch (error) {
      console.error("Error creating transaction:", error);
      alert("Failed to record transaction");
    }
  };

  useEffect(() => {
    if (selectedUser) {
      fetchWalletDetails(selectedUser.userId); // Fetch wallet details when user is selected
    }
  }, [selectedUser]);

  return (
    <div className="lg:ml-[20vw] lg:mr-[2vw] mx-2 flex flex-col md:flex-row my-10 bg-white rounded-lg shadow-lg border border-gray-300">
      {/* Left Side: User List */}
      <div className="md:w-1/3 w-full p-6 bg-gray-100 border-r border-gray-300">
        <h2 className="text-xl font-bold mb-4">Users</h2>
        <ul className="space-y-2">
          {participants &&
            participants.map((user) => (
              <li
                key={user.id}
                className={`p-3 cursor-pointer rounded-lg ${
                  selectedUser && selectedUser.id === user.id
                    ? "bg-blue-200"
                    : "bg-gray-200"
                } hover:bg-blue-100`}
                onClick={() => setSelectedUser(user)}
              >
                {user.instagramUser.name}
              </li>
            ))}
        </ul>
      </div>

      {/* Right Side: Wallet Details */}
      {selectedUser && (
        <div className="md:w-2/3 w-full p-6">
          <h2 className="text-2xl font-bold text-center mb-6">
            {selectedUser.instagramUser.name}'s Wallet
          </h2>

          <div className="text-center mb-4">
            <h3 className="text-xl font-semibold">Current Balance</h3>
            <p className="text-3xl text-green-500">₹{balance.toFixed(2)}</p>
          </div>

          <div className="flex justify-between">
            <button
              onClick={handleAddFunds}
              className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
            >
              Add Funds
            </button>
            <button
              onClick={handleDeductFunds}
              className="py-2 px-4 bg-red-500 text-white rounded hover:bg-red-600 transition"
            >
              Deduct Funds
            </button>
          </div>

          <h3 className="text-xl font-semibold mb-4 mt-6">
            Transaction History
          </h3>
          <div className="overflow-auto max-h-64">
            {transactions && transactions.length > 0 ? (
              <ul className="space-y-2">
                {transactions.map((transaction) => (
                  <li
                    key={transaction.id}
                    className={`flex justify-between p-2 rounded ${
                      transaction.amount < 0 ? "bg-red-100" : "bg-green-100"
                    }`}
                  >
                    <div>
                      <p className="font-medium">{transaction.type}</p>
                      <p className="text-sm text-gray-500">
                        {new Date(transaction.timestamp).toLocaleDateString()}
                      </p>
                    </div>
                    <p
                      className={`font-bold ${
                        transaction.amount < 0
                          ? "text-red-600"
                          : "text-green-600"
                      }`}
                    >
                      {transaction.amount < 0 ? "-" : "+"}₹
                      {Math.abs(transaction.amount).toFixed(2)}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500 text-center">
                No transactions available.
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Wallet;
