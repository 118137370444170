import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../newComponents/utilities/Pagination";
import { DataContext } from "../../../../newComponents/context/DataContext";
import LoadingScreen from "../../../../newComponents/utilities/LoadingScreen";

const SponsorDetail = () => {
  const { filteredSponsor, loading } = useContext(DataContext);
  if(loading) <LoadingScreen />
  console.log('filtered sponsor', filteredSponsor)
    const [sponsors, setSponsors] = useState([]);
  const [filteredSponsors, setFilteredSponsors] = useState([]);
  const [filters, setFilters] = useState({
    event_id: "",
    states: "",
    cities: "",
    pincodes: "",
  });
  const [availableFilters, setAvailableFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passphrase, setPassphrase] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (filteredSponsor?.sponsors) {
      setSponsors(filteredSponsor.sponsors || []);
      setFilteredSponsors(filteredSponsor.sponsors || []);
      generateFilterOptions(filteredSponsor.sponsors);
    }
  }, [filteredSponsor]);

  const generateFilterOptions = (data) => {
    const options = {
      event_id: [...new Set(data.flatMap((s) => s.event_ids))],
      states: [...new Set(data.flatMap((s) => s.states))],
      cities: [...new Set(data.flatMap((s) => s.cities))],
      pincodes: [...new Set(data.flatMap((s) => s.pincodes))],
    };
    setAvailableFilters(options);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    const applyFilters = () => {
      let filtered = sponsors;

      if (filters.event_id) {
        filtered = filtered.filter((sponsor) =>
          sponsor.event_ids.includes(parseInt(filters.event_id, 10))
        );
      }

      if (filters.states) {
        filtered = filtered.filter((sponsor) =>
          sponsor.states.includes(filters.states)
        );
      }

      if (filters.cities) {
        filtered = filtered.filter((sponsor) =>
          sponsor.cities.includes(filters.cities)
        );
      }

      if (filters.pincodes) {
        filtered = filtered.filter((sponsor) =>
          sponsor.pincodes.includes(parseInt(filters.pincodes, 10))
        );
      }

      setFilteredSponsors(filtered);
    };

    applyFilters();
  }, [filters, sponsors]);

  const totalPages = Math.ceil(filteredSponsors.length / pageSize);
  const paginatedSponsors = filteredSponsors.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleRowClick = (sponsor) => {
    setSelectedParticipant(sponsor);
    navigate(`/sponsors/${sponsor.user_id}/${sponsor.ig_user.id}`, {
      state: {
        sponsor: sponsor,
      },
    });
  };

  const handlePassphraseSubmit = () => {
    if (passphrase.trim() !== "") {
      navigate(
        `/sponsors/${selectedParticipant.user_id}/${selectedParticipant.ig_user.id}`,
        {
          state: {
            sponsor: selectedParticipant,
          },
        }
      );
      setIsModalOpen(false);
      setPassphrase("");
    }
  };

  return (
    <main className="lg:ml-[20vw] lg:mr-8">
      {/* Filters Section */}
      <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
        <h3 className="text-lg font-bold mb-4">Filters</h3>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {Object.keys(filters).map((key) => (
            <select
              key={key}
              name={key}
              value={filters[key]}
              onChange={handleFilterChange}
              className="p-2 border border-gray-300 rounded-lg bg-white"
            >
              <option value="">{`Select ${key.replace("_", " ").toUpperCase()}`}</option>
              {availableFilters[key]?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ))}
        </div>
      </div>

      {/* Sponsors Table */}
      <div className="border border-gray-300 rounded-lg overflow-x-auto my-8">
        <table className="min-w-full">
          <thead>
            <tr className="bg-gray-100 text-gray-800 text-left">
              <th className="p-4">Profile Pic.</th>
              <th className="p-4">Instagram Username</th>
              <th className="p-4">Followers</th>
              <th className="p-4">Name</th>
              <th className="p-4">Events Sponsored</th>
            </tr>
          </thead>
          <tbody>
            {paginatedSponsors.map((sponsor, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(sponsor)}
                className="hover:bg-gray-100 cursor-pointer transition duration-200 border-b ease-in-out"
              >
                <td className="p-4">
                  <img
                    src={sponsor.ig_user?.profile_picture_url}
                    alt="profile-pic"
                    className="w-12 h-12 rounded-full border border-gray-300 shadow-sm hover:scale-105 hover:shadow-lg transition-transform"
                  />
                </td>
                <td className="p-4">{sponsor.ig_user?.username || "N/A"}</td>
                <td className="p-4">{sponsor.ig_user?.followers_count || 0}</td>
                <td className="p-4">{sponsor.ig_user?.name || "N/A"}</td>
                <td className="p-4">{sponsor.eventsSponsored || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />

      {/* Modal for Passphrase */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded p-6 w-full max-w-sm">
            <h3 className="text-lg mb-4">Enter Passphrase</h3>
            <input
              type="text"
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
              placeholder="Passphrase"
              className="border w-full px-3 py-2 rounded mb-4"
            />
            <div className="flex justify-between">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Cancel
              </button>
              <button
                onClick={handlePassphraseSubmit}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default SponsorDetail;
