import React, { useEffect, useCallback, useState } from "react";
import { FaTrash, FaPlus } from "react-icons/fa";

const ParticipationAdditionalData = React.memo(
  ({
    // States for participation post
    ppCaption1,
    setPpCaption1,
    ppCaption2,
    setPpCaption2,
    ppHashtags,
    setPpHashtags,
    newHashtag,
    setNewHashtag,
    frameImageUrl,
    setFrameImageUrl,
    participationCarouselImages,
    setParticipationCarouselImages,

    // Editable toggles
    isPPHashtagEditable,
    setIsPPHashtagEditable,
    isPPCaptionEditable,
    setIsPPCaptionEditable,
    arePPSponsorsTagEditable,
    setArePPSponsorsTagEditable,
    isDefaultChecked,
    setIsDefaultChecked,
    isEditMode,
    // File upload handler
    handleFileUpload,
  }) => {
    // State to manage previews
    const [frameImagePreview, setFrameImagePreview] = useState(null);
    const [carouselImagePreviews, setCarouselImagePreviews] = useState([]);

    // Initialize previews in edit mode
    useEffect(() => {
      if (isEditMode) {
        setFrameImagePreview(frameImageUrl || null);

        // Safely handle participationCarouselImages
        setCarouselImagePreviews(
          Array.isArray(participationCarouselImages)
            ? participationCarouselImages.map(
                (image) => (image && image.url) || null
              )
            : [] // Default to an empty array if not valid
        );
      }
    }, [isEditMode, frameImageUrl, participationCarouselImages]);

    // Add a new hashtag
    const addParticipationHashtag = useCallback(() => {
      if (newHashtag.trim() && !ppHashtags.includes(newHashtag)) {
        setPpHashtags([...ppHashtags, newHashtag.trim()]);
        setNewHashtag(""); // Reset input field
      }
    }, [newHashtag, ppHashtags, setPpHashtags, setNewHashtag]);

    // Remove a hashtag
    const removeParticipationHashtag = useCallback(
      (index) => {
        setPpHashtags(ppHashtags.filter((_, i) => i !== index));
      },
      [ppHashtags, setPpHashtags]
    );

    const handleTextareaResize = (e) => {
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;
    };

    // Add a new carousel image
    const addCarouselImage = useCallback(() => {
      setParticipationCarouselImages([
        ...participationCarouselImages,
        { url: null },
      ]);
      setCarouselImagePreviews([...carouselImagePreviews, null]);
    }, [
      participationCarouselImages,
      setParticipationCarouselImages,
      carouselImagePreviews,
    ]);

    // Remove a carousel image
    const removeCarouselImage = useCallback(
      (index) => {
        setParticipationCarouselImages(
          participationCarouselImages.filter((_, i) => i !== index)
        );
        setCarouselImagePreviews(
          carouselImagePreviews.filter((_, i) => i !== index)
        );
      },
      [
        participationCarouselImages,
        setParticipationCarouselImages,
        carouselImagePreviews,
      ]
    );

    // Handle image upload for carousel
    const handleCarouselImageUpload = useCallback(
      async (file, index) => {
        if (file) {
          const previewUrl = URL.createObjectURL(file); // Generate preview URL
          setCarouselImagePreviews((prev) => {
            const updatedPreviews = [...prev];
            updatedPreviews[index] = previewUrl;
            return updatedPreviews;
          });

          const uploadedUrl = await handleFileUpload(file); // Upload file
          setParticipationCarouselImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[index] = { url: uploadedUrl };
            return updatedImages;
          });
        }
      },
      [setParticipationCarouselImages, handleFileUpload]
    );

    // Handle frame image upload with preview
    const handleFrameImageUpload = useCallback(
      async (file) => {
        if (file) {
          const previewUrl = URL.createObjectURL(file); // Generate preview URL
          setFrameImagePreview(previewUrl);

          const uploadedUrl = await handleFileUpload(file); // Upload file
          setFrameImageUrl(uploadedUrl);
        }
      },
      [setFrameImageUrl, handleFileUpload]
    );

    return (
      <div className="p-4 border rounded-md bg-blue-50 mb-6">
        <h2 className="text-lg font-semibold mb-2">
          Participation Editable Checkbox
        </h2>

        {/* Editable Toggles */}
        <section className="grid grid-cols-1 lg:grid-cols-4 gap-16 bg-gray-50 p-4 border rounded-md mb-5">
          <div className="flex items-center gap-4">
            <label className="text-base lg:text-lg font-medium">
              Hashtags Editable
            </label>
            <input
              type="checkbox"
              checked={isPPHashtagEditable}
              onChange={() => setIsPPHashtagEditable(!isPPHashtagEditable)}
              className="h-5 w-5"
            />
          </div>
          <div className="flex items-center gap-4">
            <label className="text-base lg:text-lg font-medium">
              Caption Editable
            </label>
            <input
              type="checkbox"
              checked={isPPCaptionEditable}
              onChange={() => setIsPPCaptionEditable(!isPPCaptionEditable)}
              className="h-5 w-5"
            />
          </div>
          <div className="flex items-center gap-4">
            <label className="text-base lg:text-lg font-medium">
              Sponsors Editable
            </label>
            <input
              type="checkbox"
              checked={arePPSponsorsTagEditable}
              onChange={() =>
                setArePPSponsorsTagEditable(!arePPSponsorsTagEditable)
              }
              className="h-5 w-5"
            />
          </div>
          <div className="flex items-center gap-4">
            <label className="text-base lg:text-lg font-medium">Default</label>
            <input
              type="checkbox"
              checked={isDefaultChecked}
              onChange={() => setIsDefaultChecked(!isDefaultChecked)}
              className="h-5 w-5"
            />
          </div>
        </section>

        {/* Participation Captions */}
        <div className="mb-4">
          <label className="text-base lg:text-lg font-medium mb-2">
            Caption 1
          </label>
          <textarea
            type="text"
            value={ppCaption1}
            onChange={(e) => setPpCaption1(e.target.value)}
            onInput={handleTextareaResize}
            className="w-full lg:min-h-40 border rounded-md px-4 py-2"
            placeholder="Enter Caption 1"
          />
        </div>
        <div className="mb-4">
          <label className="text-base lg:text-lg font-medium mb-2">
            Caption 2
          </label>
          <textarea
            type="text"
            value={ppCaption2}
            onChange={(e) => setPpCaption2(e.target.value)}
            onInput={handleTextareaResize}
            className="w-full lg:min-h-40 border rounded-md px-4 py-2"
            placeholder="Enter Caption 2"
          />
        </div>

        {/* Participation Hashtags */}
        <div className="mb-4">
          <label className="block text-lg font-medium">Hashtags</label>
          <div className="flex items-center gap-4 mb-4">
            <input
              type="text"
              value={newHashtag}
              onChange={(e) => setNewHashtag(e.target.value)}
              className="flex-grow border rounded-md px-4 py-2"
              placeholder="Add a hashtag"
            />
            <button
              type="button"
              onClick={addParticipationHashtag}
              className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center gap-2"
            >
              <FaPlus /> Add
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {ppHashtags &&
              ppHashtags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-gray-200 px-3 py-1 rounded-full flex items-center gap-2"
                >
                  #{tag}
                  <button
                    type="button"
                    onClick={() => removeParticipationHashtag(index)}
                    className="text-red-600"
                  >
                    <FaTrash />
                  </button>
                </span>
              ))}
          </div>
        </div>

        {/* Frame Image Upload */}
        <div className="mb-4">
          <label className="block text-lg font-medium">Frame Image</label>
          <input
            type="file"
            onChange={(e) => handleFrameImageUpload(e.target.files[0])}
            className="border px-4 py-2 rounded-md"
          />
          {frameImagePreview && (
            <img
              src={frameImagePreview}
              alt="Frame Preview"
              className="mt-4 w-60 h-60 object-cover rounded-md"
            />
          )}
        </div>

        {/* Participation Carousel Images */}
        <div className="mb-4">
          <label className="block text-lg font-medium">Carousel Images</label>
          <div className="flex flex-wrap gap-4">
            {participationCarouselImages &&
              participationCarouselImages.map((image, index) => (
                <div key={index} className="relative">
                  <input
                    type="file"
                    onChange={(e) =>
                      handleCarouselImageUpload(e.target.files[0], index)
                    }
                    className="border px-3 py-2 rounded-md"
                  />
                  {carouselImagePreviews[index] && (
                    <img
                      src={carouselImagePreviews[index]}
                      alt={`Carousel Preview ${index + 1}`}
                      className="mt-2 w-60 h-60 object-cover rounded-md"
                    />
                  )}
                  <button
                    type="button"
                    onClick={() => removeCarouselImage(index)}
                    className="absolute top-2 right-2 text-red-600 bg-gray-200 px-2 rounded-full"
                  >
                    <FaTrash />
                  </button>
                </div>
              ))}
          </div>
          <button
            type="button"
            onClick={addCarouselImage}
            className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md flex items-center gap-2"
          >
            <FaPlus /> Add Image
          </button>
        </div>
      </div>
    );
  }
);

export default ParticipationAdditionalData;
