import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Cookies from "js-cookie";

const AddVenue = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    address: "",
    city: "",
    state: "",
    pin_code: "",
    country: "",
  });
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const accessToken = Cookies.get("fbauthToken");

    // Axios POST request
    axios
      .post(`${apiDomain}/api/v2/createvenues?access_token=${accessToken}`, formData)
      .then((response) => {
        console.log("Form data saved:", response.data);
        alert("Venue added successfully");
        navigate("/"); // Navigate to the home page after successful submission
      })
      .catch((error) => {
        if (error.response) {
          console.log("Error Response:", error.response.data);
          alert(`Error: ${error.response.data}`);
        } else {
          console.log("Error Message:", error.message);
          alert(`Error: ${error.message}`);
        }
      });
  };

  return (
    <div className="flex justify-center items-center  bg-gray-100">
      <div className="w-full bg-white shadow-lg rounded-lg p-8">
        <div className="flex items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-700">Add Venue</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="col-span-2">
              <label htmlFor="address" className="block text-gray-600 mb-2">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500"
                placeholder="Enter address"
                required
              />
            </div>
            <div>
              <label htmlFor="city" className="block text-gray-600 mb-2">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500"
                placeholder="Enter city"
                required
              />
            </div>
            <div>
              <label htmlFor="state" className="block text-gray-600 mb-2">State</label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500"
                placeholder="Enter state"
                required
              />
            </div>
            <div>
              <label htmlFor="pin_code" className="block text-gray-600 mb-2">Pin Code</label>
              <input
                type="text"
                id="pin_code"
                name="pin_code"
                value={formData.pin_code}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500"
                placeholder="Enter pin code"
                required
              />
            </div>
            <div>
              <label htmlFor="country" className="block text-gray-600 mb-2">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500"
                placeholder="Enter country"
                required
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg shadow-md transition-all"
            >
              Add Venue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVenue;
