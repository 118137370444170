import React from "react";

const Winners = ({ winners = {}, setWinners }) => {
  const handleWinnerInput = (key, value) => {
    setWinners((prev) => ({
      ...prev,
      [key]: value !== "" ? parseInt(value, 10) || 0 : 0, // Ensure numeric input with default value 0
    }));
  };

  const winnerFields = [
    { key: "national", label: "National" },
    { key: "state", label: "State" },
    { key: "city", label: "City" },
    { key: "pincode", label: "Pincode" },
    { key: "international", label: "International" },
  ];

  return (
    <div className="mb-6 bg-blue-50 p-5 rounded-md border shadow-lg">
      <h3 className="text-lg font-semibold mb-4">
        Number of Winners to be Declared Based on Location
      </h3>
      {winnerFields.map(({ key, label }) => (
        <div key={key} className="mb-4">
          <label className="block font-medium capitalize">{label}:</label>
          <input
            type="number"
            value={winners[key] ?? 0} // Default to 0 if undefined
            onChange={(e) => handleWinnerInput(key, e.target.value)}
            className="border px-3 py-2 rounded-md w-full lg:w-1/2"
            placeholder={`Enter ${label} winners`}
          />
        </div>
      ))}
    </div>
  );
};

export default Winners;
